<template>
    <svg class="portfolio-icon" width="32px" height="23px" viewBox="0 0 32 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M30.602675,8 C31.1549598,8 31.602675,8.44771525 31.602675,9 C31.602675,9.14776873 31.5699264,9.29370099 31.5067848,9.42730015 L25.9040668,21.2819004 C25.4082772,22.3309248 24.3520215,23 23.1917373,23 L1.67073909,23 C1.11845434,23 0.670739092,22.5522847 0.670739092,22 C0.670739092,21.8522313 0.703487664,21.706299 0.766629262,21.5726999 L6.3693473,9.71809956 C6.86513692,8.66907518 7.92139256,8 9.08167679,8 L30.602675,8 Z M14,0.807228446 C15.6568542,0.807228446 17,2.1503742 17,3.80722845 L16.9992929,3.99922845 L23,4 C24.1045695,4 25,4.8954305 25,6 L24.9992929,6.544 L8.63026377,6.54413755 C7.17476844,6.54413755 5.84042071,7.33398836 5.13705887,8.59536274 L5.03635072,8.78806553 L-0.000707078619,19.098 L0,6 C-3.57353036e-16,4.8954305 0.8954305,4 2,4 L7.99929292,3.99922845 L8,3.80722845 C8,2.1503742 9.34314575,0.807228446 11,0.807228446 L14,0.807228446 Z M14,2.30722845 L11,2.30722845 C10.2203039,2.30722845 9.57955132,2.90211653 9.50686658,3.66276843 L9.5,3.80722845 L9.49929292,3.99922845 L15.4992929,3.99922845 L15.5,3.80722845 C15.5,3.02753233 14.9051119,2.38677977 14.14446,2.31409502 L14,2.30722845 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'portfolioIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>