<template>
    <div class="main_colums_wrapper head_swipe">
        <panel-header></panel-header>
        <div class="invisible_header"></div>
        <panel-main></panel-main>
    </div>

    <component v-for="(thisComponent, thisComponentIdx) in componentList" :is="thisComponent" :key="thisComponentIdx" />
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useLoginData } from '@/store/loginData'
import { useRoute } from 'vue-router'
import panelHeader from '@/views/panelHeader.vue'
import panelMain from '@/views/panelMain.vue'
import panelFuter  from '@/views/panelFuter.vue'
import loginRegister from '@/components/loginRegister.vue'

const loginData = useLoginData()
const route = useRoute()

const componentList = computed(() => {
    const list = []
    if (loginData.showLoginModal) list.push(loginRegister)
    if (!route.meta.noFooter) list.push(panelFuter)
    return list
})
</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/mainPage.scss';
</style>