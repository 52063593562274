<template>
    <svg class="add-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="add-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M23.2819098,18.6969755 L27.8540641,26.7462461 C24.9267077,29.9731224 20.7001529,32 15.9999987,32 C15.8739189,32 15.7478389,31.99872 15.6223991,31.99552 L23.2819098,18.6963355 L23.2819098,18.6969755 Z M1.95713588,23.6736098 L17.336317,23.6736098 L12.5824028,31.6332804 C7.99808848,30.6368015 4.14721319,27.674245 1.95649588,23.6736098 L1.95713588,23.6736098 Z M31.3279798,11.396504 C31.7750503,12.8900773 32.0014423,14.4409704 31.9999931,16.0000187 C31.9999931,19.146255 31.0918202,22.0800115 29.5231822,24.5542487 L22.1279911,11.397144 L31.3279798,11.396504 Z M2.1907356,7.91362807 L9.98720607,20.830093 L0.742417378,20.830093 C0.248970683,19.2676081 -0.00141826313,17.6385689 6.04231253e-06,16.0000187 C6.04231253e-06,13.049622 0.798097297,10.2867453 2.1907356,7.91362807 Z M15.9999987,1.23538198e-05 C16.1542385,1.23538198e-05 16.3084783,0.00195722948 16.4614381,0.00643722775 L9.11360715,13.029142 L4.54401271,4.83075161 C7.55219018,1.73742319 11.6851658,-0.00535342952 15.9999987,1.23538198e-05 Z M19.9423939,0.489636663 C24.3046285,1.59491538 27.952624,4.492192 30.0569415,8.35202746 L14.9504,8.35202746 L19.9423939,0.489636663 Z" id="Shape" fill="#838387" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'addPostIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 25px;
        width: 27px;

        @media(max-width: 430px) {
            height: 21px;
            width: 21px;
        }
    }

</style>