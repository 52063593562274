<template>
    <svg class="login-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ $t('login') }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M27.5094368,4.88546607 C33.6478244,11.2419525 33.4710204,21.3710492 27.1145339,27.5094368 C20.7580475,33.6478244 10.6289508,33.4710204 4.49056319,27.1145339 C3.80852013,26.4082577 3.82816502,25.2828025 4.53444129,24.6007594 C5.24071756,23.9187163 6.36617275,23.9383612 7.04821582,24.6446375 C11.8225173,29.5885714 19.7007036,29.7260856 24.6446375,24.9517842 C29.5885714,20.1774827 29.7260856,12.2992964 24.9517842,7.3553625 C20.1774827,2.41142861 12.2992964,2.27391436 7.3553625,7.04821582 C6.64908623,7.73025888 5.52363104,7.71061399 4.84158798,7.00433772 C4.15954491,6.29806145 4.1791898,5.17260626 4.88546607,4.49056319 C11.2419525,-1.64782439 21.3710492,-1.47102035 27.5094368,4.88546607 Z M15.0491926,11.395576 C15.2408727,11.395576 15.4285144,11.4506652 15.5897733,11.5542838 L21.216506,15.1697978 C21.6811388,15.4683523 21.8157719,16.0870378 21.5172174,16.5516707 C21.4419055,16.6688767 21.3429342,16.7690532 21.2266481,16.8457781 L15.5999154,20.5582667 C15.1389299,20.8624226 14.5186602,20.735287 14.2145044,20.2743014 C14.1066731,20.1108699 14.0491926,19.9193781 14.0491926,19.7235786 L14.0484722,17.572576 L14.0404749,17.5728715 L1.49778069,17.4999747 C0.669367557,17.49516 0.00170906767,16.8196954 0.00649784019,15.9912823 C0.0110551517,15.2115994 0.609657111,14.574315 1.3707186,14.5060522 L1.51521608,14.5000253 L14.0484722,14.572576 L14.0491926,12.395576 C14.0491926,11.8432912 14.4969078,11.395576 15.0491926,11.395576 Z" id="Combined-Shape" fill="#838387" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'loginIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 22px;
        width: 22px;

        path {
            fill: var(--white);
        }
    }
</style>