export type FileChangeInputEvent = Event & {
    target: HTMLInputElement & {
        files: FileList
    }
}

function makePhotoObject() {
    return {
        photo: '',
        photo_name: '',
        photo_size: '' as string | number,
        photo_type: '',
        loading: false,
    }
}

function handlePhotoLoad(photo: ReturnType<typeof makePhotoObject>, event: FileChangeInputEvent) {
    if (!event.target.files || !event.target.files[0]) return
    photo.loading = true
    // create base64 image
    const reader = new FileReader()
    reader.onload = async (e) => {
        if (!e.target || !e.target.result) return
        photo.photo = e.target.result as string
        photo.photo_name = event.target.files[0].name
        photo.photo_size = event.target.files[0].size
        photo.photo_type = event.target.files[0].type
        photo.loading = false
    }
    reader.readAsDataURL(event.target.files[0])
}

function handleMultiplePhotoLoad(photosList: ReturnType<typeof makePhotoObject>[], event: FileChangeInputEvent) {
    if (!event.target.files || !event.target.files[0]) return
    for (let i = 0; i < event.target.files.length; i++) {
        const photosListLength = photosList.push(makePhotoObject())
        const photo = photosList[photosListLength - 1]
        if (!photo) continue
        photo.loading = true
        // create base64 image
        const reader = new FileReader()
        reader.onload = async (e) => {
            if (!e.target || !e.target.result) return
            photo.photo = e.target.result as string
            photo.photo_name = event.target.files[i].name
            photo.photo_size = event.target.files[i].size
            photo.photo_type = event.target.files[i].type
            photo.loading = false
        }
        reader.readAsDataURL(event.target.files[i])
    }
}

async function base64ToBlobUrl(base64: string) {
    const blob = await fetch(base64).then(res => res.blob())
    return URL.createObjectURL(blob)
}

function serverPhotoPathToUrl(fullPath: string) {
    const thisDomain = window.location.origin
    const url = new URL(thisDomain)
    let domainZone = url.hostname.split('.')
    domainZone = domainZone.slice(domainZone.length - 1, domainZone.length)
    return `https://cdn1.kisshunter.${domainZone}/${fullPath}`
}

function getServerPhotoSize(imagesArray: any[], size: string) {
    const serverPhoto = imagesArray.find((image: any) => image.size === size)
    return serverPhoto ? serverPhotoPathToUrl(serverPhoto.full_path) : ''
}

export default {
    makePhotoObject,
    handlePhotoLoad,
    handleMultiplePhotoLoad,
    base64ToBlobUrl,
    serverPhotoPathToUrl,
    getServerPhotoSize
}