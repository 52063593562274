<template>
    <svg class="or-square" width="72px" height="72px" viewBox="0 0 72 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <!-- <radialGradient id="0" gradientTransform="translate(-0.91 -0.21) scale(2, 2)">
                <stop offset="0%" stop-color="#ffcf24"/>
                <stop offset="14.29%" stop-color="#feba3d"/>
                <stop offset="28.57%" stop-color="#fda547"/>
                <stop offset="42.86%" stop-color="#fc8e51"/>
                <stop offset="57.14%" stop-color="#fa765e"/>
                <stop offset="71.43%" stop-color="#f7586e"/>
                <stop offset="100%" stop-color="#d72e8c"/>
            </radialGradient>
            <radialGradient id="1" gradientTransform="translate(-1.05 -0.95) scale(2, 2)">
                <stop offset="0%" stop-color="#153def"/>
                <stop offset="53%" stop-color="rgba(217, 46, 116, 0)"/>
            </radialGradient>
            <radialGradient id="2" gradientTransform="translate(0.1 -0.07) scale(2, 2)">
                <stop offset="0%" stop-color="#153def"/>
                <stop offset="55%" stop-color="rgba(255, 0, 0, 0)"/>
            </radialGradient> -->

            <radialGradient id="0" gradientTransform="translate(-0.71 0) scale(1.48, 2)">
                <stop offset="33%" stop-color="#a855f7"/>
                <stop offset="42.57%" stop-color="#a35df8"/>
                <stop offset="52.14%" stop-color="#9f64f9"/>
                <stop offset="61.71%" stop-color="#9a6bfa"/>
                <stop offset="71.29%" stop-color="#9571fb"/>
                <stop offset="80.86%" stop-color="#9077fc"/>
                <stop offset="100%" stop-color="#8682fe"/>
            </radialGradient>
            <linearGradient id="1" x1="0.15" y1="0.15" x2="0.85" y2="0.85">
                <stop offset="0%" stop-color="#4964da"/>
                <stop offset="5.25%" stop-color="rgba(71, 94, 210, 0.92)"/>
                <stop offset="10.5%" stop-color="rgba(68, 88, 201, 0.83)"/>
                <stop offset="15.75%" stop-color="rgba(68, 82, 189, 0.75)"/>
                <stop offset="21%" stop-color="rgba(67, 77, 177, 0.67)"/>
                <stop offset="26.25%" stop-color="rgba(65, 72, 165, 0.58)"/>
                <stop offset="31.5%" stop-color="rgba(63, 66, 154, 0.5)"/>
                <stop offset="36.75%" stop-color="rgba(60, 62, 142, 0.42)"/>
                <stop offset="42%" stop-color="rgba(58, 57, 130, 0.33)"/>
                <stop offset="47.25%" stop-color="rgba(55, 53, 115, 0.25)"/>
                <stop offset="52.5%" stop-color="rgba(52, 49, 101, 0.17)"/>
                <stop offset="63%" stop-color="rgba(44, 41, 77, 0)"/>
            </linearGradient>
            <linearGradient id="2" x1="0.89" y1="0.19" x2="0.11" y2="0.81">
                <stop offset="0%" stop-color="#a855f7"/>
                <stop offset="25%" stop-color="rgba(143, 59, 155, 0.75)"/>
                <stop offset="50%" stop-color="rgba(92, 48, 82, 0.5)"/>
                <stop offset="100%" stop-color="rgba(0, 0, 0, 0)"/>
            </linearGradient>
            <radialGradient id="3" gradientTransform="translate(0.44 0.35) scale(0.95, 1.15)">
                <stop offset="0%" stop-color="#8682fe"/>
                <stop offset="14.29%" stop-color="rgba(134, 130, 254, 0.86)"/>
                <stop offset="28.57%" stop-color="rgba(134, 130, 254, 0.71)"/>
                <stop offset="42.86%" stop-color="rgba(134, 130, 254, 0.57)"/>
                <stop offset="57.14%" stop-color="rgba(134, 130, 254, 0.43)"/>
                <stop offset="71.43%" stop-color="rgba(134, 130, 254, 0.29)"/>
                <stop offset="100%" stop-color="rgba(134, 130, 254, 0)"/>
            </radialGradient>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect id="Rectangle" stroke="url(#0)" stroke-width="3" x="1.5" y="1.5" width="69" height="69" rx="15"></rect>
            <rect id="Rectangle" stroke="url(#1)" stroke-width="3" x="1.5" y="1.5" width="69" height="69" rx="15"></rect>
            <rect id="Rectangle" stroke="url(#2)" stroke-width="3" x="1.5" y="1.5" width="69" height="69" rx="15"></rect>
            <rect id="Rectangle" stroke="url(#3)" stroke-width="3" x="1.5" y="1.5" width="69" height="69" rx="15"></rect>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'squareOreonSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .or-square {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>