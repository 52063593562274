const supportedLocales = {
    en: {
        name: "English (US)",
        nativeName: "English (US)"
    },
    ru: {
        name: "Russian",
        nativeName: "Русский"
    }
}

export default supportedLocales;