<template>
    <div class="floodLimit">
        <h1>Too many requests...</h1>
        <p>You made too many requests, to be able to use this site again, you have to wait a few minutes or press unlock button below.</p>
        <div id="checkBlockTurnstile"></div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useGlobalData } from '@/store/globalData';
import { useSocketIOData } from '@/store/socketIOData';
import socketIoAPI from '@/modules/socketAPI';

const globalData = useGlobalData();
const socketIOData = useSocketIOData();
let turnstileRender = null
const turnstileSuccessKey = ref<string | null>(null)

onMounted(() => {
    turnstileRender = turnstile.render('#checkBlockTurnstile', {
        sitekey: '0x4AAAAAAAFOI-QN1kBgNNP0',
        action: 'floodLimit',
        theme: globalData.getDarkMode ? 'dark' : 'light',
        callback: function(token: string) {
            turnstileSuccessKey.value = token
            if (!socketIoAPI.socket) return console.warn('Connection error with socket')
            socketIoAPI.socket.emit('floodCaptcha', token, (returnData: any) => {
                if (!returnData.success) {
                    console.warn(returnData.error)
                    return setTimeout(() => {
                        location.reload() 
                    }, 5000);
                }

                socketIOData.floodLimit = false
            })
        },
        'error-callback': function() {
            turnstileSuccessKey.value = null
        },
        'expired-callback': function() {
            turnstileSuccessKey.value = null
        }
    });
})
</script>

<style lang="scss" scoped>

</style>