<template>
    <miniModalOuter ref="kissesOptionsElm">
        <div class="optionsMenuBody noselect">
            <div class="card_applying_wrapper">
                <h3>Add a bank card</h3>
                <div class="steps_wrapper">
                    <span class="step">Billing info</span>
                    <span class="step">Card details</span>
                </div>
                <div class="steps_dialog_wrapper">
                    <div class="scroller noscroll">
                        <div class="billing_information">
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Name</label>
                            </div>
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Surename</label>
                            </div>
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Country</label>
                            </div>
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">City</label>
                            </div>
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Addres line</label>
                            </div>
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Postal Code</label>
                            </div>
                        </div>
                        <div class="card_details_wrapper">
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Card holder</label>
                            </div>
                            <div class="input_wrapper">
                                <input type="text">
                                <label for="">Card number</label>
                            </div>
                            <div class="code_day_wrapper">
                                <div class="input_wrapper">
                                    <input type="text">
                                    <label for="">Exp. day</label>
                                </div>
                                <div class="input_wrapper">
                                    <input type="text">
                                    <label for="">CVC</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="kiss_applying_wrapper">
                <div class="kiss_buttons_wrapper">
                    <div class="button_wrapper">
                        <div class="kiss_wrapper">
                            <span>10</span>
                            <kissedKissIconSVG/>
                        </div>
                        <div class="apply_button">
                            <span>{{ $t ('messages_block.tips_modal.add') }}</span>
                        </div>
                    </div>
                    <div class="button_wrapper">
                        <div class="kiss_wrapper">
                            <span>50</span>
                            <kissedKissIconSVG/>
                        </div>
                        <div class="apply_button">
                            <span>{{ $t ('messages_block.tips_modal.add') }}</span>
                        </div>
                    </div>
                    <div class="button_wrapper">
                        <div class="kiss_wrapper">
                            <span>500</span>
                            <kissedKissIconSVG/>
                        </div>
                        <div class="apply_button">
                            <span>{{ $t ('messages_block.tips_modal.add') }}</span>
                        </div>
                    </div>
                </div>
                <div class="space_separator">
                    <span class="line"></span>
                    <span>{{ $t('or') }}</span>
                    <span class="line"></span>
                </div>
                <div class="min_max_wrapper">
                    <div class="info_wrap">
                        <small>{{ $t ('post.kisses_modal.min') }}.</small>
                        <span>2</span>
                    </div>
                    <span>-</span>
                    <div class="info_wrap">
                        <small>{{ $t ('post.kisses_modal.max') }}.</small>
                        <span>500</span>
                    </div>
                </div>
                <div class="desired_amount_wrapper">
                    <input type="number" :placeholder="$t ('post.kisses_modal.enter_quantity')">
                    <span><kissIconSVG/></span>
                </div>
                <div class="buttons_wrapper">
                    <div class="cancel_button">
                        <span>{{ $t ('messages_block.tips_modal.cancel') }}</span>
                    </div>
                    <div class="apply_button">
                        <span>{{ $t ('messages_block.tips_modal.apply') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </miniModalOuter>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import miniModalOuter from '@/components/miniModalOuter.vue';
import kissedKissIconSVG from '@/components/svg/kissed-kiss-icon.vue';
import kissIconSVG from '@/components/svg/kissIcon.vue';

const kissesOptionsElm = ref<InstanceType<typeof miniModalOuter> | null>(null);

function closeMenu() {
    if (!kissesOptionsElm.value) return
    kissesOptionsElm.value.closeMenu()
}
</script>

<style lang="scss" scoped>
    .optionsMenuBody {
        width: 100%;
        padding: 2rem 1rem;

        &.no_banking {
            .card_applying_wrapper {
                display: flex;
            }
            .tips_applying_wrapper {
                display: none;
            }

        }

        .card_applying_wrapper {
            display: none;
            flex-direction: column;
            width: 100%;

            h3 {
                font-size: 1.02rem;
            }

            .steps_wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                background-color: var(--100);
            }

            .steps_dialog_wrapper {
                .scroller {
                    display: grid;
                    grid-auto-flow: column;
                    align-content: center;
                    justify-content: flex-start;
                    padding-left: 0.75rem;
                    padding-right: 0.75rem;
                    padding-top: 0.35rem;
                    padding-bottom: 0.35rem;
                    border-radius: 0.45rem;
                    background-color: var(--100);
                    gap: 0.85rem;
                    overflow-x: auto;
                    overscroll-behavior-inline: contain;
                    scroll-behavior: smooth;
                    z-index: 1;
                    grid-template-rows: auto auto;
                    background-color: transparent;
                    padding-left: 1rem;
                    margin-left: -1rem;
                    margin-right: -1rem;                }
            }
        }
        .kiss_applying_wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .kiss_buttons_wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                flex-shrink: 1;
                gap: 1rem;

                .button_wrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex-grow: 1;
                    flex-basis: 120px;
                    width: 100%;
                    padding: 0.4rem 1rem;
                    border-radius: .6rem;
                    background-color: var(--description);
                    cursor: pointer;

                    &.pressed {
                        transform: scale(.96);
                    }
                    &:active {
                        transform: scale(.96);
                    }

                    .apply_button {
                        margin-top: .05rem;

                        span {
                            font-size: 14px;
                            font-weight: 500;
                            color: var(--lspecial);
                        }
                    }
                }
                
                .kiss_wrapper {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    svg {
                        height: 20px;
                        width: 26px;
                        filter: var(--filter_shadow);

                        :deep(#Group-41) {
                            >path {
                                fill: transparent;
                            }
                        }                    
                    }

                    span {
                        font-size: 18px;
                        font-weight: 700;
                        color: var(--dpurple);
                        margin-top: -1px;
                        margin-right: .35rem;
                    }
                }
            }

            .space_separator {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 2rem;
                margin-bottom: 1rem;

                span {
                    font-size: 11px;
                    font-weight: bold;
                    text-transform: uppercase;
                    color: var(--grey);
                    margin-left: 1rem;
                    margin-right: 1rem;
                    letter-spacing: .3px;

                    &.line {
                        margin: 0;
                        border-bottom: solid 1px;
                        border-color: var(--grey);
                        width: 4.5rem;
                    }
                }
            }

            .desired_amount_wrapper {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: auto;
                width: 100%;
                max-width: 278px;
                isolation: isolate;

                input {
                    height: 36px;
                    width: 100%;
                    outline: none;
                    border: 1px solid var(--lspecial);
                    border-radius: .6rem;
                    padding: 0.25rem 0.65rem;
                    font-size: 14px;
                    font-weight: 500;
                    color: var(--lspecial);
                    text-align: center;
                    background-color: transparent;
                    flex-grow: 1;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    width: 100%;

                    &::-webkit-outer-spin-button,
                    &::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                        -webkit-appearance: textfield;
                        appearance: textfield;
                    }

                    &::placeholder {
                        font-weight: 500;
                        font-size: 14px;
                        color: var(--lspecial);
                        transition: opacity 550ms ease;
                    }

                    &:focus {
                        &::placeholder {
                            opacity: 0;
                        }
                    }
                }

                >span {
                    position: absolute;
                    right: 2rem;
                    top: 50%;
                    transform: translate(-0%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: -1;

                    .kiss_icon {
                        height: 14px;

                        :deep(path) {
                            fill: var(--lspecial);
                        }
                    }
                }
            }

            .min_max_wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
                margin: auto;
                margin-bottom: .85rem;

                .info_wrap {
                    display: flex;
                    align-items: flex-end;

                    small {
                        font-size: .95rem;
                        font-weight: 500;
                        color: var(--lspecial);
                    
                    }
                    span {
                        margin-bottom: -2px;
                        margin-left: .35rem;
                        font-size: 1.2rem;
                        font-weight: 600;
                        color: var(--lspecial);
                    }
                }
                

                span {
                    font-size: 1rem;
                    font-weight: 800;
                    color: var(--lspecial);
                }
            }

            .buttons_wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .85rem;
                width: 100%;
                margin-top: 3rem;

                .cancel_button,
                .apply_button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    flex-grow: 1;
                    flex-shrink: 1;
                    width: 100%;
                    padding: 0.65rem 1rem;

                    span {
                        font-size: 16px;
                        font-weight: 500;
                        color: var(--grey);
                        cursor: pointer;
                    }

                    &.pressed {
                        transform: scale(.96);
                    }
                    &:active {
                        transform: scale(.96);
                    }
                }
                .apply_button {
                    border-radius: .6rem;
                    background-color: var(--description);
                    cursor: pointer;

                    span {
                        font-size: 14px;
                        font-weight: 500;
                        color: var(--lspecial);
                    }
                }
            }
        }
        
    }
</style>