<template>
    <div class="user_feed_page_wrapper">
        <div class="main_content_wrapper">
            <stories-block></stories-block>
            <div class="feed_wrapper">
                <template v-for="(thisPostData, thisPostIdx) in feed" :key="thisPostIdx">
                    <user-feed-post :postData="thisPostData" @selectedUserPost="selectUserPost"></user-feed-post>
                </template>
            </div>
        </div>
        <div class="profile_controls_wrapper" v-if="myProfileData">
            <div class="user_fast_setting_wrapper">
                <div class="author_right_block_wrapper">
                    <div class="author_wrapper">
                        <userAvatarCircle :avatar="myProfileData.avatar" defaultSize="medium" />
                        <div class="author_post_actions_wrapper">
                            <div class="name_status_icon_wrapper">
                                <div class="name_status_wrapper">
                                    <div class="author_name">
                                        <span>{{ myProfileData.username }}</span>
                                    </div>
                                    <div  class="status_icon">
                                        <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный"></status-Icon-SVG>
                                    </div>
                                </div>
                            </div>
                            <div class="profile_edit_settings_buttons">
                                <button class="round_button noselect" v-mobile-touch :title="$t('user_profile.edit_profile')" @click="editProfile()">
                                    <span>{{ $t('user_profile.edit_profile') }}</span>
                                </button>
                                <button class="round_button settings noselect" v-mobile-touch :title="$t('user_profile.settings.title')" @click="profileSettings()">
                                    <settings-Icon-SVG></settings-Icon-SVG>
                                </button>
                                <!-- <button class="round_button find_users noselect" title="Hunters" v-mobile-touch>
                                    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M22.3929778,9.57460818 C21.8999418,9.07704923 21.1491213,8.93934674 20.5116058,9.22955952 L20.5116058,9.22955952 L12.8857738,12.7010265 L9.3113023,20.2034224 C8.98768785,20.8826511 9.10350159,21.6897679 9.60505224,22.2505931 C10.0971547,22.8008535 10.8906983,22.9656142 11.5611804,22.6567371 L11.5611804,22.6567371 L19.3003545,19.0914613 L22.7347886,11.4654488 C23.0231442,10.8251674 22.8872482,10.0734128 22.3929778,9.57460818 Z M16,14.5081435 C16.8284271,14.5081435 17.5,15.1797164 17.5,16.0081435 C17.5,16.8365707 16.8284271,17.5081435 16,17.5081435 C15.1715729,17.5081435 14.5,16.8365707 14.5,16.0081435 C14.5,15.1797164 15.1715729,14.5081435 16,14.5081435 Z" id="Combined-Shape" fill="#838387"></path>
                                        </g>
                                    </svg>
                                </button> -->
                            </div>
                        </div>
                    </div>
                    <div class="back_profile_photo" :class="{
                        'add_photo': !myProfileCover
                    }" ref="myProfileCoverPhotoElm">
                        <addPhotoIconSVG/>
                        <div class="without_photo">
                            <nophoto-Icon-SVG></nophoto-Icon-SVG>
                        </div> 
                        <div class="user_header_info_wrapper">
                            <div class="full_name">
                                <span>{{ loginData.row.display_name }}</span>
                            </div>
                            <div class="porfile_category">
                                <span>&#xB7; {{ myProfileData.category }} &#xB7;</span>
                            </div>
                        </div>
                    </div>
                    <div class="user_achivments_wrapper noselect">
                        <div class="posts_count" v-mobile-touch>
                            <span>
                                <number-formating :number="myProfileData.postsCount" />
                            </span>
                            <small>{{ $t('user_profile.posts', myProfileData.correctedTransale_posts) }}</small>
                        </div>
                        <div class="followers_count" v-mobile-touch>
                            <span>
                                <number-formating :number="myProfileData.followers" />
                            </span>
                            <small>{{ $t('user_profile.followers', myProfileData.correctedTransale_followers) }}</small>
                        </div>
                        <div class="following_count" v-mobile-touch>
                            <span>
                                <number-formating :number="myProfileData.following" />
                            </span>
                            <small>{{ $t('user_profile.following', myProfileData.correctedTransale_following) }}</small>
                        </div>
                        <div class="fans_count" v-mobile-touch>
                            <span>
                                <number-formating :number="myProfileData.fans" />
                            </span>
                            <small>{{ $t('user_profile.enjoyers', myProfileData.correctedTransale_fans) }}</small>
                        </div>
                    </div>
                </div>
                <div class="explore_users_wrapper opened">
                    <!-- <div class="categories_controls_wrapper">
                        <div class="categories_selector opened">
                            <div class="categories_select_button_wrapper">
                                <span>Selected categories:</span>
                                <div class="add_categories_button">
                                    <close-Icon-SVG></close-Icon-SVG>
                                </div>
                            </div>
                            <div class="categories_selector_wrapper">
                                <form action="">
                                    <div class="scroller noselect noscroll">
                                        <label class="category_wrapper">
                                            <span>Motorcycles</span>
                                            <input type="checkbox" checked>
                                        </label>
                                        <label class="category_wrapper">
                                            <span>Cars</span>
                                            <input type="checkbox" checked>
                                        </label>
                                        <label class="category_wrapper">
                                            <span>Lifestyle</span>
                                            <input type="checkbox" checked>
                                        </label>
                                        <label class="category_wrapper">
                                            <span>Artist</span>
                                            <input type="checkbox" checked>
                                        </label>
                                    </div>
                                    <div class="row_wrapper">
                                        <div class="categories_list_wrapper">
                                            <div class="popular_categories_wrapper">
                                                <label class="category_wrapper">
                                                    <span>Artist</span>
                                                    <input type="checkbox" checked>
                                                </label>
                                                <label class="category_wrapper">
                                                    <span>Motorcycles</span>
                                                    <input type="checkbox" checked>
                                                </label>
                                                <label class="category_wrapper">
                                                    <span>Cars</span>
                                                    <input type="checkbox" checked>
                                                </label>
                                                <label class="category_wrapper">
                                                    <span>Sport</span>
                                                    <input type="checkbox">
                                                </label>
                                                <label class="category_wrapper">
                                                    <span>Extream</span>
                                                    <input type="checkbox">
                                                </label>
                                                <label class="category_wrapper">
                                                    <span>Lifestyle</span>
                                                    <input type="checkbox" checked>
                                                </label>
                                            </div>
                                            <div class="all_categories_list_button">
                                                <span>Categories list</span>
                                                <select name="" id="">
                                                    <option value="" selected disabled>More Categories</option>
                                                    <option value="">Artist</option>
                                                    <option value="">Artist</option>
                                                    <option value="">Artist</option>
                                                    <option value="">Artist</option>
                                                    <option value="">Artist</option>
                                                    <option value="">Artist</option>
                                                    <option value="">Artist</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div> -->
                    <div class="users_explorer_wrapper">
                        <div class="explore_heading_wrapper noselect">
                            <span>{{ $t('feed-page.suggested-section') }}</span>
                            <span>{{ $t('feed-page.all') }}</span>
                        </div>
                        <div class="scroller noselect noscroll snaps_inline">

                            <div class="user_explore_wrapper" v-for="thisItem in 10" :key="thisItem">
                                <div class="author_wrapper">
                                    <userAvatarCircle />
                                    <div class="name_status_icon_wrapper">
                                        <div class="name_status_wrapper">
                                            <div class="author_name">
                                                <span>{{ myProfileData.username }}</span>
                                            </div>
                                            <div  class="status_icon">
                                                <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный"></status-Icon-SVG>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="back_profile_photo no_photo">
                                    <div class="without_photo">
                                        <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                    </div>
                                    <div class="user_header_info_wrapper">
                                        <div class="full_name">
                                            <span>{{ loginData.row.display_name }}</span>
                                        </div>
                                        <div class="porfile_category">
                                            <span>Artist</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="user_explore_wrapper">
                                <div class="author_wrapper">
                                    <div class="avtar_oreon no_stories">
                                        <oreon-Stroke-SVG></oreon-Stroke-SVG>
                                        <div class="user_avatar no_avatar">
                                            <div class="without_photo">
                                                <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                            </div> 
                                            <img draggable="false" alt="user-avatar">
                                        </div>
                                    </div>
                                    <div class="name_status_icon_wrapper">
                                        <div class="name_status_wrapper">
                                            <div class="author_name">
                                                <span>{{ myProfileData.username }}</span>
                                            </div>
                                            <div  class="status_icon">
                                                <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный"></status-Icon-SVG>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="back_profile_photo">  
                                    <div class="without_photo">
                                        <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                    </div>
                                    <div class="user_header_info_wrapper">
                                        <div class="full_name">
                                            <span>{{ loginData.row.display_name }}</span>
                                        </div>
                                        <div class="porfile_category">
                                            <span>Artist</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="user_explore_wrapper">
                                <div class="author_wrapper">
                                    <div class="avtar_oreon">
                                        <oreon-Stroke-SVG></oreon-Stroke-SVG>
                                        <div class="user_avatar no_avatar">
                                            <div class="without_photo">
                                                <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                            </div> 
                                            <img draggable="false" alt="user-avatar">
                                        </div>
                                    </div>
                                    <div class="name_status_icon_wrapper">
                                        <div class="name_status_wrapper">
                                            <div class="author_name">
                                                <span>{{ myProfileData.username }}</span>
                                            </div>
                                            <div  class="status_icon">
                                                <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный"></status-Icon-SVG>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="back_profile_photo"> 
                                    <div class="without_photo">
                                        <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                    </div> 
                                    <div class="user_header_info_wrapper">
                                        <div class="full_name">
                                            <span>{{ loginData.row.display_name }}</span>
                                        </div>
                                        <div class="porfile_category">
                                            <span>Artist</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="user_explore_wrapper">
                                <div class="author_wrapper">
                                    <div class="avtar_oreon no_stories">
                                        <oreon-Stroke-SVG></oreon-Stroke-SVG>
                                        <div class="user_avatar no_avatar">
                                            <div class="without_photo">
                                                <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                            </div> 
                                            <img draggable="false" alt="user-avatar">
                                        </div>
                                    </div>
                                    <div class="name_status_icon_wrapper">
                                        <div class="name_status_wrapper">
                                            <div class="author_name">
                                                <span>{{ myProfileData.username }}</span>
                                            </div>
                                            <div  class="status_icon">
                                                <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный"></status-Icon-SVG>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="back_profile_photo no_photo"> 
                                    <div class="without_photo">
                                        <nophoto-Icon-SVG></nophoto-Icon-SVG>
                                    </div> 
                                    <div class="user_header_info_wrapper">
                                        <div class="full_name">
                                            <span>{{ loginData.row.display_name }}</span>
                                        </div>
                                        <div class="porfile_category">
                                            <span>Artist</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top_users_zone"></div>
            </div>
        </div>
    </div>
    <user-post-view v-if="selectedUserPostData" :postData="selectedUserPostData" @closePost="closeUserPostView()"></user-post-view>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useLoginData } from '@/store/loginData';
import socketIoAPI from '@/modules/socketAPI';
import corrctTranslateNumber from '@/mixins/correntTranslateNumber';
import addPhotoIconSVG from '@/components/svg/add-photo-icon.vue';
import storiesBlock from '@/components/feed/storiesBlock.vue';
import userFeedPost from '@/components/post/userFeedPost.vue';
import userPostView from '@/components/post/userPostView.vue';
import { useImageSizes } from '@/plugins/useImageSizes';
import userAvatarCircle from '@/components/userAvatarCircle.vue';

const loginData = useLoginData();
const router = useRouter();

type PostMedia = {
    id: number,
    type: string,
    url: string,
    selected: boolean
}

type PostData = {
    id: number,
    user: {
        id: number,
        name: string,
        display_name: string,
        avatar: string,
        verified?: boolean,
    },
    media: PostMedia[],
    description: string,
    commentCount: number,
    kissesCount: number,
    timestamp: number,
    userInteraction: {
        isKissed: boolean
    }
}

const myProfileData = ref<any>(null)
const selectedUserPostData = ref<PostData | null>(null);
const feed = ref<PostData[]>([]);
const [ myProfileCoverPhotoElm, myProfileCover ] = useImageSizes({
    defaultSize: 'medium'
})

let oldPageLocation: string | null = null;

watch(selectedUserPostData, (newVal) => {
    const nextState = {}
    if (newVal) {
        oldPageLocation = window.location.pathname;
        const nextURL = `/p/${newVal.id}`;
        const nextTitle = 'Post Page'
        window.history.pushState(nextState, nextTitle, nextURL);
    } else {
        const nextURL = oldPageLocation;
        const nextTitle = 'Feed Page'
        oldPageLocation = null;
        window.history.replaceState(nextState, nextTitle, nextURL);
    }
})

getMyProfileData()

function getMyProfileData() {
    if (!socketIoAPI.socket) return
    socketIoAPI.socket.emit('getMyProfileData', (data: any) => {
        if (!data.success) return console.error(data.error);
        // console.log(data.data);
        myProfileCover.value = data.data.cover;
        delete data.data.cover;
        data.data.correctedTransale_posts = corrctTranslateNumber(data.data.postsCount);
        data.data.correctedTransale_followers = corrctTranslateNumber(data.data.followers);
        data.data.correctedTransale_following = corrctTranslateNumber(data.data.following);
        data.data.correctedTransale_fans = corrctTranslateNumber(data.data.fans);
        myProfileData.value = data.data;
    });
}

function editProfile() {
    return router.push('/profile/edit')
}
function profileSettings() {
    return router.push('/profile/settings')
}

function selectUserPost(postData: PostData) {
    selectedUserPostData.value = postData;
}
function closeUserPostView() {
    selectedUserPostData.value = null;
}

for (let index = 0; index < 10; index++) {
    feed.value.push({
        id: index,
        user: {
            id: index,
            name: 'guest_21688189',
            avatar: '/img/960x718_6ae29c211184c9d38038c1610a631be8.jpg',
            display_name: 'Jomo Kakukulalkjg'
        },
        media: [
            {
                id: index,
                type: 'image',
                url: '/img/960x718_6ae29c211184c9d38038c1610a631be8.jpg',
                selected: true
            },
            {
                id: index + 1,
                type: 'image',
                url: '/img/960x718_6ae29c211184c9d38038c1610a631be8.jpg',
                selected: false
            },
            {
                id: index + 2,
                type: 'image',
                url: '/img/960x718_6ae29c211184c9d38038c1610a631be8.jpg',
                selected: false
            }
        ],
        description: 'This post will provide A Roadmap for Becoming a Front-end Developer \n\nlet’s go through the let’s go through the RoadMap step by step and find out how you can learn the let’s go through the RoadMap step by step and find out how you can learn the essential skills to become a Front-end Developer in 2022 and the coming years. \n\nIf This Post Is Helpful For You Then Like & Share The Post & Follow Us For More Such Amazing Content 🥰🥰🙏 \nI have already made posts on Other Javascript Important topics. Go and check out my Javascript Guide. \n\nIf you have any queries then let me know in the comment box.',
        commentCount: Math.floor(Math.random() * 100),
        kissesCount: Math.floor(Math.random() * 10000000),
        timestamp: new Date().getTime(),
        userInteraction: {
            isKissed: false
        }
    })
}
</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/feedPage.scss';
</style>