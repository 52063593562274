<!-- 
    Types:
        - number
        - money
        - money_short
        - money_space
        - money_dot

 -->

<template>
    <span>
        <number-formating :number="twinstedNumber" :numberType="numberType"></number-formating>
    </span>
</template>

<script>
import numberFormatingVue from '@/plugins/numberFormating.vue';
import { useGlobalData } from '@/store/globalData';

export default {
    components: {
        numberFormating: numberFormatingVue,
    },
    props: {
        animNumber: {
            type: Number,
            required: true
        },
        animDuration: {
            type: Number,
            default: 1000
        },
        animDelay: {
            type: [Number, String],
            default: 0
        },
        observerThreshold: {
            type: Number,
            default: 0.5
        },
        checkForMobile: {
            type: Boolean,
            default: true
        },
        checkForTablet: {
            type: Boolean,
            default: true
        },
        numberType: {
            type: String,
            default: 'number'
        }
    },
    data() {
        return {
            twinstedNumber: 0,
            thisAnimDelay: 0,
        }
    },
    methods: {
        animateNumbers: function(oldNumber, newNumber, time) {
            let positiveAnimate = true;
            if (oldNumber > newNumber) positiveAnimate = false;

            let intervalStep = Math.round((newNumber - oldNumber) / (time / 10));
            let interval = setInterval(() => {
                let thisIntervalStep = intervalStep;
                if (intervalStep < 1 && positiveAnimate) thisIntervalStep = 1;
                else if (intervalStep > -1 && !positiveAnimate) thisIntervalStep = -1;

                // let thisValueNow = objFromString(this, changeParam)
                // if (thisValueNow < newNumber && positiveAnimate) {
                //     objFromString(this, changeParam, (thisValueNow + thisIntervalStep))
                // } else if (thisValueNow > newNumber && !positiveAnimate) {
                //     objFromString(this, changeParam, (thisValueNow + thisIntervalStep))
                // } else {
                //     if (thisValueNow > newNumber || thisValueNow < newNumber) {
                //         objFromString(this, changeParam, newNumber)
                //     }
                //     clearInterval(interval);
                // }

                if (this.twinstedNumber < newNumber && positiveAnimate) {
                    this.twinstedNumber += thisIntervalStep;
                } else if (this.twinstedNumber > newNumber && !positiveAnimate) {
                    this.twinstedNumber += thisIntervalStep;
                } else {
                    if (this.twinstedNumber > newNumber || this.twinstedNumber < newNumber) {
                        this.twinstedNumber = newNumber;
                    }
                    clearInterval(interval);
                }
            }, 10);
        }
    },
    watch: {
        'animNumber': function(newValue, oldValue) {
            this.animateNumbers(oldValue, newValue, this.animDuration);
        }
    },
    mounted() {
        const globalData = useGlobalData();
        this.thisAnimDelay = this.animDelay;

        if (this.thisAnimDelay === 'on_view' && !globalData.getHasObserver) {
            this.thisAnimDelay = 0;
        } else if ((this.checkForMobile && (this.checkForTablet && window.device.mobile())) || window.device.tablet()) {
            this.thisAnimDelay = 0;
        }

        if (this.thisAnimDelay === 'on_view') {
            let observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    this.animateNumbers(0, this.animNumber, this.animDuration);
                    observer.disconnect();
                }
            }, {
                threshold: this.observerThreshold
            });
            observer.observe(this.$el);
        } else if (this.thisAnimDelay > 0) {
            setTimeout(() => {
                this.animateNumbers(0, this.animNumber, this.animDuration);
            }, this.thisAnimDelay);
        } else {
            this.twinstedNumber = this.animNumber;
        }
    }
}

</script>