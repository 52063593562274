<template>
    <picture>
        <source v-for="(size) in sizes" :key="size.src" :srcset="size.src" :media="size.media">
        <img draggable="false" :src="defaultImage" :alt="alt">
    </picture>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { imagesObj, sizesByWidth } from '@/plugins/useImageSizes';
import photoFunctions from '@/mixins/photoFunctions';

type ComponentProps = {
    images: imagesObj[],
    sizesByWidth?: sizesByWidth | null,
    defaultSize?: string,
    alt?: string
}

const props = withDefaults(defineProps<ComponentProps>(), {
    defaultSize: 'original',
    sizesByWidth: null,
    alt: ''
})

const defaultImage = computed(() => {
    const findedImage = props.images.find(image => image.size === props.defaultSize)
    if (!findedImage) return ''
    return photoFunctions.serverPhotoPathToUrl(findedImage.full_path)
})

type sizesReturn = {
    src: string;
    width: number;
    minWidth: number;
    media?: string;
}

const sizes = computed(() => {
    if (!props.sizesByWidth) return []
    const thisSizes = Object.keys(props.sizesByWidth)
    const newSizes: sizesReturn[] = []
    const filteredData = props.images.filter(image => thisSizes.includes(image.size)).sort((a, b) => {
        const thisAWidth = props.sizesByWidth ? props.sizesByWidth[a.size] : 0
        const thisBWidth = props.sizesByWidth ? props.sizesByWidth[b.size] : 0

        return thisAWidth - thisBWidth
    })
    filteredData.forEach((image, imageIdx) => {
        const prevImage = filteredData[imageIdx - 1]
        const data: sizesReturn = {
            src: photoFunctions.serverPhotoPathToUrl(image.full_path),
            width: props.sizesByWidth ? props.sizesByWidth[image.size] : 0,
            minWidth: 0,
        }
        if (prevImage) {
            data.minWidth = props.sizesByWidth ? props.sizesByWidth[prevImage.size] : 0
        }
        data.media = `(max-width: ${data.width}px)`
        newSizes.push(data)
    })

    return newSizes
})

</script>

<style lang="scss" scoped>
    img {
        aspect-ratio: 1/1;
        object-fit: cover;
        object-position: top;
        width: 100%;
        color: transparent;
        z-index: 1;
    }
</style>