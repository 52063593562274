<template>
    <div class="followers_stories_wrapper">
        <div class="scroller sanps_inline noscroll noselect" v-trix-horizontal-slider.overElement>
            <template v-for="(thisStory, thisStoryIdx) in storiesList" :key="thisStoryIdx">
                <stories-item :data="thisStory"></stories-item>
            </template>
        </div>
    </div>
</template>

<script>
import storiesItemVue from '@/components/feed/storiesItem.vue';

export default {
    name: 'storiesBlock',
    components: {
        storiesItem: storiesItemVue
    },
    data() {
        return {
            storiesList: Array(20)
        }
    }
}
</script>

<style>

</style>