<template>
    <router-view/>
</template>

<script setup lang="ts">

</script>

<style lang="scss" scoped>

</style>