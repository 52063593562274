<template>
     <svg class="kiss_icon" width="39px" height="28px" viewBox="0 0 39 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>{{ title }}</title>
    <defs>
        <path d="M0.450837105,0.698483029 C5.70420481,4.60536056 1.96931736,8.90848885 11.2328839,12.8312917 C16.0995379,14.0793919 19.5382533,14.2319145 24.4503127,12.8312917 C32.2650839,9.80161983 29.941261,4.60536056 34.7739661,0.497934458 C34.9624899,0.33770381 35.7826154,-0.236388915 34.7739661,0.108314421 C34.3411316,0.256234517 33.7266087,0.430621002 33.2986802,0.497934458 C27.7644128,1.36847848 25.2563564,0.577176942 22.5503903,1.36847848 C20.1585105,2.06793226 19.8303515,2.54419621 17.6335821,2.54419621 C15.4368127,2.54419621 15.0156272,1.9368239 12.6893662,1.36847848 C8.94175757,0.452873506 5.07367252,1.02398349 1.96931736,0.577176942 C1.41875065,0.497934458 1.09428977,0.435338453 0.450837105,0.342679322 C-0.417324718,0.217661392 0.189112425,0.503840977 0.450837105,0.698483029 Z" id="path-1"></path>
        <path d="M0.213106026,11.9528067 C4.78674976,9.51085608 4.63585264,3.93845219 11.4163742,0.425910157 C12.8519019,-0.245028311 14.4245725,-0.0287019419 15.7159781,0.425910157 C19.648839,2.10481385 19.1656727,2.10481385 22.9421944,0.425910157 C24.2148343,-0.0217946741 25.5007694,-0.087701747 26.8068848,0.425910157 C33.4548135,3.42308555 32.7253357,9.12934932 37.6711123,11.5491902 C37.7567388,11.591085 37.9624972,11.6928923 37.6896731,11.7819252 C33.3454969,13.199596 29.7613159,13.0230544 22.9421944,12.6596964 C20.3384267,13.3835461 17.653787,13.3835461 14.9420355,12.6596964 C9.00956693,12.8915311 4.81677044,13.0230544 0.179315434,12.2190509 C-0.192338718,12.1546166 0.113087854,12.0062082 0.213106026,11.9528067 Z" id="path-3"></path>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Feed-loged-in" transform="translate(0.849521, 0.135856)">
                <g id="bottom-lip" transform="translate(1.391193, 12.327000)">
                    <mask id="mask-2" fill="white">
                        <use xlink:href="#path-1"></use>
                    </mask>
                    <use id="bt-lip" fill="#838387" xlink:href="#path-1"></use>
                    <path d="M3.95983824,5.83307638 C11.7864728,13.1162063 24.1049813,16.1025142 31.4573922,4.29910034 C32.7742685,2.18501347 32.0988706,0.875583462 30.4843531,0.875583462 L35.1991766,2.13162821e-14 C35.3024725,2.13162821e-14 35.2851136,0.0787484347 35.1755064,0.167035038 C28.6060493,5.45861412 33.5896262,13.1141404 18.1313726,13.825395 C8.37017231,13.6738323 5.38534536,9.93323443 3.95983824,5.83307638 Z" id="shadow-path-bottom" fill="#650e3a00" opacity="0.348392305" mask="url(#mask-2)"></path>
                </g>
                <g id="upper-lip">
                    <mask id="mask-4" fill="white">
                        <use xlink:href="#path-3"></use>
                    </mask>
                    <use id="up-lip" fill="#838387" xlink:href="#path-3"></use>
                    <path d="M1.39119316,12.4380721 C7.77768448,12.7774179 12.2946319,12.4256441 14.9420355,11.3827509 C18.913141,9.81841096 21.3416755,10.4481021 26.0875993,11.6638862 C30.8335232,12.8796703 33.5357809,7.83983171 32.8879347,6.23420336 C33.9343908,7.66849826 36.6457674,10.9013564 38.028156,11.6638862 C36.0576011,13.0444554 30.8993651,13.3372101 22.9421944,12.6596964 C20.3345873,13.5692817 17.6781865,13.7280605 14.9420355,12.6596964 C10.3994072,12.8188957 5.79546577,13.7297007 1.39119316,12.4380721 Z" id="shadow-path-up" fill="#650e3a00" opacity="0.345970517" mask="url(#mask-4)"></path>
                </g>
                <g id="bliks" transform="translate(6.215427, 1.329551)" fill="#ffffff00">
                    <path d="M3.78364007e-13,5.53718261 C2.35487002,2.39878495 4.40364605,0.62668242 6.14632809,0.220875007 C7.88901013,-0.184932407 9.52233322,0.172764488 11.0462973,1.29396569 C12.4420297,1.90596539 13.3524626,1.90596539 14.814694,1.29396569 C16.6633125,0.431321897 18.0351399,-4.4408921e-16 18.9301762,-4.4408921e-16 C19.8252124,-4.4408921e-16 20.5904678,0.338945044 21.2259424,1.01683513 C20.9598411,0.892821597 19.8707501,0.590517232 18.9301762,0.608711121 C17.8997657,0.628642753 16.8188131,1.06315905 16.3805212,1.29396569 C14.7536252,2.17001895 13.5632342,2.60804558 12.8093483,2.60804558 C12.0554624,2.60804558 11.3315311,2.40776721 10.6375545,2.00721047 C8.47065513,0.603473036 7.08994098,0.603586168 5.82352979,1.01683513 C4.97925566,1.29233444 3.03807906,2.79911694 3.78364007e-13,5.53718261 Z" id="Path-17"></path>
                    <path d="M4.25947096,12.5064758 C5.66982475,12.7091811 6.13270632,12.6309293 7.37714712,13.150153 C7.77652927,13.316789 8.52777551,13.5143381 9.18382309,13.7927838 C10.66553,14.4247698 11.2798759,14.5524052 12.3931792,14.4659909 C13.4834071,14.3813677 13.3625782,15.0781752 12.9302246,15.4307177 C12.4978709,15.7832601 11.6385663,15.8818358 10.330357,15.6026128 C9.02214769,15.3233897 8.48961698,14.8727052 7.25072587,14.2188981 C6.01183476,13.5650909 5.82123967,13.7273281 3.89280709,13.150153 C1.96437451,12.5729778 1.14573384,13.370831 1.25291319,12.7883096 C1.36009253,12.2057882 2.77774508,12.2935124 4.25947096,12.5064758 Z" id="Path-18"></path>
                    <path d="M17.2643797,14.2185043 C16.137223,13.6122856 14.6717986,13.4031854 15.018168,13.0582318 C15.3645375,12.7132782 16.24128,13.0195199 17.8765253,13.4031854 C19.5117707,13.7868509 20.2905962,14.296672 19.6463866,14.7319859 C19.002177,15.1672998 18.3915364,14.8247231 17.2643797,14.2185043 Z" id="Path-18-Copy" transform="translate(17.419310, 13.917970) scale(-1, 1) translate(-17.419310, -13.917970) "></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'kissAnimationIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .animation_kiss_wrapper {
        .kisses-icon {
            .kiss_icon {
                height: 82px;
                width: 88px;

                #Feed-loged-in {
                    #bliks {
                        opacity: 0;
                        transition: opacity 250ms cubic-bezier(0.12, 0, 0.39, 0) 0s;
                    }
                    #shadow-path-up {
                        opacity: 0;
                        transition: opacity 250ms cubic-bezier(0.12, 0, 0.39, 0) 0s;
                    }
                    #shadow-path-bottom {
                        opacity: 0;
                        transition: opacity 250ms cubic-bezier(0.12, 0, 0.39, 0) 0s;
                        
                    }
                    #upper-lip {
                        #up-lip {
                            opacity: 0;
                            transition: fill 250ms cubic-bezier(0.12, 0, 0.39, 0) 0s;
                        }
                    }
                    #bottom-lip {
                        #bt-lip {
                            opacity: 0;
                            transition: fill 250ms cubic-bezier(0.12, 0, 0.39, 0) 0s;
                        }
                    }
                }
            }
        }

        &.kissed{
            .kiss_icon {
                #Feed-loged-in {
                    #bliks {
                        opacity: 1;
                        fill: #ffffff;
                    }

                    #shadow-path-up {
                        opacity: 1;
                        fill: #650E3A;
                    }

                    #shadow-path-bottom {
                        opacity: 1;
                        fill: #650E3A;
                    }

                    #upper-lip {
                        #up-lip {
                            opacity: 1;
                            fill: var(--700);
                        }
                    }
                    
                    #bottom-lip {
                        #bt-lip {
                            opacity: 1;
                            fill: var(--700);
                        }
                    }
                }
            }
        }
    }

    .post_kisses_count {
        .kissed_kiss-icon {
            .kiss_icon {
                height: 18px;
                width: 26px;

                #Feed-loged-in {
                    #bliks {
                        opacity: 1;
                        fill: #ffffff;
                    }

                    #shadow-path-up {
                        opacity: 1;
                        fill: #650E3A;
                    }

                    #shadow-path-bottom {
                        opacity: 1;
                        fill: #650E3A;
                    }

                    #upper-lip {
                        #up-lip {
                            opacity: 1;
                            fill: var(--700);
                        }
                    }
                    
                    #bottom-lip {
                        #bt-lip {
                            opacity: 1;
                            fill: var(--700);
                        }
                    }
                }
            }
        }
    }
    
</style>