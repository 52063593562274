<template>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="feed" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M6,0 L26,0 C29.3137085,-6.08718376e-16 32,2.6862915 32,6 L32,8 C32,11.3137085 29.3137085,14 26,14 L6,14 C2.6862915,14 4.05812251e-16,11.3137085 0,8 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 Z M6,18 L26,18 C29.3137085,18 32,20.6862915 32,24 L32,26 C32,29.3137085 29.3137085,32 26,32 L6,32 C2.6862915,32 4.05812251e-16,29.3137085 0,26 L0,24 C-4.05812251e-16,20.6862915 2.6862915,18 6,18 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'feedSolidIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>
    
<style lang="scss" scoped>
    svg {
        height: 21px;
        width: 21px;

        @media(max-width: 767px) {
            height: 20px;
            width: 21px;
        }
    }

    .section_wrapper {
        &.router-link-exact-active{
            #feed {
                path {
                    fill: var(--dpurple);
                }
            }
        }
    }
</style>