<template>
    <div class="day_night_mode_wrapper noselect" v-mobile-touch @click="toggleDarkMode()" :class="{
            'dark': globalData.getDarkMode
        }">
        <div class="switcher_wrapper">
            <div class="mode_icon">
                <svg class="light_icon" width="34px" height="32px" viewBox="0 0 34 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>{{ $t('theme.light_mode') }}</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group-40" transform="translate(0.493811, 0.000000)" fill="#838387">
                                <circle id="Oval" cx="16.5061889" cy="16" r="8"></circle>
                                <rect id="Rectangle" x="15.5061889" y="0" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-29" x="15.5061889" y="26" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-30" transform="translate(3.522569, 16.037274) rotate(-270.000000) translate(-3.522569, -16.037274) " x="2.5225686" y="13.0372742" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-32" transform="translate(7.384747, 25.215396) rotate(-315.000000) translate(-7.384747, -25.215396) " x="6.38474664" y="22.2153961" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-31" transform="translate(29.515175, 16.030938) rotate(-270.000000) translate(-29.515175, -16.030938) " x="28.5151748" y="13.030938" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-33" transform="translate(25.759814, 6.831368) rotate(-315.000000) translate(-25.759814, -6.831368) " x="24.7598144" y="3.83136766" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-32" transform="translate(7.380266, 6.835848) rotate(-225.000000) translate(-7.380266, -6.835848) " x="6.38026631" y="3.83584799" width="2" height="6" rx="1"></rect>
                                <rect id="Rectangle-Copy-33" transform="translate(25.764295, 25.210916) rotate(-225.000000) translate(-25.764295, -25.210916) " x="24.7642947" y="22.2109157" width="2" height="6" rx="1"></rect>
                        </g>
                    </g>
                </svg>

                <svg class="dark_icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>{{ $t('theme.dark_mode') }}</title>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path d="M16,0 C16.9334932,0 17.8483147,0.0799425658 18.7379921,0.233355035 C13.7475017,1.27641075 10,5.70056741 10,11 C10,17.0751322 14.9248678,22 21,22 C26.2994326,22 30.7235892,18.2524983 31.7669826,13.2629821 C31.9200574,14.1516853 32,15.0665068 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z" id="Combined-Shape" fill="#838387"></path>
                    </g>
                </svg>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useGlobalData } from '@/store/globalData';

const globalData = useGlobalData();

function toggleDarkMode(value = null) {
    if (value !== null) globalData.toggleDarkMode(value);
    else globalData.toggleDarkMode();
}
</script>

<style lang="scss" scoped>
    .day_night_mode_wrapper {
        .dark_icon {
            display: none;
        }
        &.dark {
            .light_icon {
                display: none;
            }
            .dark_icon {
                display: block;
            }
        }
    }

    .day_night_mode_wrapper{
        &.settings {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 2rem;
            flex-grow: 0;
            cursor: pointer;

            &.pressed {
                background: var(--100);
            }

            &:active {
                background: var(--100);
            }

            .switcher_wrapper {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;

                .mode_icon {
                    display: flex;
                    align-items: center;
                    position: relative;
                    width: 26px;
                    height: 26px;
                }
            }
            svg {
                position: absolute;
                inset: 0;
                margin: auto;
        
                &.light_icon {
                    height: 20px;
                    width: 20px;
        
                    #Group-40 {
                        fill: var(--500);
                    }
                }
        
                &.dark_icon {
                    height: 17px;
                    width: 18px;
                    path {
                        fill: var(--600);
                    }
                }
            }
        }
        
       
    }
</style>