<template>
    <svg width="20px" height="6px" viewBox="0 0 20 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ $t('more-actions') }}</title>
        <g id="moreVertical" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M3,0.619637573 C4.38071187,0.619637573 5.5,1.7389257 5.5,3.11963757 C5.5,4.50034945 4.38071187,5.61963757 3,5.61963757 C1.61928813,5.61963757 0.5,4.50034945 0.5,3.11963757 C0.5,1.7389257 1.61928813,0.619637573 3,0.619637573 Z M10,0.619637573 C11.3807119,0.619637573 12.5,1.7389257 12.5,3.11963757 C12.5,4.50034945 11.3807119,5.61963757 10,5.61963757 C8.61928813,5.61963757 7.5,4.50034945 7.5,3.11963757 C7.5,1.7389257 8.61928813,0.619637573 10,0.619637573 Z M17,0.619637573 C18.3807119,0.619637573 19.5,1.7389257 19.5,3.11963757 C19.5,4.50034945 18.3807119,5.61963757 17,5.61963757 C15.6192881,5.61963757 14.5,4.50034945 14.5,3.11963757 C14.5,1.7389257 15.6192881,0.619637573 17,0.619637573 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'moreVerticalIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }

}
</script>

<style lang="scss" scoped>
    .user_feed_page_wrapper {
        .more_action_button {
            #moreVertical {
                rect {
                    fill: var(--400);
                    opacity: .3; 
                }
            }
        }
    }
    
</style>