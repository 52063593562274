<template>
    <svg class="comments-icon" width="32px" height="30px" viewBox="0 0 32 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Combined Shape</title>
        <g id="comments-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M26,0 C29.3137085,-6.08718376e-16 32,2.6862915 32,6 L32,28.9457141 C32,29.4979988 31.5522847,29.9457141 31,29.9457141 C30.6814808,29.9457141 30.3820143,29.7939759 30.1936359,29.5371335 L25.0147762,22.4760764 L6,22.4760764 C2.6862915,22.4760764 1.29399067e-15,19.7897849 0,16.4760764 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 L26,0 Z M9,9 C7.8954305,9 7,9.8954305 7,11 C7,12.1045695 7.8954305,13 9,13 C10.1045695,13 11,12.1045695 11,11 C11,9.8954305 10.1045695,9 9,9 Z M16,9 C14.8954305,9 14,9.8954305 14,11 C14,12.1045695 14.8954305,13 16,13 C17.1045695,13 18,12.1045695 18,11 C18,9.8954305 17.1045695,9 16,9 Z M23,9 C21.8954305,9 21,9.8954305 21,11 C21,12.1045695 21.8954305,13 23,13 C24.1045695,13 25,12.1045695 25,11 C25,9.8954305 24.1045695,9 23,9 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'commentsIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .post_wrapper {
        .users_coments_wrapper {
            svg {
                height: 12px;
                width: 12px;
                cursor: pointer;

                @media(width <= 430px) {
                    margin-bottom: -1px;
                }

                #comments-icon {
                    path {
                        fill: var(--dpurple);
                    }
                }
            }
        }
    }
    
</style>