<template>
    <svg class="statistic-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M5,22 C6.1045695,22 7,22.8954305 7,24 L7,32 L0,32 L0,24 C-1.3527075e-16,22.8954305 0.8954305,22 2,22 L5,22 Z M15,13 C16.1045695,13 17,13.8954305 17,15 L17,32 L10,32 L10,15 C10,13.8954305 10.8954305,13 12,13 L15,13 Z M26,0 C27.1045695,-2.02906125e-16 28,0.8954305 28,2 L28,32 L20,32 L20,2 C20,0.8954305 20.8954305,2.02906125e-16 22,0 L26,0 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'statisticIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 18px;
        width: 18px;
    }
</style>