<template>
    <svg class="more-icon" width="32px" height="22px" viewBox="0 0 32 22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M30,18 C31.1045695,18 32,18.8954305 32,20 C32,21.1045695 31.1045695,22 30,22 L2,22 C0.8954305,22 1.3527075e-16,21.1045695 0,20 C-1.3527075e-16,18.8954305 0.8954305,18 2,18 L30,18 Z M30,9 C31.1045695,9 32,9.8954305 32,11 C32,12.1045695 31.1045695,13 30,13 L2,13 C0.8954305,13 1.02344917e-15,12.1045695 0,11 C-1.3527075e-16,9.8954305 0.8954305,9 2,9 L30,9 Z M30,0 C31.1045695,-2.02906125e-16 32,0.8954305 32,2 C32,3.1045695 31.1045695,4 30,4 L2,4 C0.8954305,4 1.3527075e-16,3.1045695 0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L30,0 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'menuIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 25px;
        width: 21px;
    }
</style>