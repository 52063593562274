<template>
    <svg class="videos-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="video-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M26,0 C29.3137085,-6.08718376e-16 32,2.6862915 32,6 L32,26 C32,29.3137085 29.3137085,32 26,32 L6,32 C2.6862915,32 4.05812251e-16,29.3137085 0,26 L0,6 C-4.05812251e-16,2.6862915 2.6862915,6.08718376e-16 6,0 L26,0 Z M11.4876006,8.74429072 C10.3830311,8.74429072 9.48760065,9.63972122 9.48760065,10.7442907 L9.48760065,10.7442907 L9.48760065,21.2721548 C9.48760065,21.582645 9.55989083,21.8888711 9.69874627,22.166582 C10.1927248,23.1545389 11.3940708,23.5549876 12.3820278,23.0610091 L12.3820278,23.0610091 L22.9098919,17.7970771 C23.2969467,17.6035497 23.6107917,17.2897047 23.8043191,16.9026499 C24.2982976,15.9146929 23.8978489,14.7133469 22.9098919,14.2193684 L22.9098919,14.2193684 L12.3820278,8.95543633 C12.104317,8.8165809 11.7980908,8.74429072 11.4876006,8.74429072 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'videosIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    #video_icon {
        path {
            fill: var(--500);
            transition: fill 200ms linear;
        }
    }
</style>