<template>
     <div class="header_wrapper" ref="headerWrapper" :style="{
        'transform': `translateY(-${scrollHeaderPosition}px)`,
     }">
        <div class="container">
            <div class="header_logo noselect" @click="$router.push('/')">
                <kissHunter-Icon-SVG></kissHunter-Icon-SVG>
                <logo-Icon-Main-Icon-SVG></logo-Icon-Main-Icon-SVG>
            </div>
            <div class="search_bar">
                <div class="bar_wrapper">
                    <i class="uil uil-search"></i>
                    <input type="search" :placeholder="$t('search')">
                </div>
            </div>
            <div class="actions_wrapper">
                <div class="buttons_wrapper" v-if="!loginData.getLoginState">
                    <div class="action_button" v-mobile-touch @click="openLoginModal()">
                        <loginIconSVG/>
                        <span>{{ $t('login') }}</span>
                    </div>
                    <div class="action_button no_back" v-mobile-touch @click="openRegisterModal()">
                        <registrIconsSVG/>
                        <span>{{ $t('sign-up') }}</span>
                    </div>
                </div>
                <template v-else>
                    <div class="messages_page_icons_wrapper" v-if="isMobileHeaderMessagesPageBlock">
                        <div class="newMessges-icon" @click="$router.push('/messages/new')" v-if="!isNewMessagesPage">
                            <newMessageIconSVG />
                        </div>
                        <div class="close_button_wrapper" v-mobile-touch @click="$router.back()">
                            <close-Icon-SVG></close-Icon-SVG>
                        </div>
                    </div>
                    <div class="action_icons_wrapper" v-else>
                        <div class="icons_wrapper">
                            <router-link to="/" class="section_wrapper top_bar_icon_hide noselect">
                                <div class="feed-icon">
                                    <feed-Solid-Icon-SVG :title="$t('header.your_feed')"></feed-Solid-Icon-SVG>
                                </div>
                                <span class="section_name">{{ $t('header.your_feed') }}</span>
                            </router-link>
                            <div class="section_wrapper top_bar_icon_hide noselect">
                                    <div class="search_icon">
                                        <search-Icon-SVG :title="$t('header.search')"></search-Icon-SVG>
                                    </div>
                                <span class="section_name">{{ $t('header.search') }}</span>
                            </div>
                            <router-link to="/hunters" class="section_wrapper top_bar_icon_hide noselect">
                                <div class="hunters-icon">
                                    <hunterIconSVG :title="$t('header.hunters')"></hunterIconSVG>
                                </div>
                                <span class="section_name">{{ $t('header.hunters') }}</span>
                            </router-link>
                            <div class="section_wrapper noselect">
                                <div class="notifications-icon">
                                    <notification-Icon-SVG :title="$t('header.notifications')"></notification-Icon-SVG>
                                </div>
                                <span class="section_name">{{ $t('header.notifications') }}</span>
                            </div>
                            <div class="section_wrapper top_bar_icon_hide noselect">
                                <div class="add-post-icon">
                                    <add-Post-Icon-SVG :title="$t('header.add_post')"></add-Post-Icon-SVG>
                                </div>
                                <span class="section_name">{{ $t('header.add_post') }}</span>
                            </div>
                            <router-link to="/messages" class="section_wrapper noselect">
                                <div class="message-icon">
                                    <message-icon-SVG :title="$t('header.messages')"></message-icon-SVG>
                                </div>
                                <span class="section_name">{{ $t('header.messages') }}</span>
                            </router-link>
                            <div class="section_wrapper top_bar_icon_hide noselect">
                                <div class="kisses-icon">
                                    <kiss-icon-SVG :title="$t('header.kisses')"></kiss-icon-SVG>
                                </div>
                                <span class="section_name">{{ $t('header.kisses') }}</span>
                            </div>
                            <!-- <div class="section_wrapper top_bar_icon_hide">
                                <div class="lock-icon">
                                    <locker-Icon-SVG :title="$t('header.subscribes')"></locker-Icon-SVG>
                                </div>
                                <span class="section_name">{{ $t('header.subscribes') }}</span>
                            </div> -->
                            <router-link :to="`/${loginData.row.username}`" class="section_wrapper profile top_bar_icon_hide noselect">
                                <div class="loged_user_avatar_wrapper">
                                    <div class="avtar_oreon active">
                                        <div class="user_avatar">
                                            <imageSizesBlock v-if="loginData.row.avatar" :images="loginData.row.avatar" defaultSize="small" alt="user-avatar" />
                                            <nophotoIconSVG v-else></nophotoIconSVG>
                                        </div>
                                    </div>
                                </div>
                                <span class="section_name">{{ $t('header.profile') }}</span>
                            </router-link>
                            <div class="section_with_drop_menu noselect">
                                <div class="section_wrapper" @click.stop="toggleMoreMenu()">
                                    <div class="menu-icon">
                                        <menuIconSVG :title="$t('header.more')"></menuIconSVG>
                                    </div>
                                    <span class="section_name">{{ $t('header.more') }}</span>
                                </div>
                                <Teleport to="body" :disabled="!dynamicHeader">
                                    <Transition :name="moreMenuTransitionName">
                                        <div class="blacked_wrapper" v-if="moreMenuOpened">
                                            <div class="menu_drop_down_wrapper" @click.stop ref="moreMenuElm">
                                                <div class="close_drop_menu" ref="moreMenuCloseElm">
                                                    <div class="line"></div>
                                                </div>
                                                <div class="menu_list_wrapper">
                                                    <!-- <div class="menu_list left_sidebar_hide" v-mobile-touch @click="toggleMoreMenu()">
                                                        <locker-Icon-SVG :title="$t('header.subscribes')"></locker-Icon-SVG>
                                                        <span class="section_name">{{ $t('header.subscribes') }}</span>
                                                    </div> -->
                                                    <router-link to="/profile/settings" class="menu_list" v-mobile-touch @click="toggleMoreMenu()">
                                                        <settingsIconSVG :title="$t('user_profile.settings.title')"></settingsIconSVG>
                                                        <span class="section_name">{{ $t('user_profile.settings.title') }}</span>
                                                    </router-link>
                                                    <div class="menu_list" v-mobile-touch @click="toggleMoreMenu()">
                                                        <statisticIconSVG :title="$t('header.statistic')"></statisticIconSVG>
                                                        <span class="section_name">{{ $t('header.statistic') }}</span>
                                                    </div>
                                                    <div class="menu_list" v-mobile-touch @click="toggleMoreMenu()">
                                                        <portfolioIconSVG :title="$t('header.portfolio')"></portfolioIconSVG>
                                                        <span class="section_name">{{ $t('header.portfolio') }}</span>
                                                    </div>
                                                    <div class="menu_list" v-mobile-touch @click="toggleMoreMenu()">
                                                        <supportIconSVG :title="$t('header.support')"></supportIconSVG>
                                                        <span class="section_name">{{ $t('header.support') }}</span>
                                                    </div>
                                                    <div class="menu_list" v-mobile-touch @click="toggleDarkMode()">
                                                        <appearenceSwitch @click.stop />
                                                        <span class="section_name">{{ $t('user_profile.settings.your-account-settings.appearance') }}</span>
                                                    </div>
                                                    <div class="menu_list mt-auto" v-mobile-touch @click="unlogin()">
                                                        <logoutIconSVG :title="$t('logout')"></logoutIconSVG>
                                                        <span class="section_name">{{ $t('logout') }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Transition>
                                </Teleport>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
     </div>
     <div class="mobile_bottom_header_wrapper">
        <div class="icons_wrapper noselect">
            <router-link to="/" class="section_wrapper">
                <div class="feed-icon">
                    <feed-Solid-Icon-SVG :title="$t('header.your_feed')"></feed-Solid-Icon-SVG>
                </div>
                <span class="section_name">{{ $t('header.your_feed') }}</span>
            </router-link>
            <router-link to="/hunters" class="section_wrapper">
                <div class="hunters-icon">
                    <hunterIconSVG :title="$t('header.hunters')"></hunterIconSVG>
                </div>
                <span class="section_name">{{ $t('header.hunters') }}</span>
            </router-link>
            <div class="section_wrapper top_bar_icon_hide">
                <div class="notifications-icon">
                    <notification-Icon-SVG :title="$t('header.notifications')"></notification-Icon-SVG>
                </div>
                <span class="section_name">{{ $t('header.notifications') }}</span>
            </div>
            <div class="section_wrapper">
                <div class="add-post-icon">
                    <add-Post-Icon-SVG :title="$t('header.add_post')"></add-Post-Icon-SVG>
                </div>
                <span class="section_name">{{ $t('header.add_post') }}</span>
            </div>
            <router-link to="/messages" class="section_wrapper top_bar_icon_hide">
                <div class="message-icon">
                    <message-icon-SVG :title="$t('header.messages')"></message-icon-SVG>
                </div>
                <span class="section_name">{{ $t('header.messages') }}</span>
            </router-link>
            <div class="section_wrapper">
                <div class="kisses-icon">
                    <kiss-icon-SVG :title="$t('header.kisses')"></kiss-icon-SVG>
                </div>
                <span class="section_name">{{ $t('header.kisses') }}</span>
            </div>
            <router-link :to="`/${loginData.row.username}`" class="section_wrapper profile">
                <div class="loged_user_avatar_wrapper">
                    <div class="avtar_oreon active">
                        <div class="user_avatar">
                            <imageSizesBlock v-if="loginData.row.avatar" :images="loginData.row.avatar" defaultSize="small" alt="user-avatar" />
                            <nophotoIconSVG v-else></nophotoIconSVG>
                        </div>
                    </div>
                </div>
                <span class="section_name">{{ $t('header.profile') }}</span>
            </router-link>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLoginData } from '@/store/loginData';
import { useGlobalData } from '@/store/globalData';
import { useRoute } from 'vue-router';
import { useSwipe } from '@vueuse/core';
import hunterIconSVG from '@/components/svg/hunters-icon.vue';
import menuIconSVG from '@/components/svg/menu-icon.vue';
import newMessageIconSVG from '@/components/svg/new-message-icon.vue';
import statisticIconSVG from '@/components/svg/statistic-icon.vue';
import supportIconSVG from '@/components/svg/support-icon.vue';
import logoutIconSVG from '@/components/svg/logout-icon.vue';
import appearenceSwitch from '@/components/appearenceSwitch.vue';
import portfolioIconSVG from '@/components/svg/portfolio-icon.vue';
import loginIconSVG from '@/components/svg/login-icon.vue';
import registrIconsSVG from '@/components/svg/registr-icon.vue';
import imageSizesBlock from '@/components/imageSizesBlock.vue';
import nophotoIconSVG from '@/components/svg/no-photo-icon.vue';

const loginData = useLoginData()
const globalData = useGlobalData()
const route = useRoute()

const headerWrapper = ref<HTMLElement | null>(null)

const moreMenuOpened = ref(false)
const moreMenuElm = ref<HTMLElement | null>(null)
const moreMenuCloseElm = ref<HTMLElement | null>(null)

let openedBodyOverflow: string | null = null
watch(moreMenuOpened, async (value) => {
    if (value) {
        if (moreMenuTransitionName.value) {
            openedBodyOverflow = document.body.style.overflow
            document.body.style.overflow = 'hidden'
        }
        setTimeout(() => {
            document.body.addEventListener('click', closeMoreMenu, { once: true })
        }, 50);
    } else {
        if (openedBodyOverflow !== null) {
            document.body.style.overflow = openedBodyOverflow
            openedBodyOverflow = null
        }
        document.body.removeEventListener('click', closeMoreMenu)
    }
})

function closeMoreMenu() {
    moreMenuOpened.value = false
}

const isMessagesPage = computed(() => {
    const messagesPage = route.matched.find((item) => item.name === 'messages')
    if (messagesPage) return true
    return false
})
const isNewMessagesPage = computed(() => {
    if (!isMessagesPage.value) return false
    const newMessagesPage = route.matched.find((item) => item.name === 'newMessagePage')
    if (newMessagesPage) return true
    return false
})

const isMobileHeaderMessagesPageBlock = computed(() => {
    if (globalData.getScreenWidth > 768) return false
    return isMessagesPage.value
})

const dynamicHeader = computed(() => {
    if (globalData.getScreenWidth < 768) return true
    return false
})

watch(dynamicHeader, (value) => {
    if (!value && scrollHeaderPosition.value !== 0) scrollHeaderPosition.value = 0
})

let lastScrollPosition = 0
const scrollHeaderHeight = 60
const scrollHeaderPosition = ref(0)

onMounted(() => {
    window.addEventListener('scroll', scrollHandler)
    window.addEventListener('touchend', windowOnTouchEnd, { passive: true })
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', scrollHandler)
    window.removeEventListener('touchend', windowOnTouchEnd)
})

function scrollHandler() {
    if (!dynamicHeader.value || (window.scrollY < 0 && scrollHeaderPosition.value === 0)) return
    const addedScroll = window.scrollY - lastScrollPosition
    const addedScrollPosition = scrollHeaderPosition.value + addedScroll
    // console.log(addedScrollPosition, scrollHeaderPosition.value, addedScroll)
    if (window.scrollY <= 0 && scrollHeaderPosition.value !== 0) {
        addTransitionToHeader()
        scrollHeaderPosition.value = 0
    } else if (addedScrollPosition >= 0 && addedScrollPosition <= scrollHeaderHeight) {
        scrollHeaderPosition.value += addedScroll
    } else if (addedScrollPosition < 0 && scrollHeaderPosition.value !== 0) {
        scrollHeaderPosition.value = 0
    } else if (addedScrollPosition > scrollHeaderHeight && scrollHeaderPosition.value !== scrollHeaderHeight) {
        scrollHeaderPosition.value = scrollHeaderHeight
    }

    lastScrollPosition = window.scrollY
}

async function ajustScrollHeaderPosition() {
    if (!dynamicHeader.value || window.scrollY <= 0) return
    if (scrollHeaderPosition.value === 0 || scrollHeaderPosition.value === scrollHeaderHeight) return
    addTransitionToHeader()
    if (scrollHeaderPosition.value > scrollHeaderHeight / 2) scrollHeaderPosition.value = scrollHeaderHeight
    else scrollHeaderPosition.value = 0
}

function addTransitionToHeader() {
    if (headerWrapper.value === null) return
    headerWrapper.value.style.transition = 'transform 0.2s ease-in-out'
    headerWrapper.value.addEventListener('transitionend', () => {
        if (headerWrapper.value === null) return
        headerWrapper.value.style.transition = ''
    }, { once: true })
}

function windowOnTouchEnd() {
    ajustScrollHeaderPosition()
}


function openLoginModal() {
    loginData.toggleLoginModal()
}

function openRegisterModal() {
    loginData.toggleRegisterModal()
}

function toggleMoreMenu(value = null) {
    if (value !== null) moreMenuOpened.value = value
    else moreMenuOpened.value = !moreMenuOpened.value
}

function unlogin() {
    loginData.unlogin()
}

function toggleDarkMode(value: boolean | null = null) {
    if (value !== null) globalData.toggleDarkMode(value)
    else globalData.toggleDarkMode()
}


let moreMenuTopPosition: number | null = null
const { lengthY: MoreMenuLengthY } = useSwipe(moreMenuCloseElm, {
    passive: true,
    threshold: 0,
    onSwipeStart: () => {
        moreMenuTopPosition = moreMenuElm.value?.getBoundingClientRect().top as number
    },
    onSwipe: () => {
        if (!moreMenuElm.value) return
        else if (moreMenuTopPosition === null) return

        if (MoreMenuLengthY.value < 0) moreMenuElm.value.style.top = `${moreMenuTopPosition - MoreMenuLengthY.value}px`
        else moreMenuElm.value.style.top = ''
    },
    onSwipeEnd: (ev, swipeDirection) => {
        moreMenuTopPosition = null
        if (swipeDirection === 'down') closeMoreMenu()
    },
})

const moreMenuTransitionName = computed(() => {
    if (globalData.getScreenWidth > 507) return ''
    else return 'buttomSlide'
})

</script>

<style lang="scss" scoped>
@import 'src/styles/scss/panelHeader.scss';

.buttomSlide-enter-from,
.buttomSlide-leave-to {
    opacity: 0;
    .menu_drop_down_wrapper {
        top: 100% !important;
    }
}

.buttomSlide-enter-active,
.buttomSlide-leave-active {
    transition: opacity 0.1s ease;
    .menu_drop_down_wrapper {
        transition: top 0.1s ease;
    }
}
</style>