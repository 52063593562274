<template>
    <div class="post_wrapper">
        <div class="post_content_wrapper">
            <div class="author_media_wrapper" @dblclick="addKissToPost()">
                <div class="animation_kiss_wrapper" ref="kissingAnimElm" :class="{
                    'kissed': kissedAnimaton
                }">
                    <div class="kisses-icon">
                        <kiss-Animation-Icon-SVG></kiss-Animation-Icon-SVG>
                    </div>
                </div>
                <template v-if="postMediaCount > 1">
                    <div class="white_frame"></div>
                    <div class="media_scroller" :style="{
                        'transform': `translate3d(-${mediaScrollerTranslateX}px, 0px, 0px)`,
                        'transition': isSwipingImages ? 'none' : ''
                    }">
                        
                        <div class="post_media" ref="postMedia" v-for="thisMedia in postData.media" :key="thisMedia.id">
                           
                            <div class="media_bg" :style="{
                                'background-image': `url(${thisMedia.url})`
                            }"></div>
                            <img draggable="false" :src="thisMedia.url" alt="">
                        </div>
                    </div>
                    <div class="slide_buttons_wrapper" ref="slideElm">
                        <template v-if="!isMobileUser">
                            <div class="botton_previus" :attr="$t('post.slide-buttons.button-previous')" v-if="(selectedPostMediaIdx - 1) >= 0"
                            @click.stop="changePostMedia(selectedPostMediaIdx - 1)"></div>
                            <div class="botton_next" :attr="$t('post.slide-buttons.button-next')" v-if="(selectedPostMediaIdx + 1) <= (postMediaCount - 1)"
                            @click.stop="changePostMedia(selectedPostMediaIdx + 1)"></div>
                        </template>
                    </div>
                    <div class="elemnts_quantaty_slider">
                        <span>{{ (selectedPostMediaIdx + 1) }}</span>
                        <span>/</span>
                        <span>{{ postMediaCount }}</span>
                    </div>
                </template>
                <template v-else >
                    <div class="post_media" ref="postMedia" v-for="thisMedia in postData.media" :key="thisMedia.id">
                        <div class="media_bg" :style="{
                            'background-image': `url(${thisMedia.url})`
                        }"></div>
                        <img draggable="false" :src="thisMedia.url" alt="">
                    </div>
                </template>
                <div class="author_header" @dblclick.stop>
                    <div class="author_wrapper">
                        <userAvatarCircle :src="postData.user.avatar" @click="goToUserPage()" />
                        <div class="author_post_actions_wrapper">
                            <div class="name_status_icon_wrapper">
                                <div class="author_name" @click="goToUserPage()">
                                    <span>{{ postData.user.name }}</span>
                                </div>
                                <div  class="status_icon">
                                    <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный"></status-Icon-SVG>
                                </div>
                            </div>
                            <div class="actions_author_wrapper">
                                <small>{{ $t('post.posted') }}</small>
                                <span>2m</span>
                            </div>
                        </div>
                    </div>
                    <div class="more_action_button" @click="togglePostOptions()">
                        <more-Vertical-Icon-SVG></more-Vertical-Icon-SVG>
                    </div>
                </div>
            </div>
            <div class="action_buttons-wrapper noselect">
                <div class="post_kisses_count">
                    <div class="kisses-icon" :class="{
                        'kissed': postData.userInteraction.isKissed
                    }"
                    @click="toggleKissesOptions()">
                        <kiss-icon-SVG></kiss-icon-SVG>
                        <div class="kissed_kiss-icon">
                            <kissedKissIconSVG/>
                        </div>
                    </div>
                    <!-- <span><number-formating :number="postData.kissesCount" /></span> -->
                </div>
                <div class="share-icon">
                    <share-icon-SVG></share-icon-SVG>
                </div>
            </div>
            <div class="kisses_mount_count">
                <span><number-formating :number="postData.kissesCount" /></span>
                <small>{{ $t('post.kissed') }}</small>
            </div>
            <div class="autor_post_description">
                <div class="description">
                    <div class="small_description_wrapper" v-if="!moreDescriptionOpened && descriptionLength > 100">
                        <span>
                            {{ cutedDescription }}
                        </span>
                        <div class="more_info" @click="openMoreDescription()">
                            <span>...</span>
                            <span>{{ $t('header.more') }}</span>
                        </div>
                    </div>
                    <span class="full_description" v-else-if="moreDescriptionOpened || descriptionLength <= 100">
                        <formated-text-handler :formatedText="formatedDescription" v-once />
                    </span>
                </div>
            </div>
            <div class="users_coments_wrapper">
                <div class="block_position_wrapper" @click="selectedUserPost()">
                    <div class="show_all_comments_wrapper">
                        <comments-Icon-SVG></comments-Icon-SVG>
                        <span>{{ $t('post.show_comments') }} ( <number-formating :number="postData.commentCount" /> )</span>
                    </div>
                </div>
            </div>
            <div class="add_comment_section">
                <customTextInput v-model="commentField" :placeholder="`${$t('post.add_comment')}...`"
                @submitData="commentPost()">
                    {{ $t('post.post_comment') }}
                </customTextInput>
            </div>
        </div>
        <Teleport to="body" v-if="postOptionsOpened">
            <postOptionsMenu @close="togglePostOptions(false)" />
        </Teleport>
        <Teleport to="body" v-if="kissesOptionsOpened">
            <kissesOptionsMenu @close="toggleKissesOptions(false)" />
        </Teleport>
    </div>
</template>

<script>
import emojiPopupVue from '@/components/emojiPopup.vue';

export default {
    name: 'userFeedPost',
    components: {
        emojiPopup: emojiPopupVue,
    },
    emits: ['selectedUserPost'],
    mounted() {
        this.formatedDescription = this.formatText(this.postData.description)
    },
    data() {
        return {
            moreDescriptionOpened: false,
            emojiOpened: false,
            formatedDescription: '',
            commentField: '',
        }
    },
    computed: {
        postMediaCount() {
            return this.postData.media.length;
        },
        descriptionLength() {
            return this.postData.description.length;
        },
        cutedDescription() {
            return this.postData.description.slice(0, 100);
        },
        isMobileUser() {
            return device.mobile() || device.tablet();
        }
    },
    methods: {
        openMoreDescription() {
            this.moreDescriptionOpened = true;
        },
        closeMoreDescription() {
            this.moreDescriptionOpened = false;
        },
        // addKissToPost() {
        //     if (this.postData.userInteraction.isKissed) return
        //     this.postData.userInteraction.isKissed = true;
        //     this.postData.kissesCount++;
        // },
        selectedUserPost() {
            this.$emit('selectedUserPost', this.postData);
        },
        goToUserPage() {
            this.$router.push(`/${this.postData.user.name}`)
        },
        commentPost() {
            this.postData.commentCount++;
            this.commentField = '';
        },
        changePostMedia(mediaIdx) {
            this.postData.media.forEach(media => {
                media.selected = false;
            })
            this.postData.media[mediaIdx].selected = true;
        },
    }
}
</script>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useGlobalData } from '@/store/globalData';
import { useSwipe } from '@vueuse/core';

import postOptionsMenu from './postOptionsMenu.vue';
import kissedKissIconSVG from '@/components/svg/kissed-kiss-icon.vue';
import customTextInput from '@/components/customTextInput.vue';
import userAvatarCircle from '@/components/userAvatarCircle.vue';
import kissesOptionsMenu from '@/components/post/kissesOptionsMenu.vue';

const globalData = useGlobalData();

const props = defineProps({
    postData: {
        type: Object,
        required: true
    }
});

const mediaScrollerTranslateX = ref(0);
const slideElm = ref(null);
const postMedia = ref(null);

let mediaScrollerTranslateXValue = 0;

const postMediaOffsetWidth = computed(() => postMedia.value[0].offsetWidth);
const postMediaMaxWidth = computed(() => postMediaOffsetWidth.value * postMedia.value.length);
const selectedPostMediaIdx = computed(() => props.postData.media.findIndex(media => media.selected));

onMounted(() => {
    watch(() => globalData.getScreenWidth, (newVal) => {
        mediaScrollerTranslateX.value = postMedia.value[0].offsetWidth * selectedPostMediaIdx.value;
    })

    watch(selectedPostMediaIdx, (newValue) => {
        mediaScrollerTranslateX.value = postMedia.value[newValue].offsetWidth * newValue
    })
});

const { isSwiping: isSwipingImages, direction: swipingImagesDirection, lengthX } = useSwipe(slideElm, {
    passive: true,
    threshold: 0,
    onSwipeStart: (ev) => {
        mediaScrollerTranslateXValue = mediaScrollerTranslateX.value;
    },
    onSwipe: (ev) => {
        mediaScrollerTranslateX.value = mediaScrollerTranslateXValue + lengthX.value;
        if (mediaScrollerTranslateX.value > (postMediaMaxWidth.value - postMediaOffsetWidth.value)) {
            mediaScrollerTranslateX.value = (postMediaMaxWidth.value - postMediaOffsetWidth.value);
        }
    },
    onSwipeEnd: (ev, swipeDirection) => {
        if (swipeDirection == 'left') {
            let selecedMedia = props.postData.media[props.postData.media.findIndex(media => media.selected) + 1]
            if (!selecedMedia) return
            props.postData.media.forEach(media => {
                media.selected = false;
            })
            selecedMedia.selected = true;
        } else if (swipeDirection == 'right') {
            let selecedMedia = props.postData.media[props.postData.media.findIndex(media => media.selected) - 1]
            if (!selecedMedia) return
            props.postData.media.forEach(media => {
                media.selected = false;
            })
            selecedMedia.selected = true;
        } else {
            mediaScrollerTranslateX.value = mediaScrollerTranslateXValue;
            mediaScrollerTranslateXValue = 0;
        }
    },
})

watch(() => isSwipingImages.value, (newValue) => {
    if (newValue && swipingImagesDirection.value != 'left' && swipingImagesDirection.value != 'right') return
    // console.log(swipingImagesDirection.value)
    if (newValue) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
})

const kissedAnimaton = ref(false);
const kissingAnimElm = ref(null);
watch(() => props.postData.userInteraction.isKissed, (newValue) => {
    kissedAnimaton.value = newValue;
    if (kissingAnimElm.value) {
        kissingAnimElm.value.addEventListener('animationend', (event) => {
            kissedAnimaton.value = false;
        }, { once: true })
    }
})

const kissesOptionsOpened = ref(false);
function toggleKissesOptions(value = null) {
    if (value != null) kissesOptionsOpened.value = value;
    else kissesOptionsOpened.value = !kissesOptionsOpened.value;
}


const postOptionsOpened = ref(false);
function togglePostOptions(value = null) {
    if (value != null) postOptionsOpened.value = value;
    else postOptionsOpened.value = !postOptionsOpened.value;
}

function addKissToPost() {
    if (props.postData.userInteraction.isKissed) return
    props.postData.userInteraction.isKissed = true;
    props.postData.kissesCount++;
}

</script>
    
<style lang="scss" scoped>
@import 'src/styles/scss/components/userFeedPost.scss';
</style>