<template>
    <div class="user_new_stories">
        <div class="avtar_oreon">
            <square-Oreon-SVG></square-Oreon-SVG>
            <div class="user_avatar no_avatar">
                <div class="without_photo">
                    <nophoto-Icon-SVG></nophoto-Icon-SVG>
                </div> 
                <!-- <i class="uis uis-camera-plus"></i> -->
                <!-- <img draggable="false" src="@/assets/app_photo.png" alt="user-avatar"> -->
            </div>
        </div>
        <div class="user_name">
            <span>jomokakukulalkjg</span>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>