<template>
	<div class="emojiCloseBg" v-if="autoClose" @click="closePopup">
		
	</div>
	<div class="emoji_wrapper_popup" ref="emojiWrapperPopup">
		<div class="emoji_scroller custom-scrollbar">
			<div class="emoji">
				<template v-for="(thisEmojiCat, thisEmojiCatName) in emoji" :key="thisEmojiCatName">
					<div class="group_name">
						<div class="name">{{ $t(`emoji.${thisEmojiCatName}`) }}</div>
					</div>
					<div v-for="thisEmoji in thisEmojiCat" :key="thisEmoji">
						<button class="emoji_button" type="button" @click="emojiClick(thisEmoji)">
							<div class="emoji_icon">{{ thisEmoji }}</div>
						</button>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'emojiPopup',
	props: {
		autoClose: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['emojiClick', 'closePopup'],
	data() {
		return {
			emoji: {
				popular: [
					"😂",
					"😮",
					"😍",
					"😢",
					"👏",
					"🔥",
					"🎉",
					"💯",
					"❤️",
					"🤣",
					"🥰",
					"😘",
					"😭",
					"😊"
				],
				smileys_and_people: [
					"😀",
					"😃",
					"😄",
					"😁",
					"😆",
					"😅",
					"🤣",
					"😂",
					"🙂",
					"🙃",
					"😉",
					"😊",
					"😇",
					"🥰",
					"😍",
					"🤩",
					"😘",
					"😗",
					"😚",
					"😙",
					"😋",
					"😛",
					"😜",
					"🤪",
					"😝",
					"🤑",
					"🤗",
					"🤭",
					"🤫",
					"🤔",
					"🤐",
					"🤨",
					"😐",
					"😑",
					"😶",
					"😏",
					"😒",
					"🙄",
					"😬",
					"🤥",
					"😌",
					"😔",
					"😪",
					"🤤",
					"😴",
					"😷",
					"🤒",
					"🤕",
					"🤢",
					"🤮",
					"🤧",
					"🥵",
					"🥶",
					"🥴",
					"😵",
					"🤯",
					"🤠",
					"🥳",
					"😎",
					"🤓",
					"🧐",
					"😕",
					"😟",
					"🙁",
					"😮",
					"😯",
					"😲",
					"😳",
					"🥺",
					"😦",
					"😧",
					"😨",
					"😰",
					"😥",
					"😢",
					"😭",
					"😱",
					"😖",
					"😣",
					"😞",
					"😓",
					"😩",
					"😫",
					"😤",
					"😡",
					"😠",
					"🤬",
					"😈",
					"👿",
					"💀",
					"💩",
					"🤡",
					"👹",
					"👺",
					"👻",
					"👽",
					"👾",
					"🤖",
					"😺",
					"😸",
					"😹",
					"😻",
					"😼",
					"😽",
					"🙀",
					"😿",
					"😾",
					"💋",
					"👋",
					"🤚",
					"🖐",
					"✋",
					"🖖",
					"👌",
					"✌🏻",
					"🤞",
					"🤟",
					"🤘",
					"🤙",
					"👈",
					"👉",
					"👆",
					"🖕",
					"👇",
					"☝🏻",
					"👍",
					"👎",
					"✊",
					"👊",
					"🤛",
					"🤜",
					"👏",
					"🙌",
					"👐",
					"🤲",
					"🤝",
					"🙏",
					"✍🏻",
					"💅",
					"🤳",
					"💪",
					"🦵",
					"🦶",
					"👂",
					"👃",
					"🧠",
					"🦷",
					"🦴",
					"👀",
					"👁",
					"👅",
					"👄",
					"👶",
					"🧒",
					"👦",
					"👧",
					"🧑",
					"👱",
					"👨",
					"🧔",
					"👨‍🦰",
					"👨‍🦱",
					"👨‍🦳",
					"👨‍🦲",
					"👩",
					"👩‍🦰",
					"👩‍🦱",
					"👩‍🦳",
					"👩‍🦲",
					"👱‍♀️",
					"👱‍♂️",
					"🧓",
					"👴",
					"👵",
					"🙍",
					"🙍‍♂️",
					"🙍‍♀️",
					"🙎",
					"🙎‍♂️",
					"🙎‍♀️",
					"🙅",
					"🙅‍♂️",
					"🙅‍♀️",
					"🙆",
					"🙆‍♂️",
					"🙆‍♀️",
					"💁",
					"💁‍♂️",
					"💁‍♀️",
					"🙋",
					"🙋‍♂️",
					"🙋‍♀️",
					"🙇",
					"🙇‍♂️",
					"🙇‍♀️",
					"🤦",
					"🤦‍♂️",
					"🤦‍♀️",
					"🤷",
					"🤷‍♂️",
					"🤷‍♀️",
					"👨‍⚕️",
					"👩‍⚕️",
					"👨‍🎓",
					"👩‍🎓",
					"👨‍🏫",
					"👩‍🏫",
					"👨‍⚖️",
					"👩‍⚖️",
					"👨‍🌾",
					"👩‍🌾",
					"👨‍🍳",
					"👩‍🍳",
					"👨‍🔧",
					"👩‍🔧",
					"👨‍🏭",
					"👩‍🏭",
					"👨‍💼",
					"👩‍💼",
					"👨‍🔬",
					"👩‍🔬",
					"👨‍💻",
					"👩‍💻",
					"👨‍🎤",
					"👩‍🎤",
					"👨‍🎨",
					"👩‍🎨",
					"👨‍✈️",
					"👩‍✈️",
					"👨‍🚀",
					"👩‍🚀",
					"👨‍🚒",
					"👩‍🚒",
					"👮",
					"👮‍♂️",
					"👮‍♀️",
					"🕵",
					"🕵️‍♂️",
					"🕵️‍♀️",
					"💂",
					"💂‍♂️",
					"💂‍♀️",
					"👷",
					"👷‍♂️",
					"👷‍♀️",
					"🤴",
					"👸",
					"👳",
					"👳‍♂️",
					"👳‍♀️",
					"👲",
					"🧕",
					"🤵",
					"👰",
					"🤰",
					"🤱",
					"👼",
					"🎅",
					"🤶",
					"🦸",
					"🦸‍♂️",
					"🦸‍♀️",
					"🦹",
					"🦹‍♂️",
					"🦹‍♀️",
					"🧙",
					"🧙‍♂️",
					"🧙‍♀️",
					"🧚",
					"🧚‍♂️",
					"🧚‍♀️",
					"🧛",
					"🧛‍♂️",
					"🧛‍♀️",
					"🧜",
					"🧜‍♂️",
					"🧜‍♀️",
					"🧝",
					"🧝‍♂️",
					"🧝‍♀️",
					"🧞",
					"🧞‍♂️",
					"🧞‍♀️",
					"🧟",
					"🧟‍♂️",
					"🧟‍♀️",
					"💆",
					"💆‍♂️",
					"💆‍♀️",
					"💇",
					"💇‍♂️",
					"💇‍♀️",
					"🚶",
					"🚶‍♂️",
					"🚶‍♀️",
					"🏃",
					"🏃‍♂️",
					"🏃‍♀️",
					"💃",
					"🕺",
					"🕴",
					"👯",
					"👯‍♂️",
					"👯‍♀️",
					"🧖",
					"🧖‍♂️",
					"🧖‍♀️",
					"🧘",
					"👭",
					"👫",
					"👬",
					"💏",
					"👨‍❤️‍💋‍👨",
					"👩‍❤️‍💋‍👩",
					"💑",
					"👨‍❤️‍👨",
					"👩‍❤️‍👩",
					"👪",
					"👨‍👩‍👦",
					"👨‍👩‍👧",
					"👨‍👩‍👧‍👦",
					"👨‍👩‍👦‍👦",
					"👨‍👩‍👧‍👧",
					"👨‍👨‍👦",
					"👨‍👨‍👧",
					"👨‍👨‍👧‍👦",
					"👨‍👨‍👦‍👦",
					"👨‍👨‍👧‍👧",
					"👩‍👩‍👦",
					"👩‍👩‍👧",
					"👩‍👩‍👧‍👦",
					"👩‍👩‍👦‍👦",
					"👩‍👩‍👧‍👧",
					"👨‍👦",
					"👨‍👦‍👦",
					"👨‍👧",
					"👨‍👧‍👦",
					"👨‍👧‍👧",
					"👩‍👦",
					"👩‍👦‍👦",
					"👩‍👧",
					"👩‍👧‍👦",
					"👩‍👧‍👧",
					"🗣",
					"👤",
					"👥",
					"👣",
					"🧳",
					"🌂",
					"☂",
					"🧵",
					"🧶",
					"👓",
					"🕶",
					"🥽",
					"🥼",
					"👔",
					"👕",
					"👖",
					"🧣",
					"🧤",
					"🧥",
					"🧦",
					"👗",
					"👘",
					"👙",
					"👚",
					"👛",
					"👜",
					"👝",
					"🎒",
					"👞",
					"👟",
					"🥾",
					"🥿",
					"👠",
					"👡",
					"👢",
					"👑",
					"👒",
					"🎩",
					"🎓",
					"🧢",
					"⛑",
					"💄",
					"💍",
					"💼"
				],
				animals_and_nature: [
					"🙈",
					"🙉",
					"🙊",
					"💥",
					"💫",
					"💦",
					"💨",
					"🐵",
					"🐒",
					"🦍",
					"🐶",
					"🐕",
					"🐩",
					"🐺",
					"🦊",
					"🦝",
					"🐱",
					"🐈",
					"🦁",
					"🐯",
					"🐅",
					"🐆",
					"🐴",
					"🐎",
					"🦄",
					"🦓",
					"🦌",
					"🐮",
					"🐂",
					"🐃",
					"🐄",
					"🐷",
					"🐖",
					"🐗",
					"🐽",
					"🐏",
					"🐑",
					"🐐",
					"🐪",
					"🐫",
					"🦙",
					"🦒",
					"🐘",
					"🦏",
					"🦛",
					"🐭",
					"🐁",
					"🐀",
					"🐹",
					"🐰",
					"🐇",
					"🐿",
					"🦔",
					"🦇",
					"🐻",
					"🐨",
					"🐼",
					"🦘",
					"🦡",
					"🐾",
					"🦃",
					"🐔",
					"🐓",
					"🐣",
					"🐤",
					"🐥",
					"🐦",
					"🐧",
					"🕊",
					"🦅",
					"🦆",
					"🦢",
					"🦉",
					"🦚",
					"🦜",
					"🐸",
					"🐊",
					"🐢",
					"🦎",
					"🐍",
					"🐲",
					"🐉",
					"🦕",
					"🦖",
					"🐳",
					"🐋",
					"🐬",
					"🐟",
					"🐠",
					"🐡",
					"🦈",
					"🐙",
					"🐚",
					"🐌",
					"🦋",
					"🐛",
					"🐜",
					"🐝",
					"🐞",
					"🦗",
					"🕷",
					"🕸",
					"🦂",
					"🦟",
					"🦠",
					"💐",
					"🌸",
					"💮",
					"🏵",
					"🌹",
					"🥀",
					"🌺",
					"🌻",
					"🌼",
					"🌷",
					"🌱",
					"🌲",
					"🌳",
					"🌴",
					"🌵",
					"🌾",
					"🌿",
					"☘",
					"🍀",
					"🍁",
					"🍂",
					"🍃",
					"🍄",
					"🌰",
					"🦀",
					"🦞",
					"🦐",
					"🦑",
					"🌍",
					"🌎",
					"🌏",
					"🌐",
					"🌑",
					"🌒",
					"🌓",
					"🌔",
					"🌕",
					"🌖",
					"🌗",
					"🌘",
					"🌙",
					"🌚",
					"🌛",
					"🌜",
					"☀️",
					"🌝",
					"🌞",
					"⭐",
					"🌟",
					"🌠",
					"☁",
					"⛅",
					"⛈",
					"🌤",
					"🌥",
					"🌦",
					"🌧",
					"🌨",
					"🌩",
					"🌪",
					"🌫",
					"🌬",
					"🌈",
					"☂",
					"☔",
					"⚡",
					"❄",
					"☃",
					"⛄",
					"☄",
					"🔥",
					"💧",
					"🌊",
					"🎄",
					"✨",
					"🎋",
					"🎍"
				],
				food_and_drink: [
					"🍇",
					"🍈",
					"🍉",
					"🍊",
					"🍋",
					"🍌",
					"🍍",
					"🥭",
					"🍎",
					"🍏",
					"🍐",
					"🍑",
					"🍒",
					"🍓",
					"🥝",
					"🍅",
					"🥥",
					"🥑",
					"🍆",
					"🥔",
					"🥕",
					"🌽",
					"🌶",
					"🥒",
					"🥬",
					"🥦",
					"🍄",
					"🥜",
					"🌰",
					"🍞",
					"🥐",
					"🥖",
					"🥨",
					"🥯",
					"🥞",
					"🧀",
					"🍖",
					"🍗",
					"🥩",
					"🥓",
					"🍔",
					"🍟",
					"🍕",
					"🌭",
					"🥪",
					"🌮",
					"🌯",
					"🥙",
					"🍳",
					"🥘",
					"🍲",
					"🥣",
					"🥗",
					"🍿",
					"🧂",
					"🥫",
					"🍱",
					"🍘",
					"🍙",
					"🍚",
					"🍛",
					"🍜",
					"🍝",
					"🍠",
					"🍢",
					"🍣",
					"🍤",
					"🍥",
					"🥮",
					"🍡",
					"🥟",
					"🥠",
					"🥡",
					"🍦",
					"🍧",
					"🍨",
					"🍩",
					"🍪",
					"🎂",
					"🍰",
					"🧁",
					"🥧",
					"🍫",
					"🍬",
					"🍭",
					"🍮",
					"🍯",
					"🍼",
					"🥛",
					"☕",
					"🍵",
					"🍶",
					"🍾",
					"🍷",
					"🍸",
					"🍹",
					"🍺",
					"🍻",
					"🥂",
					"🥃",
					"🥤",
					"🥢",
					"🍽",
					"🍴",
					"🥄"
				],
				activities: [
					"🕴",
					"🧗",
					"🧗‍♂️",
					"🧗‍♀️",
					"🏇",
					"⛷",
					"🏂",
					"🏌",
					"🏌️‍♂️",
					"🏌️‍♀️",
					"🏄",
					"🏄‍♂️",
					"🏄‍♀️",
					"🚣",
					"🚣‍♂️",
					"🚣‍♀️",
					"🏊",
					"🏊‍♂️",
					"🏊‍♀️",
					"⛹",
					"⛹️‍♂️",
					"⛹️‍♀️",
					"🏋",
					"🏋️‍♂️",
					"🏋️‍♀️",
					"🚴",
					"🚴‍♂️",
					"🚴‍♀️",
					"🚵",
					"🚵‍♂️",
					"🚵‍♀️",
					"🤸",
					"🤸‍♂️",
					"🤸‍♀️",
					"🤼",
					"🤼‍♂️",
					"🤼‍♀️",
					"🤽",
					"🤽‍♂️",
					"🤽‍♀️",
					"🤾",
					"🤾‍♂️",
					"🤾‍♀️",
					"🤹",
					"🤹‍♂️",
					"🤹‍♀️",
					"🧘",
					"🧘‍♂️",
					"🧘‍♀️",
					"🎪",
					"🛹",
					"🎗",
					"🎟",
					"🎫",
					"🎖",
					"🏆",
					"🏅",
					"🥇",
					"🥈",
					"🥉",
					"⚽",
					"⚾",
					"🥎",
					"🏀",
					"🏐",
					"🏈",
					"🏉",
					"🎾",
					"🥏",
					"🎳",
					"🏏",
					"🏑",
					"🏒",
					"🥍",
					"🏓",
					"🏸",
					"🥊",
					"🥋",
					"⛳",
					"⛸",
					"🎣",
					"🎽",
					"🎿",
					"🛷",
					"🥌",
					"🎯",
					"🎱",
					"🎮",
					"🎰",
					"🎲",
					"🧩",
					"♟",
					"🎭",
					"🎨",
					"🧵",
					"🧶",
					"🎼",
					"🎤",
					"🎧",
					"🎷",
					"🎸",
					"🎹",
					"🎺",
					"🎻",
					"🥁",
					"🎬",
					"🏹"
				],
				travel_and_places: [
					"🚣",
					"🗾",
					"🏔",
					"⛰",
					"🌋",
					"🗻",
					"🏕",
					"🏖",
					"🏜",
					"🏝",
					"🏞",
					"🏟",
					"🏛",
					"🏗",
					"🏘",
					"🏚",
					"🏠",
					"🏡",
					"🏢",
					"🏣",
					"🏤",
					"🏥",
					"🏦",
					"🏨",
					"🏩",
					"🏪",
					"🏫",
					"🏬",
					"🏭",
					"🏯",
					"🏰",
					"💒",
					"🗼",
					"🗽",
					"⛪",
					"🕌",
					"🕍",
					"⛩",
					"🕋",
					"⛲",
					"⛺",
					"🌁",
					"🌃",
					"🏙",
					"🌄",
					"🌅",
					"🌆",
					"🌇",
					"🌉",
					"🎠",
					"🎡",
					"🎢",
					"🚂",
					"🚃",
					"🚄",
					"🚅",
					"🚆",
					"🚇",
					"🚈",
					"🚉",
					"🚊",
					"🚝",
					"🚞",
					"🚋",
					"🚌",
					"🚍",
					"🚎",
					"🚐",
					"🚑",
					"🚒",
					"🚓",
					"🚔",
					"🚕",
					"🚖",
					"🚗",
					"🚘",
					"🚚",
					"🚛",
					"🚜",
					"🏎",
					"🏍",
					"🛵",
					"🚲",
					"🛴",
					"🚏",
					"🛤",
					"⛽",
					"🚨",
					"🚥",
					"🚦",
					"🚧",
					"⚓",
					"⛵",
					"🚤",
					"🛳",
					"⛴",
					"🛥",
					"🚢",
					"✈",
					"🛩",
					"🛫",
					"🛬",
					"💺",
					"🚁",
					"🚟",
					"🚠",
					"🚡",
					"🛰",
					"🚀",
					"🛸",
					"🌠",
					"🌌",
					"⛱",
					"🎆",
					"🎇",
					"🎑",
					"💴",
					"💵",
					"💶",
					"💷",
					"🗿",
					"🛂",
					"🛃",
					"🛄",
					"🛅",
					"💌",
					"🕳",
					"💣",
					"🛀",
					"🛌",
					"🔪",
					"🏺",
					"🗺",
					"🧭",
					"🧱",
					"💈",
					"🛢",
					"🛎",
					"🧳",
					"⌛",
					"⏳",
					"⌚",
					"⏰",
					"⏱",
					"⏲",
					"🕰",
					"🌡",
					"⛱",
					"🧨",
					"🎈",
					"🎉",
					"🎊",
					"🎎",
					"🎏",
					"🎐",
					"🧧",
					"🎀",
					"🎁",
					"🔮",
					"🧿",
					"🕹",
					"🧸",
					"🖼",
					"🧵",
					"🧶",
					"🛍",
					"📿",
					"💎",
					"📯",
					"🎙",
					"🎚",
					"🎛",
					"📻",
					"📱",
					"📲",
					"☎",
					"📞",
					"📟",
					"📠",
					"🔋",
					"🔌",
					"💻",
					"🖥",
					"🖨",
					"⌨",
					"🖱",
					"🖲",
					"💽",
					"💾",
					"💿",
					"📀",
					"🧮",
					"🎥",
					"🎞",
					"📽",
					"📺",
					"📷",
					"📸",
					"📹",
					"📼",
					"🔍",
					"🔎",
					"🕯",
					"💡",
					"🔦",
					"🏮",
					"📔",
					"📕",
					"📖",
					"📗",
					"📘",
					"📙",
					"📚",
					"📓",
					"📃",
					"📜",
					"📄",
					"📰",
					"🗞",
					"📑",
					"🔖",
					"🏷",
					"💰",
					"💴",
					"💵",
					"💶",
					"💷",
					"💸",
					"💳",
					"🧾",
					"✉",
					"📧",
					"📨",
					"📩",
					"📤",
					"📥",
					"📦",
					"📫",
					"📪",
					"📬",
					"📭",
					"📮",
					"🗳",
					"✏",
					"✒",
					"🖋",
					"🖊",
					"🖌",
					"🖍",
					"📝",
					"📁",
					"📂",
					"🗂",
					"📅",
					"📆",
					"🗒",
					"🗓",
					"📇",
					"📈",
					"📉",
					"📊",
					"📋",
					"📌",
					"📍",
					"📎",
					"🖇",
					"📏",
					"📐",
					"✂",
					"🗃",
					"🗄",
					"🗑",
					"🔒",
					"🔓",
					"🔏",
					"🔐",
					"🔑",
					"🗝",
					"🔨",
					"⛏",
					"⚒",
					"🛠",
					"🗡",
					"⚔",
					"🔫",
					"🛡",
					"🔧",
					"🔩",
					"⚙",
					"🗜",
					"⚖",
					"🔗",
					"⛓",
					"🧰",
					"🧲",
					"⚗",
					"🧪",
					"🧫",
					"🧬",
					"🔬",
					"🔭",
					"📡",
					"💉",
					"💊",
					"🚪",
					"🛏",
					"🛋",
					"🚽",
					"🚿",
					"🛁",
					"🧴",
					"🧷",
					"🧹",
					"🧺",
					"🧻",
					"🧼",
					"🧽",
					"🧯",
					"🚬",
					"⚰",
					"⚱",
					"🗿",
					"🚰"
				],
				symbols: [
					"💘",
					"💝",
					"💖",
					"💗",
					"💓",
					"💞",
					"💕",
					"💟",
					"❣",
					"💔",
					"❤",
					"🧡",
					"💛",
					"💚",
					"💙",
					"💜",
					"🖤",
					"💯",
					"💢",
					"💬",
					"👁️‍🗨️",
					"🗯",
					"💭",
					"💤",
					"💮",
					"♨",
					"💈",
					"🛑",
					"🕛",
					"🕧",
					"🕐",
					"🕜",
					"🕑",
					"🕝",
					"🕒",
					"🕞",
					"🕓",
					"🕟",
					"🕔",
					"🕠",
					"🕕",
					"🕡",
					"🕖",
					"🕢",
					"🕗",
					"🕣",
					"🕘",
					"🕤",
					"🕙",
					"🕥",
					"🕚",
					"🕦",
					"🌀",
					"♠",
					"♥",
					"♦",
					"♣",
					"🃏",
					"🀄",
					"🎴",
					"🔇",
					"🔈",
					"🔉",
					"🔊",
					"📢",
					"📣",
					"📯",
					"🔔",
					"🔕",
					"🎵",
					"🎶",
					"🏧",
					"🚮",
					"🚰",
					"♿",
					"🚹",
					"🚺",
					"🚻",
					"🚼",
					"🚾",
					"⚠",
					"🚸",
					"⛔",
					"🚫",
					"🚳",
					"🚭",
					"🚯",
					"🚱",
					"🚷",
					"🔞",
					"☢",
					"☣",
					"⬆",
					"↗",
					"➡",
					"↘",
					"⬇",
					"↙",
					"⬅",
					"↖",
					"↕",
					"↔",
					"↩",
					"↪",
					"⤴",
					"⤵",
					"🔃",
					"🔄",
					"🔙",
					"🔚",
					"🔛",
					"🔜",
					"🔝",
					"🛐",
					"⚛",
					"🕉",
					"✡",
					"☸",
					"☯",
					"✝",
					"☦",
					"☪",
					"☮",
					"🕎",
					"🔯",
					"♈",
					"♉",
					"♊",
					"♋",
					"♌",
					"♍",
					"♎",
					"♏",
					"♐",
					"♑",
					"♒",
					"♓",
					"⛎",
					"🔀",
					"🔁",
					"🔂",
					"▶",
					"⏩",
					"◀",
					"⏪",
					"🔼",
					"⏫",
					"🔽",
					"⏬",
					"⏹",
					"⏏",
					"🎦",
					"🔅",
					"🔆",
					"📶",
					"📳",
					"📴",
					"✖",
					"➕",
					"➖",
					"➗",
					"♾",
					"‼",
					"⁉",
					"❓",
					"❔",
					"❕",
					"❗",
					"♻",
					"🔱",
					"📛",
					"🔰",
					"⭕",
					"✅",
					"☑",
					"✔",
					"❌",
					"❎",
					"➰",
					"➿",
					"〽",
					"✳",
					"✴",
					"❇",
					"©",
					"®",
					"™",
					"#️⃣",
					"0️⃣",
					"1️⃣",
					"2️⃣",
					"3️⃣",
					"4️⃣",
					"5️⃣",
					"6️⃣",
					"7️⃣",
					"8️⃣",
					"9️⃣",
					"🔟",
					"🔠",
					"🔡",
					"🔢",
					"🔣",
					"🔤",
					"🅰",
					"🆎",
					"🅱",
					"🆑",
					"🆒",
					"🆓",
					"ℹ",
					"🆔",
					"Ⓜ",
					"🆕",
					"🆖",
					"🅾",
					"🆗",
					"🅿",
					"🆘",
					"🆙",
					"🆚",
					"🈁",
					"🈂",
					"🈷",
					"🈶",
					"🈯",
					"🉐",
					"🈹",
					"🈚",
					"🈲",
					"🉑",
					"🈸",
					"🈴",
					"🈳",
					"㊗",
					"㊙",
					"🈺",
					"🈵",
					"🔴",
					"🔵",
					"⚫",
					"⚪",
					"⬛",
					"⬜",
					"◼",
					"◻",
					"◾",
					"◽",
					"▪",
					"▫",
					"🔶",
					"🔷",
					"🔸",
					"🔹",
					"🔺",
					"🔻",
					"💠",
					"🔳",
					"🔲"
				]
			}
		}
	},
	methods: {
		emojiClick: function (emoji) {
			this.$emit('emojiClick', emoji);
		},
		closePopup() {
			this.$emit('closePopup');
		}
	}
}
</script>

<style lang="scss" scoped>
	@import "src/styles/scss/components/emojiPopup.scss";
</style>
