<template>
    <svg class="lock-icon-locked"  width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-23">
                <rect id="Rectangle" x="0" y="0" width="42" height="42"></rect>
                <path d="M16,3 C21.4292399,3 25.8479317,7.32667079 25.9961582,12.7200952 L26,13 L26,17 C29.3137085,17 32,19.6862915 32,23 L32,36 C32,39.3137085 29.3137085,42 26,42 L6,42 C2.6862915,42 4.05812251e-16,39.3137085 0,36 L0,23 C-4.05812251e-16,19.6862915 2.6862915,17 6,17 L6,13 L6.0038418,12.7200952 C6.15206828,7.32667079 10.5707601,3 16,3 Z M12,17 C12,17.5522847 12.4477153,18 13,18 L13,18 L19,18 C19.5522847,18 20,17.5522847 20,17 L20,17 L22,17 L22,13 L21.9958615,12.7750617 C21.8775718,9.56557489 19.2383969,7 16,7 C12.8369146,7 10.2455046,9.44763337 10.0164572,12.552213 L10.0041385,12.7750617 L10,13 L10,17 Z" id="locked-lock" fill="#838387"></path>
            </g>
        </g>
    </svg>
    <svg class="lock-icon-unloked" width="42px" height="42px" viewBox="0 0 42 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Group 23</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-23">
                <rect id="Rectangle" x="0" y="0" width="42" height="42"></rect>
                <path d="M32,3 C37.4292399,3 41.8479317,7.32667079 41.9961582,12.7200952 L42,13 L42,18 L38,18 L38,13 C38,9.76160306 35.4344251,7.12242824 32.2249383,7.00413847 L32,7 C28.8369146,7 26.2455046,9.44763337 26.0164572,12.552213 L26.0041385,12.7750617 L26,13 L26,17 C29.3137085,17 32,19.6862915 32,23 L32,36 C32,39.3137085 29.3137085,42 26,42 L6,42 C2.6862915,42 4.05812251e-16,39.3137085 0,36 L0,23 C-4.05812251e-16,19.6862915 2.6862915,17 6,17 L12,17 C12,17.5128358 12.3860402,17.9355072 12.8833789,17.9932723 L13,18 L19,18 C19.5128358,18 19.9355072,17.6139598 19.9932723,17.1166211 L20,17 L22,17 L22,13 L22.0038418,12.7200952 C22.1520683,7.32667079 26.5707601,3 32,3 Z M16,24 C14.3431458,24 13,25.3431458 13,27 C13,28.0997078 13.5917104,29.0612143 14.4741225,29.5835108 L14.4741225,29.5835108 L14.4747103,34.4612765 C14.4747103,35.31109 15.1636202,36 16.0134338,36 C16.8632473,36 17.5521573,35.31109 17.5521573,34.4612765 L17.5521573,34.4612765 L17.5528702,29.5673421 C18.4203355,29.0415252 19,28.0884566 19,27 C19,25.3431458 17.6568542,24 16,24 Z" id="unlocked-lock" fill="#838387"></path>
            </g>
        </g>
    </svg>
    <!-- <svg class="lock-icon-locked" width="32px" height="39px" viewBox="0 0 32 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="locked" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M16,0 C21.4292399,0 25.8479317,4.32667079 25.9961582,9.72009516 L26,10 L26,14 C29.3137085,14 32,16.6862915 32,20 L32,33 C32,36.3137085 29.3137085,39 26,39 L6,39 C2.6862915,39 4.05812251e-16,36.3137085 0,33 L0,20 C-4.05812251e-16,16.6862915 2.6862915,14 6,14 L6,10 L6.0038418,9.72009516 C6.15206828,4.32667079 10.5707601,0 16,0 Z M12,14 C12,14.5522847 12.4477153,15 13,15 L13,15 L19,15 C19.5522847,15 20,14.5522847 20,14 L20,14 L22,14 L22,10 L21.9958615,9.77506174 C21.8775718,6.56557489 19.2383969,4 16,4 C12.8369146,4 10.2455046,6.44763337 10.0164572,9.55221297 L10.0041385,9.77506174 L10,10 L10,14 Z" id="Combined-Shape-Copy-4" fill="#838387"></path>
        </g>
    </svg> -->

    <!-- <svg class="lock-icon-unloked" width="42px" height="39px" viewBox="0 0 42 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M32,0 C37.4292399,0 41.8479317,4.32667079 41.9961582,9.72009516 L42,10 L42,15 L38,15 L38,10 L37.9958615,9.77506174 C37.8775718,6.56557489 35.2383969,4 32,4 C28.8369146,4 26.2455046,6.44763337 26.0164572,9.55221297 L26.0041385,9.77506174 L26,10 L26,14 C29.3137085,14 32,16.6862915 32,20 L32,33 C32,36.3137085 29.3137085,39 26,39 L6,39 C2.6862915,39 4.05812251e-16,36.3137085 0,33 L0,20 C-4.05812251e-16,16.6862915 2.6862915,14 6,14 L12,14 C12,14.5128358 12.3860402,14.9355072 12.8833789,14.9932723 L13,15 L19,15 C19.5128358,15 19.9355072,14.6139598 19.9932723,14.1166211 L20,14 L22,14 L22,10 L22.0038418,9.72009516 C22.1520683,4.32667079 26.5707601,0 32,0 Z M16,21 C14.3431458,21 13,22.3431458 13,24 C13,25.0997078 13.5917104,26.0612143 14.4741225,26.5835108 L14.4741225,26.5835108 L14.4747103,31.4612765 C14.4747103,32.31109 15.1636202,33 16.0134338,33 C16.8632473,33 17.5521573,32.31109 17.5521573,31.4612765 L17.5521573,31.4612765 L17.5528702,26.5673421 C18.4203355,26.0415252 19,25.0884566 19,24 C19,22.3431458 17.6568542,21 16,21 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg> -->
</template>

<script>
export default {
    name: 'lockerIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 25px;
        width: 25px;
    }
    .lock-icon-unloked {
        display: none;
    }

    .subscribe_btn {
        svg {
            height: 15px;
            width: 15px;

            @media(max-width: 430px) {
                height: 12px;
                width: 12px;
            }

            path {
                fill: var(--white)
            }
        }
        &.pressed {
            svg {
                path {
                    fill: var(--lpurple)
                }
            }
        }

        &:active {
            svg {
                path {
                    fill: var(--lpurple)
                }
            }
        }
    }

    .tab_icons_wrapper {
        svg {
            height: 14px;
            width: 14px;

            @media(max-width:  507px) {
                height: 16px;
                width: 16px;
            }
        }
    }
    .tab_icons_wrapper {
        svg {
           margin-right: -.25rem;
           @media(max-width:  507px) {
                margin: 0;
            }
        }
    }

   
</style>