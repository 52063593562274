<template>
    <div class="user_comment">
        <div class="user">
            <userAvatarCircle :src="comment.user.avatar" @click="goToUserPage(comment.user.name)" />
            <div class="author_name" >
                <div class="author_name_comments_wrapper">
                    <span @click="goToUserPage(comment.user.name)">{{ comment.user.name }}</span>
                    <div class="status_icon" v-if="comment.user.verified">
                        <status-Icon-SVG draggable="false" src="@/assets/svg/status-pp.svg" :alt="$t('user_profile.verified')" :title="$t('user_profile.verified')"></status-Icon-SVG>
                        <!-- <img draggable="false" src="@/assets/svg/status-pp.svg" :alt="$t('user_profile.verified')" :title="$t('user_profile.verified')"> -->
                    </div>
                </div>
                <div class="user_comment_wrapper">
                    <div class="user_comment_action_buttons">
                        <div class="comment">
                            <span>{{ comment.text }}</span>
                        </div>
                        <div class="commetn_action_buttons noselect">
                            <span>11:45</span>
                            <span>{{ $t('post.likes_count', comment.likes) }}</span>
                            <span>{{ $t('post.reply') }}</span>
                            <span class="mre_button" title="More actions"><i class="uis uis-ellipsis-h"></i></span>
                            <div class="comment_like" @click="toggleLike()">
                                <unlike-Icon-SVG v-if="comment.liked"></unlike-Icon-SVG>
                                <like-Icon-SVG v-else></like-Icon-SVG>
                            </div>
                        </div>
                    </div>
                    <div class="comments_replise" v-if="!reply && comment.replies_count > 0">
                        <div class="replise_show_hide noselect" @click="toggleReplies()">
                            <div class="sep_line"></div>
                            <template v-if="repliesOpened">
                                <span class="rep">{{ $t('post.hide_replies') }}</span>
                            </template>
                            <template v-else>
                                <span class="rep">{{ $t('post.view_replies') }}</span>
                                <span class="rep">({{ comment.replies_count }})</span>
                            </template>
                        </div>

                        <div class="replies_users_comments" @click.stop v-if="repliesOpened">
                            <user-post-view-comment v-for="thisReply in comment.replies" @closePost="closePost()" :key="thisReply.id" :comment="thisReply" :reply="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userPostViewCommentVue from '@/components/post/userPostViewComment.vue';

export default {
    name: "userPostViewComment",
    components: {
        userPostViewComment: userPostViewCommentVue
    },
    props: {
        comment: {
            type: Object,
            required: true
        },
        reply: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        closePost() {
            this.$emit('closePost')
        },
        goToUserPage(userNick) {
            this.$router.push(`/${userNick}`)
            this.closePost()
        },
        toggleLike() {
            this.comment.liked = !this.comment.liked;
            if (this.comment.liked) {
                this.comment.likes++;
            } else {
                this.comment.likes--;
            }
        }
    }
};
</script>

<script setup>
import { ref } from 'vue';
import userAvatarCircle from '@/components/userAvatarCircle.vue';

defineEmits(['closePost']);

const repliesOpened = ref(false);
function toggleReplies() {
    repliesOpened.value = !repliesOpened.value;
}

</script>

<style lang="scss" scoped>

</style>