import { defineStore as definePiniaStore } from 'pinia'
import { watch } from "vue"
import $ from 'jquery'
import vueAPI from "@/modules/vueAPI"

export const useGlobalData = definePiniaStore('globalData', {
    state: () => ({
        hasObserver: false,
        webpSupport: {
            support: false,
            loseless: false,
        },
        screen: {
            width: 0,
            height: 0,
        },
        standaloneApp: false,
        darkMode: false,
        serviceWorkerReady: false,
        notifications: {
            pushEnabled: false
        }
    }),
    getters: {
        getHasObserver: (state) => state.hasObserver,
        getWebpSupport: (state) => state.webpSupport.support,
        getWebpLoseless: (state) => state.webpSupport.loseless,
        getScreenWidth: (state) => state.screen.width,
        getScreenHeight: (state) => state.screen.height,
        getStandaloneApp: (state) => state.standaloneApp,
        getDarkMode: (state) => state.darkMode,
        getServiceWorkerReady: (state) => state.serviceWorkerReady,
        getPushEnabled: (state) => state.notifications.pushEnabled,
    },
    actions: {
        toggleDarkMode(value: boolean | null = null) {
            if (value !== null) this.darkMode = value
            else this.darkMode = !this.darkMode
        }
    }
})

type WebPFeature = 'basic' | 'lossless' | null;
    
const hasWebP = (function() {
    let images = {
        basic: "data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoCAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==",
        lossless: "data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAQAAAAfQ//73v/+BiOh/AAA="
    };
    
    return function(feature: WebPFeature = null) {
        let deferred = $.Deferred();
        
        $("<img>").on("load", function() {
            // @ts-ignore
            if(this.width === 2 && this.height === 1) {
                deferred.resolve();
            } else {
                deferred.reject();
            }
        }).on("error", function() {
            deferred.reject();
        }).attr("src", images[feature || "basic"]);
        
        return deferred.promise();
    }
})();

vueAPI.onBeforeMounted(() => {
    const globalData = useGlobalData()
    globalData.screen.width = window.innerWidth
    globalData.screen.height = window.innerHeight
    window.addEventListener('resize', () => {
        globalData.screen.width = window.innerWidth
        globalData.screen.height = window.innerHeight
    })

    // WebP Support Check
    hasWebP().then(() => {
        console.log("WebP supported");
        globalData.webpSupport.support = true
    }, function() {
        console.warn("WebP not supported, please use a modern browser");
    });
    hasWebP('lossless').then(() => {
        console.log("WebP lossless supported");
        globalData.webpSupport.loseless = true
    }, function() {
        console.warn("WebP lossless not supported, please use a modern browser");
    });

    // Intersection Observer
    if (hasObserver()) {
        console.log('Intersection Observer supported')
        globalData.hasObserver = true
    }

    // Standalone App
    if (window.matchMedia('(display-mode: standalone)').matches) {
        globalData.standaloneApp = true
        document.body.classList.add('standalone')
    }

    // User Theme Preference
    if (localStorage.getItem('darkMode') === 'true') {
        globalData.darkMode = true
        document.querySelector('html')?.setAttribute('data-theme', 'dark')
    } else if (localStorage.getItem('darkMode') === 'false') {
        document.querySelector('html')?.setAttribute('data-theme', 'light')
    } else if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        globalData.darkMode = true
        document.querySelector('html')?.setAttribute('data-theme', 'dark')
    } else {
        document.querySelector('html')?.setAttribute('data-theme', 'light')
    }
    changeThemeColor(globalData.getDarkMode)

    watch(() => globalData.darkMode, (newVal) => {
        if (newVal) {
            localStorage.setItem('darkMode', 'true')
            document.querySelector('html')?.setAttribute('data-theme', 'dark')
        } else {
            localStorage.setItem('darkMode', 'false')
            document.querySelector('html')?.setAttribute('data-theme', 'light')
        }
        changeThemeColor(globalData.getDarkMode)
    })

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', async ({ matches }) => {
        if (matches) globalData.darkMode = true
        else globalData.darkMode = false
    })

    // Service Worker check
    navigator.serviceWorker.ready.then((registration) => {
        globalData.serviceWorkerReady = true

        registration.pushManager.getSubscription().then((subscription) => {
            if (subscription) globalData.notifications.pushEnabled = true
            else globalData.notifications.pushEnabled = false
        })
    })
})

function hasObserver() {
    return 'IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype;
}

function changeThemeColor(isDarkMode: boolean) {
    const themeColor = isDarkMode ? '#121212' : '#FFFFFF'
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', themeColor);
}