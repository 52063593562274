<template>
    <svg class="add-photo-icon" width="32px" height="31px" viewBox="0 0 32 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M18.5,6 C20.8163788,6 22.7239814,7.7501788 22.9725684,10.000297 L25,10 C27.7614237,10 30,12.2385763 30,15 L30,26 C30,28.7614237 27.7614237,31 25,31 L5,31 C2.23857625,31 -1.43817996e-15,28.7614237 0,26 L0,15 C-3.38176876e-16,12.2385763 2.23857625,10 5,10 L7.02743156,10.000297 C7.27601865,7.7501788 9.18362124,6 11.5,6 L18.5,6 Z M15,14 C11.6862915,14 9,16.6862915 9,20 C9,23.3137085 11.6862915,26 15,26 C18.3137085,26 21,23.3137085 21,20 C21,16.6862915 18.3137085,14 15,14 Z M15,17 C16.6568542,17 18,18.3431458 18,20 C18,21.6568542 16.6568542,23 15,23 C13.3431458,23 12,21.6568542 12,20 C12,18.3431458 13.3431458,17 15,17 Z M27.5,0 C28.3284271,-1.52179594e-16 29,0.671572875 29,1.5 L29,2.999 L30.5,3 C31.3284271,3 32,3.67157288 32,4.5 C32,5.32842712 31.3284271,6 30.5,6 L29,5.999 L29,7.5 C29,8.32842712 28.3284271,9 27.5,9 C26.6715729,9 26,8.32842712 26,7.5 L26,5.999 L24.5,6 C23.6715729,6 23,5.32842712 23,4.5 C23,3.67157288 23.6715729,3 24.5,3 L26,2.999 L26,1.5 C26,0.671572875 26.6715729,-5.13954221e-16 27.5,0 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'addPhotoIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>