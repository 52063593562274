<template>
<svg class="post-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Combined Shape</title>
    <g id="posts_icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M30,18 C31.1045695,18 32,18.8954305 32,20 L32,30 C32,31.1045695 31.1045695,32 30,32 L20,32 C18.8954305,32 18,31.1045695 18,30 L18,20 C18,18.8954305 18.8954305,18 20,18 L30,18 Z M12,18 C13.1045695,18 14,18.8954305 14,20 L14,30 C14,31.1045695 13.1045695,32 12,32 L2,32 C0.8954305,32 8.94280938e-16,31.1045695 0,30 L0,20 C-3.57315355e-16,18.8954305 0.8954305,18 2,18 L12,18 Z M12,0 C13.1045695,5.56104062e-16 14,0.8954305 14,2 L14,12 C14,13.1045695 13.1045695,14 12,14 L2,14 C0.8954305,14 8.94280938e-16,13.1045695 0,12 L0,2 C-3.57315355e-16,0.8954305 0.8954305,-1.91384796e-17 2,0 L12,0 Z M30,0 C31.1045695,5.56104062e-16 32,0.8954305 32,2 L32,12 C32,13.1045695 31.1045695,14 30,14 L20,14 C18.8954305,14 18,13.1045695 18,12 L18,2 C18,0.8954305 18.8954305,-1.91384796e-17 20,0 L30,0 Z" id="Combined-Shape" fill="#838387"></path>
    </g>
</svg>
</template>

<script>
export default {
    name: 'postsIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    #posts_icon {
        fill: var(--500);
        transition: fill 200ms linear;
    }
</style>