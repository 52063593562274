export type FormattedTextObj = {
    type: string,
    url?: string,
    protocol?: string,
    hashTag?: string,
    mention?: string
}

export type FormattedText = FormattedTextObj | string

type FormattedTextOptions = {
    links?: boolean,
    hashTags?: boolean,
    mentions?: boolean
}

export default {
    methods: {
        formatText(text: string, newOptions: FormattedTextOptions = {}) {
            let options: FormattedTextOptions = {
                links: true,
                hashTags: true,
                mentions: true,
                ...newOptions
            }

            if (typeof text !== 'string') return [text];
            else if (text.length == 0) return [text];
            text = text.replace(/(\r\n|\n|\r)/gm, '{{/text_wraper/}}{{/new_line/}}{{/text_wraper/}}');

            if (options.links) {
                let custom_http_link_regex = /(http|https):\/\/([\w/\-?=%.]+\.([\w/\-&?=%.]+))/gm
                let matchedArr2 = text.match(custom_http_link_regex)
                if (matchedArr2 && matchedArr2.length > 0) {
                    matchedArr2.forEach(thisMatch => {
                        custom_http_link_regex.lastIndex = 0
                        let custom_http_link_regex_result = custom_http_link_regex.exec(thisMatch)
                        if (custom_http_link_regex_result) {
                            let urlProtocol = custom_http_link_regex_result[1]
                            let urlDomain = custom_http_link_regex_result[2]
                            text = text.replace(thisMatch, `{{/text_wraper/}}{{/custom_link/[${urlProtocol}://${urlDomain}]}}{{/text_wraper/}}`)
                        }
                    })
                }
            }

            if (options.hashTags) {
                let hashTagRegex = /#([a-zA-Z0-9а-яА-я]+)/gm
                let hashTagMatchedArr = text.match(hashTagRegex)
                if (hashTagMatchedArr && hashTagMatchedArr.length > 0) {
                    hashTagMatchedArr.forEach(thisMatch => {
                        hashTagRegex.lastIndex = 0
                        let hashTagRegexResult = hashTagRegex.exec(thisMatch)
                        if (hashTagRegexResult) {
                            let hashTag = hashTagRegexResult[1]
                            text = text.replace(thisMatch, `{{/text_wraper/}}{{/hash_tag/[${hashTag}]}}{{/text_wraper/}}`)
                        }
                    })
                }
            }

            if (options.mentions) {
                let mentionRegex = /@(\S+)/gm
                let mentionMatchedArr = text.match(mentionRegex)
                if (mentionMatchedArr && mentionMatchedArr.length > 0) {
                    mentionMatchedArr.forEach(thisMatch => {
                        mentionRegex.lastIndex = 0
                        let mentionRegexResult = mentionRegex.exec(thisMatch)
                        if (mentionRegexResult) {
                            let mention = mentionRegexResult[1]
                            text = text.replace(thisMatch, `{{/text_wraper/}}{{/mention/[${mention}]}}{{/text_wraper/}}`)
                        }
                    })
                }
            }

            let splittedText = text.split('{{/text_wraper/}}')

            let formattedText: FormattedText[] = []
            splittedText.forEach((text, index) => {
                if (text) {
                    if (text.startsWith('{{/custom_link/[')) {
                        let url = text.replace('{{/custom_link/[', '').replace(']}}', '')
                        let urlSplit = url.split('://')
                        let urlProtocol = urlSplit[0]
                        let urlDomain = urlSplit[1]

                        if (!urlDomain) {
                            urlDomain = urlProtocol
                            urlProtocol = 'https'
                        }

                        return formattedText.push({
                            type: 'custom_link',
                            url: urlDomain,
                            protocol: urlProtocol
                        })
                    } else if (text.startsWith('{{/hash_tag/[')) {
                        let hashTag = text.replace('{{/hash_tag/[', '').replace(']}}', '')
                        return formattedText.push({
                            type: 'hash_tag',
                            hashTag: hashTag
                        })
                    } else if (text.startsWith('{{/mention/[')) {
                        let mention = text.replace('{{/mention/[', '').replace(']}}', '')
                        return formattedText.push({
                            type: 'mention',
                            mention: mention
                        })
                    }
                }

                formattedText.push(text)
            })

            // console.log(formattedText)

            return formattedText
        }
    }
}