import { defineStore as definePiniaStore } from 'pinia'
import socketIoAPI from '@/modules/socketAPI'
import vueAPI from '@/modules/vueAPI'
import Cookies from 'js-cookie'
import { imagesObj } from '@/plugins/useImageSizes';

type userRowTypes = {
    id?: number,
    username?: string,
    verified?: boolean,
    display_name?: string,
    avatar?: imagesObj[],
}

type userAdminRowTypes = {
    category?: string
}

type globalDataTypes = {
    showLoginModal: boolean,
    loginModalIsRegistering: boolean,
    loginUserData: {
        username: string,
        password: string
    },
    registerUserData: {
        username: string,
        email: string,
        password: string,
        password2: string
    },
    userLoggedIn: boolean,
    adminLoggedIn: boolean,
    row: userRowTypes,
    userIsAdmin: boolean,
    adminRow: userAdminRowTypes,
    checkingForLogin: boolean,
    unloginPosition: string | null
}

export const useLoginData = definePiniaStore('loginData', {
    state: () => ({
        showLoginModal: false,
        loginModalIsRegistering: false,
        loginUserData: {
            username: '',
            password: ''
        },
        registerUserData: {
            username: '',
            email: '',
            password: '',
            password2: ''
        },
        userLoggedIn: false,
        adminLoggedIn: false,
        row: {},
        userIsAdmin: false,
        adminRow: {},
        checkingForLogin: true,
        unloginPosition: null
    } as globalDataTypes),
    getters: {
        getLoginState: (state) => state.userLoggedIn,
        getCheckingForLogin: (state) => state.checkingForLogin,
        getAdminState: (state) => state.userIsAdmin,
    },
    actions: {
        unlogin() {
            if (!socketIoAPI.socket || !socketIoAPI.socket.connected) return
            socketIoAPI.socket.emit('unloginMe')
        },
        loginUser() {
            if (!socketIoAPI.socket || !socketIoAPI.socket.connected) return
            const loginData = {
                username: this.loginUserData.username,
                password: this.loginUserData.password
            }
            socketIoAPI.socket.emit('userLogin', loginData, device, async (returnData: any) => {
                if (returnData.success == true) {
                    Cookies.set('lgn_t', returnData.sessionKey, { expires: 30 })
                    
                    await updateMyPrfileData(this)
                    if (this.unloginPosition) {
                        this.router.push(this.unloginPosition);
                    } else {
                        this.router.push("/");
                    }

                    this.unloginPosition = null;
                    this.showLoginModal = false;
                } else {
                    userUnlogin()
                }
            })
        },
        registerUser() {
            if (!socketIoAPI.socket || !socketIoAPI.socket.connected) return
            const registerData = {
                username: this.registerUserData.username,
                email: this.registerUserData.email,
                password: this.registerUserData.password,
                password2: this.registerUserData.password2
            }
            socketIoAPI.socket.emit('userRegister', registerData, device, async (returnData: any) => {
                if (returnData.success == true) {
                    Cookies.set('lgn_t', returnData.sessionKey, { expires: 30 })
                    
                    await updateMyPrfileData(this)
                    vueAPI.onMounted(() => {
                        if (this.unloginPosition) {
                            this.router.push(this.unloginPosition);
                        } else {
                            this.router.push("/");
                        }
    
                        this.unloginPosition = null;
                        this.showLoginModal = false;
                    })
                } else {
                    userUnlogin()
                }
            })
        },
        toggleLoginModal() {
            this.loginModalIsRegistering = false
            this.showLoginModal = !this.showLoginModal
        },
        toggleRegisterModal() {
            this.loginModalIsRegistering = true
            this.showLoginModal = !this.showLoginModal
        },
        async updateMyData() {
            await updateMyPrfileData(this)
        }
    }
})

socketIoAPI.onNewConnection(function(socketVar) {
    const globalData = useLoginData()
    socketVar.on("connect", () => {
        console.log("Connected to server!");
        let timestampNow = Date.now()
        let thisCookiesLoginToken = Cookies.get('lgn_t')
        if (thisCookiesLoginToken && thisCookiesLoginToken.length >= 32) {
            console.log('Checking for login...')
            // @ts-ignore
            socketVar.emit('auth', timestampNow, thisCookiesLoginToken, device, function(returnData: any) {
                // console.log(`Return data: ${JSON.stringify(returnData)}`)
                vueAPI.onMounted(async function() {
                    if (!thisCookiesLoginToken) return
                    if (!returnData.success) {
                        userUnlogin()
                        console.log("Not logged in")
                    } else {
                        Cookies.set('lgn_t', thisCookiesLoginToken, { expires: 30 })
                        await updateMyPrfileData(globalData)
                        console.log(`Logged in!`)
                    }
                    globalData.checkingForLogin = false
                })
            })
        } else {
            vueAPI.onMounted(function() {
                userUnlogin()
                globalData.checkingForLogin = false
                console.log("Not logged in, token error or not found")
            })
        }
    })

    socketVar.on("refreshLoginToken", (callback) => {
        let thisCookiesLoginToken = Cookies.get('lgn_t')
        if (thisCookiesLoginToken && thisCookiesLoginToken.length >= 32) {
            callback(thisCookiesLoginToken)
        }
    })
})

socketIoAPI.onNewConnection(function(socketVar) {
    const globalData = useLoginData()
    socketVar.on('sessionLogout', function() {
        console.log('Logging out')
        Cookies.remove('lgn_t')
        vueAPI.onMounted(function() {
            userUnlogin()
            globalData.checkingForLogin = false
            globalData.unloginPosition = window.location.pathname
            if (!socketIoAPI.socket || !socketIoAPI.socket.connected) return
            socketIoAPI.socket.disconnect()
        })
    })
})


function userUnlogin() {
    const globalData = useLoginData()
    vueAPI.onMounted(function() {
        globalData.userLoggedIn = false
        globalData.row = {}
        globalData.userIsAdmin = false
        globalData.adminRow = {}
    })
}

async function updateMyPrfileData(state: ReturnType<typeof useLoginData>) {
    return new Promise((resolve, reject) => {
        if (!socketIoAPI.socket || !socketIoAPI.socket.connected) return reject()
        socketIoAPI.socket.emit('getMyProfileData', function(returnData: any) {
            if (!returnData.success) return userUnlogin()
            state.userLoggedIn = true;
            state.row = {
                id: returnData.data.id,
                username: returnData.data.username,
                verified: returnData.data.verified,
                display_name: returnData.data.displayName,
                avatar: returnData.data.avatar
            }
            if (returnData.data.adminData) {
                state.userIsAdmin = true;
                state.adminRow = {
                    category: returnData.data.adminData.category
                }
            }

            return resolve(true)
        })
    })
}