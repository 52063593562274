/* eslint-disable no-console */

import { register } from 'register-service-worker'
import vueAPI from "./modules/vueAPI"

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered(registration) {
      console.log('Service worker has been registered.')
      vueAPI._swCallback(registration)
    },
    cached() {
      console.log('Content has been cached for offline use.')
      vueAPI._swEventCallback('cached')
    },
    updatefound() {
      console.log('New content is downloading.')
      vueAPI._swEventCallback('updatefound')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      vueAPI._swEventCallback('updated')
      if (registration.waiting) registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
      vueAPI._swEventCallback('offline')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
      vueAPI._swEventCallback('error')
    }
  })
}

let refreshing = false
navigator.serviceWorker.addEventListener('controllerchange', () => {
  if (refreshing) return
  window.location.reload()
  refreshing = true
})
