<template>
    <svg class="message_icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="messages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M7.03228917,0.516902346 L29.3400769,11.5755834 C31.7229057,12.7568275 32.6887652,15.6296448 31.4973848,17.9922002 C31.0384207,18.9023446 30.2986428,19.6436286 29.3855946,20.1082899 L7.03409857,31.4832365 C4.66400547,32.6894055 1.75648166,31.7622055 0.539962381,29.4122774 C-0.108011552,28.1605978 -0.167262617,26.6895319 0.377978992,25.3906068 L3.69893015,17.4791293 L18.395368,17.3414206 C19.197847,17.3339011 19.8541275,16.7051702 19.8892534,15.9102458 C19.9230436,15.1455529 19.3252102,14.4984877 18.5539556,14.464985 C18.542938,14.4645064 18.5319152,14.4641572 18.5208894,14.4639373 L3.71093351,14.1685196 L0.448240051,6.6944169 C-0.609954729,4.27032877 0.514183394,1.45467926 2.95907281,0.405487674 C4.26676524,-0.155691034 5.75779097,-0.114907012 7.03228917,0.516902346 Z" id="Rectangle" fill="#838387" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'messageIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 23px;
        width: 25px;
    }
    .message_icon {
        @media(max-width: 430px) {
            height: 21px;
            width: 21px;
            margin-left: 0;
            margin-bottom: 0;   
        }

        .active > & {
            path {
                fill: var(--dpurple);
            }
        }
    }

    .post_content_wrapper {
            .message_icon {
            height: 22px;
            width: 22px;

            path {
                fill: var(--dpurple);
            }
        }
    }

    .section_wrapper {
            &.router-link-exact-active{
                #messages {
                    path {
                        fill: var(--dpurple);
                    }
                }
            }
    }
</style>