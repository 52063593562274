<template>
    <template v-for="(thisTextPeace, thisTextPeaceIdx) in formatedText" :key="thisTextPeaceIdx">
        <template v-if="typeof thisTextPeace === 'object'">
            <template v-if="thisTextPeace.type == 'custom_link'">
                <a class="navigate_link" :href="`${thisTextPeace.protocol}://${thisTextPeace.url}`" target="_blank">
                    <strong>{{ thisTextPeace.url }}</strong>
                </a>
            </template>
            <template v-else-if="thisTextPeace.type == 'hash_tag'">
                <router-link class="hash_tag" :to="{
                    path: '/search',
                    query: {
                        hash: thisTextPeace.hashTag
                    }
                }">
                    #{{ thisTextPeace.hashTag }}
                </router-link>
            </template>
            <template v-else-if="thisTextPeace.type == 'mention'">
                <router-link class="mention" :to="`/${thisTextPeace.mention}`">
                    @{{ thisTextPeace.mention }}
                </router-link>
            </template>
        </template>
        <template v-else>
            <br v-if="thisTextPeace == '{{/new_line/}}'" />
            <template v-else>
                {{ thisTextPeace }}
            </template>
        </template>
    </template>
</template>

<script>
export default {
    props: {
        formatedText: {
            type: Array,
            required: true
        }
    }
}
</script>