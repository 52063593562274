<template>
    <svg class="logo-icon-main" width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Icon-group</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Icon-group" transform="translate(0.866667, 0.866667)">
            <g id="logo-icon-main">
                <path d="M9.77205236,5.91301868 L10.1865979,5.91253271 C9.980516,8.19927104 8.15946407,10.020323 5.87272574,10.2264049 L5.87251492,9.78364472 C7.92669114,9.5901988 9.56370326,7.96282382 9.77205236,5.91301868 Z M5.00547537,9.77924739 L5.00600559,10.2264049 C2.71926726,10.020323 0.89821533,8.19927104 0.692133382,5.91253271 L1.15020716,5.91301868 C1.35705736,7.94807714 2.97208838,9.56676166 5.00547537,9.77924739 Z M5.87272574,0.731940346 C8.15946407,0.938022293 9.980516,2.75907422 10.1865979,5.04581255 L9.77394852,5.04522255 C9.57384727,2.98644687 7.93305817,1.34956943 5.87251492,1.15552392 L5.87272574,0.731940346 Z M1.148311,5.04522255 L0.692133382,5.04581255 C0.89821533,2.75907422 2.71926726,0.938022293 5.00600559,0.731940346 L5.00547537,1.15992124 C2.96574012,1.37307036 1.34697713,3.00121232 1.148311,5.04522255 Z" id="Combined-Shape" fill="#8682FE"></path>
                <path d="M5.45916639,-1.77635684e-15 C5.58476431,-1.77635684e-15 5.68658154,0.101884942 5.68658154,0.227566386 L5.68667994,1.36895554 C7.72997139,1.48156152 9.3738807,3.09368838 9.53796878,5.12417846 L9.54590604,5.24409345 L10.6885118,5.24429818 C10.8141097,5.24429818 10.9159269,5.34618313 10.9159269,5.47186457 C10.9159269,5.59754602 10.8141097,5.69943096 10.6885118,5.69943096 L9.54448712,5.69954708 C9.42440062,7.77347865 7.76237592,9.43346575 5.68849685,9.54867296 L5.68823014,10.6895697 C5.68823014,10.8012866 5.60778196,10.8942013 5.50169315,10.9134697 L5.460815,10.9171361 C5.33521708,10.9171361 5.23339985,10.8152512 5.23339985,10.6895697 L5.23333578,9.54899679 C3.15670705,9.43661618 1.49164026,7.7754469 1.3714398,5.69954708 L0.227415144,5.69943096 C0.101817228,5.69943096 1.04094511e-12,5.59754602 1.04094511e-12,5.47186457 C1.04094511e-12,5.34618313 0.101817228,5.24429818 0.227415144,5.24429818 L1.37002087,5.24409345 C1.47777187,3.15672729 3.14710361,1.48255309 5.2315184,1.36883099 L5.23175125,0.227566386 C5.23175125,0.115849547 5.31219943,0.0229348821 5.41828823,0.00366639916 L5.45916639,-1.77635684e-15 Z M5.45841829,9.10538829 L5.53119113,9.1012921 L5.57129776,9.10092285 C7.41474544,9.04448482 8.91311864,7.6159576 9.08096447,5.80144944 L9.09041839,5.67322024 L9.10206372,5.46796717 L9.09168134,5.26757198 C8.99798085,3.45239409 7.57459412,1.98762207 5.77691346,1.83157361 L5.66166876,1.82339965 L5.45887312,1.81188561 L5.25627989,1.82328879 C3.44224733,1.9222593 1.98195793,3.35160395 1.83202686,5.15214871 L1.82424557,5.26757198 L1.81340836,5.46796717 L1.82621344,5.68754901 C1.9389723,7.53044965 3.42166096,9.00280868 5.26810674,9.09778427 L5.45841829,9.10538829 Z" id="Combined-Shape-Copy-3" fill="#11151C" fill-rule="nonzero"></path>
                <path d="M5.45916639,-1.77635684e-15 C5.58476431,-1.77635684e-15 5.68658154,0.101884942 5.68658154,0.227566386 L5.68667994,1.36895554 C7.72997139,1.48156152 9.3738807,3.09368838 9.53796878,5.12417846 L9.54590604,5.24409345 L10.6885118,5.24429818 C10.8141097,5.24429818 10.9159269,5.34618313 10.9159269,5.47186457 C10.9159269,5.59754602 10.8141097,5.69943096 10.6885118,5.69943096 L9.54448712,5.69954708 C9.42440062,7.77347865 7.76237592,9.43346575 5.68849685,9.54867296 L5.68823014,10.6895697 C5.68823014,10.8012866 5.60778196,10.8942013 5.50169315,10.9134697 L5.460815,10.9171361 C5.33521708,10.9171361 5.23339985,10.8152512 5.23339985,10.6895697 L5.23333578,9.54899679 C3.15670705,9.43661618 1.49164026,7.7754469 1.3714398,5.69954708 L0.227415144,5.69943096 C0.101817228,5.69943096 -3.21520588e-13,5.59754602 -3.21520588e-13,5.47186457 C-3.21520588e-13,5.34618313 0.101817228,5.24429818 0.227415144,5.24429818 L1.37002087,5.24409345 C1.47777187,3.15672729 3.14710361,1.48255309 5.2315184,1.36883099 L5.23175125,0.227566386 C5.23175125,0.115849547 5.31219943,0.0229348821 5.41828823,0.00366639916 L5.45916639,-1.77635684e-15 Z M5.45841829,9.10538829 L5.53119113,9.1012921 L5.57129776,9.10092285 C7.41474544,9.04448482 8.91311864,7.6159576 9.08096447,5.80144944 L9.09041839,5.67322024 L9.10206372,5.46796717 L9.09168134,5.26757198 C8.99798085,3.45239409 7.57459412,1.98762207 5.77691346,1.83157361 L5.66166876,1.82339965 L5.45887312,1.81188561 L5.25627989,1.82328879 C3.44224733,1.9222593 1.98195793,3.35160395 1.83202686,5.15214871 L1.82424557,5.26757198 L1.81340836,5.46796717 L1.82621344,5.68754901 C1.9389723,7.53044965 3.42166096,9.00280868 5.26810674,9.09778427 L5.45841829,9.10538829 Z" id="target" fill="#2C294D" fill-rule="nonzero"></path>
                </g>
                <g id="icon-letters" transform="translate(2.850447, 4.029454)">
                    <polygon id="k-letter" fill="#8682FE" points="0.296785755 2.87561447 0.297910739 1.79274852 0.838886059 1.79260184 1.59800312 2.87561447 2.47268939 2.87561447 1.45235741 1.49475195 2.39607453 0.000118545441 1.52138827 0.000118545441 0.838886059 1.14231087 0.309163364 1.14093328 0.296785755 0.000118545441 5.83977311e-14 0.000118545441 5.83977311e-14 2.87561447"></polygon>
                    <polygon id="h-letter" fill="#2C294D" points="3.84113876 2.87549592 3.84113876 1.79017578 4.9207327 1.79017578 4.9207327 2.87549592 5.00909471 2.87549592 5.00909471 4.4408921e-16 4.9207327 4.4408921e-16 4.9207327 1.14382492 3.84113876 1.14382492 3.84113876 4.4408921e-16 2.96645249 4.4408921e-16 2.96645249 2.87549592"></polygon>
                </g>
                <path d="M5.43936567,8.51250596 C7.11462941,8.51250596 8.472699,7.15443637 8.472699,5.47917263 C8.472699,3.80390889 7.11462941,2.4458393 5.43936567,2.4458393 C3.76410192,2.4458393 2.40603233,3.80390889 2.40603233,5.47917263 C2.40603233,7.15443637 3.76410192,8.51250596 5.43936567,8.51250596 Z M5.43936567,7.75417263 C6.69581347,7.75417263 7.71436567,6.73562044 7.71436567,5.47917263 C7.71436567,4.22272482 6.69581347,3.20417263 5.43936567,3.20417263 C4.18291786,3.20417263 3.16436567,4.22272482 3.16436567,5.47917263 C3.16436567,6.73562044 4.18291786,7.75417263 5.43936567,7.75417263 Z" id="radial-center" fill="#2C294D"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'logoIconMainIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .logo-icon-main {
        height: 100%;
        width: 100%;

        #target {
            fill: var(--logoPrime);
        }

        #h-letter {
            fill: var(--logoPrime);
        }

        #radial-center {
            fill: var(--logoSpecial);
        }
    }
</style>