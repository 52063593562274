<template>
    <svg class="notification-icon" width="24px" height="26px" viewBox="0 0 24 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M12.0075381,0.874925087 C14.3870181,1.00242717 16.783672,2.05493911 18.8544404,4.08492819 C23.0677306,9.03936823 19.5442802,13.6819509 23.4350395,19.0332138 C24.5037022,20.6786673 24.2215292,22.760985 21.2030503,22.760985 L15.9275921,22.7615572 C15.571973,24.6064683 13.9487249,26 12,26 C10.0512751,26 8.42802703,24.6064683 8.0724079,22.7615572 L2.81568068,22.760985 C-0.202798193,22.760985 -0.484971242,20.6786673 0.583691458,19.0332138 C4.47445082,13.6819509 0.951000344,9.03936823 5.16429061,4.08492819 C7.14407981,2.14412669 9.4217479,1.09679821 11.7025952,0.897121003 L12.0075381,0.874925087 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'notificationIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 24px;
        width: 25px;

        @media(max-width: 430px) {
            height: 22px;
        }
    }
    
    .section_wrapper {
        &.active {
            .notifications-icon {
                svg {
                    path {
                        fill: var(--dpurple);
                    }
                }
            }
            
        }
    }    
</style>