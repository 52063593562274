<template>
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>Combined Shape</title>
        <g id="shost_icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M31.9996666,12.7997071 L31.9996666,25.9996747 C31.9996666,29.3133832 29.3133751,31.9996747 25.9996666,31.9996747 L5.99993331,31.9996747 C2.68622481,31.9996747 -6.66870312e-05,29.3133832 -6.66870312e-05,25.9996747 L-0.000333423837,12.7997071 L31.9996666,12.7997071 Z M31.9996666,5.99994141 L31.9996666,9.59970707 L25.5586666,9.59970707 L31.5182925,3.64128549 C31.8281348,4.36529318 31.9996666,5.1625856 31.9996666,5.99994141 Z M25.9996666,-5.85870728e-05 C27.3084834,-5.85870728e-05 28.5194221,0.419007261 29.5056231,1.13027929 L21.0326666,9.59970707 L14.3586666,9.59970707 L23.9596666,-0.000292925598 L25.9996666,-5.85870728e-05 Z M19.4346666,-0.000292925598 L9.83366658,9.59970707 L3.15866658,9.59970707 L12.7596666,-0.000292925598 L19.4346666,-0.000292925598 Z M8.23466658,-0.000292925598 L-0.000333423837,8.23370707 L-6.66870312e-05,5.99994141 C-6.66870312e-05,2.68623291 2.68622481,-5.85870728e-05 5.99993331,-5.85870728e-05 L8.23466658,-0.000292925598 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'shotsIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .shots_icon {
        #shost_icon {
            fill: var(--500);
            transition: fill 200ms linear;
        }
    }
</style>