export default {
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "sign-up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
  "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
  "your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your"])},
  "your_name": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Your ", _linked("name", undefined, _type)])},
  "your_email": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("your", undefined, _type), " ", _linked("email", undefined, _type)])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "repeat_password": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize(["Repeat ", _linked("password", undefined, _type)])},
  "name_or_email": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("name", undefined, _type), " ", _linked("or", undefined, _type), " ", _linked("email", undefined, _type)])},
  "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
  "with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with"])},
  "register_with": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("sign-up", undefined, _type), " ", _linked("with", undefined, _type)])},
  "login_with": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("login", undefined, _type), " ", _linked("with", undefined, _type)])},
  "forgotten_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten password"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "collapse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collapse"])},
  "expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "more-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More actions"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
  "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join"])},
  "beforeunload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to leave this page? Changes you made may not be saved."])},
  "theme": {
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark mode"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light mode"])}
  },
  "feed-page": {
    "suggested-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggested for you"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])}
  },
  "user_profile": {
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "send_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
    "edit_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit profile"])},
    "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow"])},
    "unfollow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfollow"])},
    "followers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Followers"]), _normalize(["Follower"]), _normalize(["Followers"])])},
    "following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Following"])},
    "follows_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follows you"])},
    "enjoyers": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Enjoyers"]), _normalize(["Enjoyer"]), _normalize(["Enjoyers"])])},
    "posts": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Posts"]), _normalize(["Post"]), _normalize(["Posts"])])},
    "become-an-enjoyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become an enjoyer"])},
    "tabs": {
      "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
      "post_not_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all your posts, unfortunately you have not created any posts"])},
      "feed_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feed"])},
      "post_not_created_feed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all your posts in the feed view, sorted by post creation date. Sorry, you have not created any post"])},
      "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shots"])},
      "post_not_created_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will find all your shots. Sorry, you have not created any shot"])},
      "enjoyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoyers"])},
      "only_for_enjoyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only for Enjoyers"])},
      "only_for_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join the Enjoyers and get access to exclusive content and offers"])},
      "warning_explicit_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! The content may contain explicit, erotic and / or porn content"])},
      "exclusive_videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exclusive Videos"])},
      "private_photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Photos"])},
      "bonuses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonuses"])},
      "video_call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Call"])},
      "dinner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinner"])},
      "traveling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traveling"])},
      "free_chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free chat"])}
    },
    "settings": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
      "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings section"])},
      "section_description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Here you will find all settings belongings to your page on ", _interpolate(_named("text")), " platform"])},
      "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
      "account_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account settings"])},
      "profile_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete your profile"])},
      "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])},
      "payments_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments and billings settings"])},
      "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
      "privacy_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore privacy settings"])},
      "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
      "security_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account and security settings"])},
      "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "notifications_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your notifications"])},
      "paid_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid services"])},
      "paid_services_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your paid services"])},
      "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
      "promotion_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your account promotion"])},
      "chats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chats"])},
      "chats_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your chats"])},
      "posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posts"])},
      "posts_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your posts"])},
      "enjoyers_explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings for Enjoyers"])},
      "accountSettings": {
        "section-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your account settings"])},
        "section-payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments and billings settings"])},
        "section-paid-services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your paid services"])},
        "section-promote-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account promotion settings"])},
        "section-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup your notifications"])},
        "section-privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy settings"])},
        "section-security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account security settings"])},
        "section-chat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setup chat settings"])},
        "section-posts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your post settings"])}
      },
      "your-account-settings": {
        "account-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account content:"])},
        "im_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm Creator"])},
        "im_enjoyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm Enjoyer"])},
        "erotic-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contains erotic content"])},
        "not-erotic-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not contain erotic content"])},
        "general-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General settings:"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
        "appearance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch appearance"])},
        "show-account-search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show my account in search"])},
        "dont-see-erotic-content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not show profiles with erotic content"])}
      },
      "notificationsSettings": {
        "push-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Push notifications:"])},
        "new-followers-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Followers notifications"])},
        "new-enjoyers-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Enjoyers notifications"])},
        "new-messages-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Messages notifications"])},
        "new-kisses-summary-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New kisses summary notifications"])},
        "daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])},
        "every_2_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every two days"])},
        "every_3_days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every three days"])},
        "weekly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])},
        "new-comments-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Comments notifications"])},
        "email-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email notifications:"])},
        "kissHunter-newsletters-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KissHunter newsletters notifications"])},
        "promotion-campaign-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications about your promotion campaigns"])}
      },
      "paidSettings": {
        "subscription-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription price:"])},
        "subscription-are-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription are free"])},
        "price-per-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per month"])},
        "subscription-packs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription packs"])},
        "add_pack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add pack"])},
        "messages-from-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages from users:"])},
        "free-messages-for-anybody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free messages for anybody"])},
        "price-per-message-for-anybody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per message for anybody"])},
        "price-per-message-for-Enjoyers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price per message for Enjoyers"])},
        "enjoyers-and-following": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enjoyers and following:"])},
        "auto-follow-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto follow back Enjoyers for the duration of their subscription"])}
      },
      "promotionSettings": {
        "direct-account-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct account promotion:"])},
        "my-account-suggested-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show my account in section  Suggested"])},
        "my-account-hunters-section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show my account in section Hunters"])},
        "my-account-topKissed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show my account in Top Kissed"])},
        "tagging_creating_tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagging and creating tags"])}
      },
      "edit_profile_settings": {
        "complete_empty_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete empty fields"])},
        "user_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
        "name_can_be_changed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name can be changed in"])},
        "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Days"])},
        "display_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display Name"])},
        "your_surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Surname"])},
        "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
        "only_one_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only one link can be created"])},
        "add_any_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add any link"])},
        "your_social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select social network"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
        "choose_profile_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your profile category"])},
        "add_social_mediaBtn": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("add", undefined, _type)])}
      },
      "enjoyersSettings": {
        "add_bonus": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("add", undefined, _type), " bonus"])},
        "schedule": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("add", undefined, _type), " schedule"])},
        "video_call_connection": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("add", undefined, _type), " communication method"])},
        "treveling_preferences": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("create", undefined, _type), " preferences"])},
        "create_offer": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("create", undefined, _type), " an offer"])},
        "bonuses_offer": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("user_profile.tabs.bonuses", undefined, _type)])},
        "video_call_offer": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("user_profile.tabs.video_call", undefined, _type)])},
        "dinner_offer": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("user_profile.tabs.dinner", undefined, _type)])},
        "traveling_offer": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("user_profile.tabs.traveling", undefined, _type)])},
        "your_own_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create your own offer"])}
      }
    }
  },
  "header": {
    "your_feed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feed"])},
    "kisses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kisses"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "add_post": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Post"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "subscribes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribes"])},
    "hunters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunters"])},
    "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
    "statistic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])}
  },
  "copyright": {
    "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])}
  },
  "post": {
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "posted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posted"])},
    "kissed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kisses"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report"])},
    "report_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason"])},
    "report_reasons": {
      "spam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spam"])},
      "inappropriate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inappropriate"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
    },
    "slide-buttons": {
      "button-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
      "button-previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prev"])}
    },
    "report_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post reported successfully"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error reporting post"])},
    "delete_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post deleted successfully"])},
    "delete_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error deleting post"])},
    "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
    "post_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post"])},
    "add_comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment"])},
    "show_comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show comments"])},
    "view_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View replies"])},
    "hide_replies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide replies"])},
    "likes_count": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("count")), " likes"]), _normalize([_interpolate(_named("count")), " like"]), _normalize([_interpolate(_named("count")), " likes"])])},
    "reply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reply"])},
    "kisses_modal": {
      "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
      "enter_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Quantity"])}
    }
  },
  "emoji": {
    "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
    "smileys_and_people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smileys & People"])},
    "animals_and_nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animals & Nature"])},
    "food_and_drink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food & Drink"])},
    "activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "travel_and_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travel & Places"])},
    "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])}
  },
  "messages_block": {
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
    "messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages"])},
    "new_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Message"])},
    "free_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])},
    "your_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your messages"])},
    "send_private_messages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send private messages to a friends or share the posts"])},
    "tips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tips"])},
    "your_chats": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("user_profile.settings.chats", undefined, _type)])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "paid_message_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add a tip to this message to send it"])},
    "paid_message_info_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correspondence with the user is paid. You can write to the creator only by adding a tip (content creator support). Payment - the minimum set by the user is paid, or you can specify the desired amount, but not lower than the minimum and not higher than the maximum. Prices can be found by clicking on the coin"])},
    "tips_modal": {
      "add": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("add", undefined, _type)])},
      "apply": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("apply", undefined, _type)])},
      "min_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min. Fee"])},
      "max_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Fee"])},
      "enter_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Amount"])},
      "cancel": (ctx) => {const { normalize: _normalize, linked: _linked, type: _type } = ctx;return _normalize([_linked("cancel", undefined, _type)])}
    }
  },
  "date": {
    "days_short": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tue"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wed"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fri"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sat"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sun"])},
      
    ],
    "days": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saturday"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sunday"])},
      
    ],
    "months_short": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feb"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mar"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apr"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jun"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jul"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aug"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sep"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oct"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nov"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dec"])},
      
    ],
    "months": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])},
      
    ],
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "yesterday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yesterday"])},
    "tomorrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tomorrow"])}
  }
}