<template>
    <svg class="emoji-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M16,3 C8.82029825,3 3,8.82029825 3,16 C3,23.1797017 8.82029825,29 16,29 C23.1797017,29 29,23.1797017 29,16 C29,8.82029825 23.1797017,3 16,3 Z M11.838757,19.8679353 C13.939943,23.1046027 18.2788554,23.1046027 20.1353995,19.9099814 C20.4129004,19.4324757 21.0249542,19.2703398 21.5024599,19.5478407 C21.9799655,19.8253416 22.1421015,20.4373954 21.8646005,20.9149011 C19.2451512,25.4222808 13.060057,25.4222808 10.161243,20.9569472 C9.86052068,20.4937145 9.99226139,19.8744066 10.4554941,19.5736842 C10.9187268,19.2729619 11.5380347,19.4047026 11.838757,19.8679353 Z M11.5,11.6607471 C12.3284271,11.6607471 13,12.33232 13,13.1607471 C13,13.9891742 12.3284271,14.6607471 11.5,14.6607471 C10.6715729,14.6607471 10,13.9891742 10,13.1607471 C10,12.33232 10.6715729,11.6607471 11.5,11.6607471 Z M20.5,11.6607471 C21.3284271,11.6607471 22,12.33232 22,13.1607471 C22,13.9891742 21.3284271,14.6607471 20.5,14.6607471 C19.6715729,14.6607471 19,13.9891742 19,13.1607471 C19,12.33232 19.6715729,11.6607471 20.5,11.6607471 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'emojiIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>