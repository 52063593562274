import { computed, watch, ref, Ref } from 'vue'
import { useGlobalData } from '@/store/globalData'
import photoFunctions from '@/mixins/photoFunctions';

export type imagesObj = {
    full_path: string,
    size: string
}

export type sizesByWidth = {
    [key: string]: number
}

type optionsData = {
    defaultSize?: string,
    sizesByWidth?: sizesByWidth | null
}

export function useImageSizes(options?: optionsData) {
    const defaultOptions: optionsData = {
        defaultSize: 'original',
        sizesByWidth: null
    }
    const thisOptions = options ? Object.assign(defaultOptions, options) : defaultOptions

    const refElement = ref<HTMLElement | null>(null)
    const images = ref<imagesObj[] | undefined>()
    const globalData = useGlobalData()

    const defaultImage = computed(() => {
        if (!images.value) return ''
        const findedImage = images.value.find(image => image.size === thisOptions.defaultSize)
        if (!findedImage) return ''
        return photoFunctions.serverPhotoPathToUrl(findedImage.full_path)
    })
    
    const sizes = computed(() => {
        if (!images.value) return []
        if (!thisOptions.sizesByWidth) return []
        const thisSizes = Object.keys(thisOptions.sizesByWidth)
        return images.value.filter(image => thisSizes.includes(image.size)).map(image => {
            return {
                src: photoFunctions.serverPhotoPathToUrl(image.full_path),
                width: thisOptions.sizesByWidth ? thisOptions.sizesByWidth[image.size] : 0
            }
        })
    })

    const biggestSize = computed(() => {
        if (!images.value) return null
        const findedImage = sizes.value.sort((a, b) => b.width - a.width)[0]
        if (!findedImage) return null
        return findedImage
    })

    const currentSize = ref('')
    watch(images, () => {
        const newCurrentSize = getCurrentSize()
        if (newCurrentSize === currentSize.value) return
        currentSize.value = newCurrentSize
        if (!refElement.value) return
        changeElementStyle(refElement, currentSize)
    })
    watch(() => globalData.getScreenWidth, () => {
        const newCurrentSize = getCurrentSize()
        if (newCurrentSize === currentSize.value) return
        currentSize.value = newCurrentSize
        if (!refElement.value) return
        changeElementStyle(refElement, currentSize)
    }, {
        immediate: true
    })

    watch(refElement, (thisElement) => {
        if (!thisElement) return
        changeElementStyle(refElement, currentSize)
    })

    return [refElement, images]


    function getCurrentSize() {
        if (!images.value) return ''
        if (biggestSize.value && biggestSize.value.width < globalData.getScreenWidth) return defaultImage.value
        let currentSize = null
        for (let i = 0; i < sizes.value.length; i++) {
            const thisSize = sizes.value[i]
            if (thisSize.width >= globalData.getScreenWidth) {
                if (currentSize && currentSize.width < thisSize.width) continue
                currentSize = thisSize
            }
        }
        if (!currentSize) return defaultImage.value

        return currentSize.src
    }
}

function changeElementStyle(thisElement: Ref<HTMLElement | null>, currentSize: Ref<string>) {
    if (!thisElement.value) return
    thisElement.value.style.backgroundImage = `url(${currentSize.value})`
}