import feedSolid from './feed-solid.vue';
import kissIcon from './kissIcon.vue';
import messageIcon from './message-solid.vue';
import postsSolid from './posts-solid.vue';
import videoSolid from './video-solid.vue';
import shareSolid from './share-solid.vue';
import moreVertical from './more-vertical.vue';
import oreonStroke from './oreon-stroke.vue';
import squareOreonStroke from './square-oreon.vue';
import addPostIcon from './add-post.vue';
import shotsIcon from './shots-solid.vue';
import commentsIcon from './comments-icon.vue';
import kisshunterIcon from './main-logo.vue';
import statusIcon from './status-icon.vue';
import closeIcon from './close-icon.vue';
import logoIcon from './logo-icon-main.vue';
import kissanimationIcon from './kiss-icon-animation.vue';
import likeIcon from './comment-like-icon.vue';
import unlikeIcon from './comment-unlike-icon.vue';
import notificationsIcon from './notifications-icon.vue';
import searchIcon from './search-icon.vue';
import lockIcon from './lock-icon.vue';
import editIcon from './edit-icon.vue';
import settingsIcon from './settings-icon.vue';
import noPhotoIcon from './no-photo-icon.vue';

export default [
    feedSolid,
    kissIcon,
    messageIcon,
    postsSolid,
    videoSolid,
    shareSolid,
    moreVertical,
    oreonStroke,
    squareOreonStroke,
    addPostIcon,
    shotsIcon,
    commentsIcon,
    kisshunterIcon,
    statusIcon,
    closeIcon,
    logoIcon,
    kissanimationIcon,
    likeIcon,
    unlikeIcon,
    notificationsIcon,
    searchIcon,
    lockIcon,
    editIcon,
    settingsIcon,
    noPhotoIcon,
]