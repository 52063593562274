<template>
    <post-options-menu-outer ref="postOptionsElm">
        <div class="optionsMenuBody noselect" v-if="openedMenuPage === 'adminMenu'">
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Block Post</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Get information</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Block Post User</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch @click="closeMenuPage()">
                <p>{{ $t('close') }}</p>
            </div>
        </div>
        <div class="optionsMenuBody noselect" v-else>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>{{ $t('report') }}</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Share</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Copy Link</p>
            </div>
            <router-link to="/notification" class="optionsMenuBodyItem" v-mobile-touch>
                <p>Turn on Post Notifications</p>
            </router-link>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Unfollow</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Mute</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch>
                <p>Embed</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch v-if="loginData.getAdminState" @click="selectMenuPage('adminMenu')">
                <p>Admin Menu</p>
            </div>
            <div class="optionsMenuBodyItem" v-mobile-touch @click="closeMenu()">
                <p>{{ $t('cancel') }}</p>
            </div>
        </div>
    </post-options-menu-outer>
</template>

<script setup>
import { ref } from 'vue';
import { useLoginData } from '@/store/loginData';

import postOptionsMenuOuter from '@/components/optionsMenuOuter.vue';

const loginData = useLoginData();

const postOptionsElm = ref(null);
const openedMenuPage = ref(null)

function selectMenuPage(page) {
    openedMenuPage.value = page
}
function closeMenuPage() {
    openedMenuPage.value = null
}

function closeMenu() {
    postOptionsElm.value.closeMenu()
}
</script>

<style lang="scss" scoped>
</style>