<template>
    <div class="avtar_oreon" :class="{
        'no_stories': !isStory,
    }">
        <oreon-Stroke-SVG></oreon-Stroke-SVG>
        <div class="user_avatar" :class="{
            'no_avatar': !avatarIsset && isMyProfile,
            'without_photo': !avatarIsset
        }">
            <addPhotoIconSVG/>
            <div class="without_photo">
                <nophoto-Icon-SVG></nophoto-Icon-SVG>
            </div>
            <picture v-if="src">
                <img draggable="false" :src="src" alt="user-avatar">
            </picture>
            <imageSizesBlock v-else-if="avatar"
                :images="avatar" 
                :defaultSize="defaultSize" 
                :sizesByWidth="sizesByWidth" 
                alt="user-avatar" />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import imageSizesBlock from '@/components/imageSizesBlock.vue';
import { imagesObj, sizesByWidth as pluginSizesByWidth } from '@/plugins/useImageSizes';
import addPhotoIconSVG from '@/components/svg/add-photo-icon.vue';

type ComponentProps = {
    isStory?: boolean;
    avatar?: imagesObj[] | null;
    src?: string | null;
    isMyProfile?: boolean;
    defaultSize?: string;
    sizesByWidth?: pluginSizesByWidth | null;
}

const props = withDefaults(defineProps<ComponentProps>(), {
    isStory: false,
    avatar: null,
    isMyProfile: false,
    defaultSize: 'original',
    sizesByWidth: null,
});

const avatarIsset = computed(() => {
    if (props.src) return true;
    else if (props.avatar) return true;
    return false;
});

</script>

<style lang="scss" scoped>
.avtar_oreon {
    :deep(svg) {
        &.oreon {
            height: 112px;
            width: 112px;
            overflow: visible;
            circle {
                stroke-linecap: round;
                stroke-dasharray: 1;
                stroke-dashoffset: 0;
                animation: draw-stroke 1.8s ease-out infinite;
            }
        }
    }
    
    @keyframes draw-stroke {
        0% {
            stroke-dasharray:  950, 55, 60, 55, 900, 35, 15;
            // stroke-dasharray: 900, 35, 15,;
            -webkit-transform: rotate(0);
            stroke-width: 5;
            transform: rotate(0);
            -webkit-transform: rotate(0);
        }
        100% {
            stroke-dasharray: -5;
            stroke-width: 5;
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg), translate(-50%, -50%);
        }
    }
    
    &.no_stories {
        $border-radius: 10rem;
        position: relative;
        padding: 8px 7px 8px 8px;
        border-radius: $border-radius;
        border: solid 1px var(--300);
    
        .oreon {
            display: none;
        }
    }

    .user_avatar {
        .without_photo {
            position: absolute;
            display: none;
            inset: 0;
            margin: auto;
            height: 40px;
            width: 40px;
        }

        .add-photo-icon {
            display: none;
            position: absolute;
            inset: 0;
            margin: auto;
            height: 48px;
            width: 48px;
            cursor: pointer;
        }

        &.without_photo {
            .without_photo {
                display: block;
            }

            .add-photo-icon {
                display: none;
            }
        }

        &.no_avatar {
            .add-photo-icon {
                display: block;
            }
        }
    }
}
</style>