import Trans from '@/plugins/Translation'

export default {
    methods: {
        getTranslation(obj: any, language = 'current', customName = '') {
            if (language == 'current') {
                language = Trans.currentLocale
            } else if (!Trans.isLocaleSupported(language)) {
                language = Trans.fallbackLocale
            }

            if (!obj.hasOwnProperty(customName + language)) {
                return 'no-lang'
            } else if (!obj[customName + language]) {
                return obj[customName + Trans.fallbackLocale]
            }

            return obj[customName + language]
        }
    }
}