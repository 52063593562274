import i18n from '@/i18n'
import supportedLocales from '@/locales/supportedLocales'

const Trans = {
    get defaultLocale() {
        return process.env.VUE_APP_I18N_LOCALE
    },
    get supportedLocales() {
        return supportedLocales
    },
    get currentLocale() {
        return i18n.global.locale.value
    },
    get fallbackLocale() {
        return process.env.VUE_APP_I18N_FALLBACK_LOCALE || this.defaultLocale
    },
    set currentLocale(locale: string) {
        i18n.global.locale.value = locale
    },
    async changeLocale(locale: string) {
        if (!locale) return Promise.reject(new Error('Locale not specified'))
        if (!Trans.isLocaleSupported(locale)) return Promise.reject(
            new Error('Locale not supported')
        )

        if (i18n.global.locale.value === locale) return Promise.resolve(locale)

        return Trans.loadLocaleFile(locale).then(msgs => {
            i18n.global.setLocaleMessage(locale, msgs.default || msgs)
            return Trans.setI18nLocaleInServices(locale)
        })
    },
    isLocaleSupported(locale: string) {
        return Trans.supportedLocales.hasOwnProperty(locale)
    },
    loadLocaleFile(locale: string) {
        return import(
            /* webpackInclude: /\.json$/ */
            /* webpackExclude: /\.noimport\.json$/ */
            /* webpackChunkName: "locale-[request]" */ 
            `@/locales/${locale}`
        )
    },
    setI18nLocaleInServices(locale: string) {
        Trans.currentLocale = locale
        document.querySelector('html')?.setAttribute('lang', locale)
        return locale
    }
}

export default Trans