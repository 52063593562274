<template>
    <svg class="search-icon" width="30px" height="31px" viewBox="0 0 30 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="search-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M25.9677576,24.0555471 L29.5032915,27.591081 C30.089078,28.1768674 30.089078,29.1266149 29.5032915,29.7124013 C28.9175051,30.2981878 27.9677576,30.2981878 27.3819712,29.7124013 L23.8464373,26.1768674 C23.2606509,25.591081 23.2606509,24.6413335 23.8464373,24.0555471 C24.4322237,23.4697606 25.3819712,23.4697606 25.9677576,24.0555471 Z M13.418944,0.586557821 C20.5986457,0.586557821 26.418944,6.40685607 26.418944,13.5865578 C26.418944,20.7662596 20.5986457,26.5865578 13.418944,26.5865578 C6.23924223,26.5865578 0.418943974,20.7662596 0.418943974,13.5865578 C0.418943974,6.40685607 6.23924223,0.586557821 13.418944,0.586557821 Z M13.418944,2.58655782 C7.34381173,2.58655782 2.41894397,7.51142557 2.41894397,13.5865578 C2.41894397,19.6616901 7.34381173,24.5865578 13.418944,24.5865578 C19.4940762,24.5865578 24.418944,19.6616901 24.418944,13.5865578 C24.418944,7.51142557 19.4940762,2.58655782 13.418944,2.58655782 Z M13.418944,3.58655782 C18.9417915,3.58655782 23.418944,8.06371032 23.418944,13.5865578 C23.418944,19.1094053 18.9417915,23.5865578 13.418944,23.5865578 C7.89609648,23.5865578 3.41894397,19.1094053 3.41894397,13.5865578 C3.41894397,8.06371032 7.89609648,3.58655782 13.418944,3.58655782 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>

    <!-- <svg class="search-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="search-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M14.418944,0.586557821 C22.1509305,0.586557821 28.418944,6.85457132 28.418944,14.5865578 C28.418944,17.9227549 27.251997,20.9863969 25.3040151,23.3915716 L30.9175051,29.0052945 C31.5032915,29.591081 31.5032915,30.5408284 30.9175051,31.1266149 C30.3317187,31.7124013 29.3819712,31.7124013 28.7961848,31.1266149 L23.1780844,25.5086235 C20.7798847,27.434376 17.7339676,28.5865578 14.418944,28.5865578 C6.68695748,28.5865578 0.418943974,22.3185443 0.418943974,14.5865578 C0.418943974,6.85457132 6.68695748,0.586557821 14.418944,0.586557821 Z M14.418944,3.58655782 C8.34381173,3.58655782 3.41894397,8.51142557 3.41894397,14.5865578 C3.41894397,20.6616901 8.34381173,25.5865578 14.418944,25.5865578 C20.4940762,25.5865578 25.418944,20.6616901 25.418944,14.5865578 C25.418944,8.51142557 20.4940762,3.58655782 14.418944,3.58655782 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg> -->
</template>

<script>
export default {
    name: 'searchIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        height: 24px;
        width: 25px;
    }
</style>