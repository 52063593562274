type VueAPI = {
    _thisSW: ServiceWorkerRegistration | null,
    _mounted: boolean,
    _beforeMounted: boolean,
    _mountedCallbacks: Function[],
    _beforeMountedCallbacks: Function[],
    _swCallbacks: Function[],
    _swEventsList: {
        [key: string]: Function[]
    },
    onMounted: (callback: () => void) => void,
    onBeforeMounted: (callback: () => void) => void,
    asyncOnMounted: () => Promise<void>,
    asyncOnBeforeMounted: () => Promise<void>,
    onServiceWorkerInstalled: (callback: (instance: ServiceWorkerRegistration) => void) => void,
    onSWEvent: (eventName: string, callback: Function) => void,
    _swEventCallback: (eventName: string, ...args: any[]) => void,
    _mountedCallback: () => void,
    _beforeMountedCallback: () => void,
    _swCallback: (thisSW: ServiceWorkerRegistration) => void
}

const vueAPI: VueAPI = {
    _thisSW: null,
    _mounted: false,
    _beforeMounted: false,
    _mountedCallbacks: [],
    _beforeMountedCallbacks: [],
    _swCallbacks: [],
    _swEventsList: {},
    onMounted(callback) {
        if (typeof callback === 'function') {
            if (this._mounted) {
                callback();
            } else {
                this._mountedCallbacks.push(callback);
            }
        }
    },
    onBeforeMounted(callback) {
        if (typeof callback === 'function') {
            if (this._beforeMounted) {
                callback();
            } else {
                this._beforeMountedCallbacks.unshift(callback);
            }
        }
    },
    asyncOnMounted() {
        return new Promise((resolve) => {
            this.onMounted(resolve);
        });
    },
    asyncOnBeforeMounted() {
        return new Promise((resolve) => {
            this.onBeforeMounted(resolve);
        });
    },
    onServiceWorkerInstalled(callback) {
        if (typeof callback === 'function') {
            if (this._thisSW) {
                callback(this._thisSW);
            } else {
                this._swCallbacks.push(callback);
            }
        }
    },
    onSWEvent(eventName, callback) {
        if (typeof callback === 'function') {
            if (!this._swEventsList.hasOwnProperty(eventName)) {
                this._swEventsList[eventName] = [];
            }

            this._swEventsList[eventName].push(callback);
        }
    },
    _swEventCallback(eventName, ...args) {
        if (this._swEventsList.hasOwnProperty(eventName)) {
            this._swEventsList[eventName].forEach(callback => callback(...args));
        }
    },
    _mountedCallback() {
        this._mounted = true;
        this._mountedCallbacks.forEach(callback => callback());
        this._mountedCallbacks = [];
    },
    _beforeMountedCallback() {
        this._beforeMounted = true;
        this._beforeMountedCallbacks.forEach(callback => callback());
        this._beforeMountedCallbacks = [];
    },
    _swCallback(thisSW) {
        this._thisSW = thisSW;
        this._swCallbacks.forEach(callback => callback(thisSW));
        this._swCallbacks = [];
    }
}

export default vueAPI;