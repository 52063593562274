<template>
    <svg class="new-message-icon" width="30px" height="29px" viewBox="0 0 30 29" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>{{ $t('messages_block.new_message') }}</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path d="M21.847,2.80842767 L8.76706934,15.7014991 C8.49765353,15.9670776 8.28139249,16.2807229 8.12904499,16.6255219 L8.04539218,16.8360043 L6.11273563,22.2902556 C6.04096792,22.492795 6.03634922,22.7130648 6.09956374,22.9184346 C6.25043343,23.4085763 6.74373496,23.6989755 7.23605908,23.6078737 L7.34949869,23.5799953 L12.5780055,21.9706176 C12.9808482,21.8466193 13.3522705,21.6393712 13.6685923,21.3631728 L13.8220518,21.2193939 L27,7.97442767 L27,23.8086415 C27,26.5700653 24.7614237,28.8086415 22,28.8086415 L5,28.8086415 C2.23857625,28.8086415 3.38176876e-16,26.5700653 0,23.8086415 L0,7.8086415 C-3.38176876e-16,5.04721775 2.23857625,2.8086415 5,2.8086415 L21.847,2.80842767 Z M23.1886877,3.331 L26.4466877,6.59 L12.8481553,20.1005349 C12.5500124,20.3967068 12.1929703,20.6269353 11.8001645,20.7763025 L8.13840152,22.1687129 C7.88029018,22.2668615 7.59148444,22.1371861 7.49333583,21.8790747 C7.44816655,21.7602885 7.44992411,21.6287597 7.49825127,21.5112228 L9.19760695,17.3782055 C9.35238921,17.0017583 9.58215655,16.6607449 9.87292399,16.3759215 L23.1886877,3.331 Z M28.8211658,0.585786601 L29.2319701,0.996590883 C30.0130187,1.77763947 30.0130187,3.04396942 29.2319701,3.82501801 L29.2272722,3.82970039 L27.4906877,5.553 L24.2396877,2.302 L26.0074177,0.571258423 C26.7907343,-0.196045241 28.0458143,-0.189564908 28.8211658,0.585786601 Z" id="Combined-Shape" fill="#838387"></path>
    </g>
</svg>
</template>

<script>
export default {
    name: 'newMessageIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>