<template>
    <div class="opened_post_view_wrapper" @click="closePost()">
        <div class="post_card_wrapper" @click.stop>
            <div class="close_button" @click="closePost()">
                <close-Icon-SVG></close-Icon-SVG>
            </div>
            <div class="post_content_wrapper">
                <div class="author_media_wrapper" @dblclick="addKissToPost()">
                    <div class="animation_kiss_wrapper" ref="kissingAnimElm" :class="{
                        'kissed': kissedAnimaton
                    }">
                        <div class="kisses-icon">
                            <kiss-Animation-Icon-SVG></kiss-Animation-Icon-SVG>
                        </div>
                    </div>
                    <div class="white_frame"></div>
                    <template v-if="postMediaCount > 1">
                        <div class="media_scroller" :style="{
                            'transform': `translate3d(-${mediaScrollerTranslateX}px, 0px, 0px)`,
                            'transition': (isSwiping || !imagesSet) ? 'none' : ''
                        }">
                            <div class="post_media" ref="postMedia" v-for="(thisMedia, thisMediaIdx) in postData.media" :key="thisMedia.id">
                                <div class="media_bg" :style="{
                                    'background-image': `url(${thisMedia.url})`
                                }"></div>
                                <img v-if="thisMediaIdx === 0" draggable="false" :src="thisMedia.url" alt="" @load="updateScrollerTranslateX">
                                <img v-else draggable="false" :src="thisMedia.url" alt="">
                            </div>
                        </div>
                        <div class="slide_buttons_wrapper">
                            <div class="botton_previus" attr="prev" v-if="(selectedPostMediaIdx - 1) >= 0"
                            @click.stop="changePostMedia(selectedPostMediaIdx - 1)"></div>
                            <div class="botton_next" attr="next" v-if="(selectedPostMediaIdx + 1) <= (postMediaCount - 1)"
                            @click.stop="changePostMedia(selectedPostMediaIdx + 1)"></div>
                        </div>
                        <div class="elemnts_quantaty_slider">
                            <span>{{ (selectedPostMediaIdx + 1) }}</span>
                            <span>/</span>
                            <span>{{ postMediaCount }}</span>
                        </div>
                    </template>
                    <template v-else>
                        <div class="post_media" ref="postMedia" v-for="thisMedia in postData.media" :key="thisMedia.id">
                            <div class="media_bg" :style="{
                                'background-image': `url(${thisMedia.url})`
                            }"></div>
                            <img draggable="false" :src="thisMedia.url" alt="" @load="updateScrollerTranslateX">
                        </div>
                    </template>
                </div>
                <div class="action_buttons-wrapper noselect">
                    <div class="post_kisses_count">
                        <div class="kisses-icon" :class="{
                            'kissed': postData.userInteraction.isKissed
                        }"
                        @click="addKissToPost()">
                            <kiss-icon-SVG></kiss-icon-SVG>
                            <div class="kissed_kiss-icon">
                                <kissedKissIconSVG/>
                            </div>
                        </div>
                        <!-- <span><number-formating :number="postData.kissesCount" /></span> -->
                    </div>
                    <div class="share-icon">
                        <share-Icon-SVG></share-Icon-SVG>
                    </div>
                </div>
                <div class="kisses_mount_count">
                    <span><number-formating :number="postData.kissesCount" /></span>
                    <small>&#xB7; Kisses</small>
                </div>
                <div class="autor_post_description">
                    <div class="description">
                        <div class="small_description_wrapper" v-if="!moreDescriptionOpened && descriptionLength > 100">
                            <span>
                                {{ cutedDescription }}
                            </span>
                            <div class="more_info" @click="openMoreDescription()">
                                <span>...</span>
                                <span>{{ $t('header.more') }}</span>
                            </div>
                        </div>
                        <span class="full_description" v-else-if="moreDescriptionOpened || descriptionLength <= 100">
                            <formated-text-handler :formatedText="formatedDescription" v-once />
                        </span>
                    </div>
                </div>
            </div>
            <!-- <div class="line"></div> -->
            <div class="comments_wrapper">
                <div class="author_header">
                    <div class="author_wrapper">
                        <!-- <div class="avtar_oreon" @click="goToUserPage()">
                            <oreon-Stroke-SVG></oreon-Stroke-SVG>
                            <div class="user_avatar" :class="{
                                'no-avatar': !postData.user.avatar
                            }">
                                <i class="uis uis-camera-plus"></i>
                                <img draggable="false" v-if="postData.user.avatar" :src="postData.user.avatar" alt="user-avatar">
                            </div>
                        </div> -->
                        <userAvatarCircle :src="postData.user.avatar" @click="goToUserPage()" />
                        <div class="author_name" @click="goToUserPage()">
                            <span>{{ postData.user.name }}</span>
                            <div class="status_icon" data-v-724e2798="">
                                <status-Icon-SVG draggable="false" alt="Проверенный" title="Проверенный" data-v-724e2798=""></status-Icon-SVG>
                                <!-- <img draggable="false" src="/img/status-pp.d05eaa03.svg" alt="Проверенный" title="Проверенный" > -->
                            </div>
                        </div>
                    </div>
                    <div class="more_action_button" @click="togglePostOptions()">
                        <more-Vertical-Icon-SVG></more-Vertical-Icon-SVG>
                    </div>
                </div>
                <div class="users_coments_wrapper">
                    <div class="user_commenst_popup">
                        <div class="comments_wrapper_scroller noScroll">
                            <userPostViewComment v-for="thisComment in commentsList" @closePost="closePost()" :key="thisComment.id" :comment="thisComment" />
                        </div>
                    </div>
                    <div class="comments"></div>
                </div>
                <div class="add_comment_section">
                    <customTextInput v-model="commentField" :placeholder="`${$t('post.add_comment')}...`"
                    @submitData="commentPost()">
                        {{ $t('post.post_comment') }}
                    </customTextInput>
                </div>
            </div>
        </div>
        <Teleport to="body" v-if="postOptionsOpened">
            <post-options-menu @close="togglePostOptions(false)" />
        </Teleport>
    </div>
</template>

<script>
import emojiPopupVue from '@/components/emojiPopup.vue';
import userPostViewCommentVue from '@/components/post/userPostViewComment.vue';

export default {
    name: 'userPostView',
    components: {
        emojiPopup: emojiPopupVue,
        userPostViewComment: userPostViewCommentVue
    },
    emits: ['closePost'],
    mounted() {
        this.formatedDescription = this.formatText(this.postData.description)
    },
    data() {
        return {
            moreDescriptionOpened: false,
            emojiOpened: false,
            formatedDescription: '',
            commentField: '',
        }
    },
    computed: {
        postMediaCount() {
            return this.postData.media.length;
        },
        descriptionLength() {
            return this.postData.description.length;
        },
        cutedDescription() {
            return this.postData.description.slice(0, 100);
        },
        isMobileUser() {
            return device.mobile() || device.tablet();
        },
    },
    methods: {
        openMoreDescription() {
            this.moreDescriptionOpened = true;
        },
        closeMoreDescription() {
            this.moreDescriptionOpened = false;
        },
        addKissToPost() {
            if (this.postData.userInteraction.isKissed) return
            this.postData.userInteraction.isKissed = true;
            this.postData.kissesCount++;
        },
        selectedUserPost() {
            this.$emit('selectedUserPost', this.postData);
        },
        goToUserPage() {
            this.$router.push(`/${this.postData.user.name}`)
            this.closePost()
        },
        commentPost() {
            this.postData.commentCount++;
            this.commentField = '';
        },
        changePostMedia(mediaIdx) {
            this.postData.media.forEach(media => {
                media.selected = false;
            })
            this.postData.media[mediaIdx].selected = true;
        },
        closePost() {
            this.$emit('closePost');
        }
    }
}
</script>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted, onBeforeMount } from 'vue';
import { useGlobalData } from '@/store/globalData';
import { useSwipe } from '@vueuse/core';

import postOptionsMenu from './postOptionsMenu.vue';
import kissedKissIconSVG from '@/components/svg/kissed-kiss-icon.vue';
import customTextInput from '@/components/customTextInput.vue';
import userAvatarCircle from '@/components/userAvatarCircle.vue';

const globalData = useGlobalData();

const props = defineProps({
    postData: {
        type: Object,
        required: true
    }
});

const mediaScrollerTranslateX = ref(0);
const slideElm = ref(null);
const postMedia = ref(null);
const postMediaPhotos = ref(null);

let mediaScrollerTranslateXValue = 0;

const postMediaOffsetWidth = computed(() => postMedia.value[0].offsetWidth);
const postMediaMaxWidth = computed(() => postMediaOffsetWidth.value * postMedia.value.length);
const selectedPostMediaIdx = computed(() => props.postData.media.findIndex(media => media.selected));

const imagesSet = ref(false);

onMounted(async () => {
    mediaScrollerTranslateX.value = postMedia.value[selectedPostMediaIdx.value].offsetWidth * selectedPostMediaIdx.value;
    watch(() => globalData.getScreenWidth, (newVal) => {
        mediaScrollerTranslateX.value = postMedia.value[selectedPostMediaIdx.value].offsetWidth * selectedPostMediaIdx.value;
    })
    
    watch(selectedPostMediaIdx, (newValue) => {
        mediaScrollerTranslateX.value = postMedia.value[newValue].offsetWidth * newValue
    })
    
    setTimeout(() => {
        imagesSet.value = true;
    }, 200);

    document.body.style.overflow = 'hidden';
});
onUnmounted(() => {
    document.body.style.overflow = '';
});

function updateScrollerTranslateX() {
    mediaScrollerTranslateX.value = postMedia.value[selectedPostMediaIdx.value].offsetWidth * selectedPostMediaIdx.value;
}

const { isSwiping, direction, lengthX } = useSwipe(slideElm, {
    passive: true,
    threshold: 0,
    onSwipeStart: (ev) => {
        mediaScrollerTranslateXValue = mediaScrollerTranslateX.value;
    },
    onSwipe: (ev) => {
        mediaScrollerTranslateX.value = mediaScrollerTranslateXValue + lengthX.value;
        if (mediaScrollerTranslateX.value > (postMediaMaxWidth.value - postMediaOffsetWidth.value)) {
            mediaScrollerTranslateX.value = (postMediaMaxWidth.value - postMediaOffsetWidth.value);
        }
    },
    onSwipeEnd: (ev, swipeDirection) => {
        if (swipeDirection == 'left') {
            let selecedMedia = props.postData.media[props.postData.media.findIndex(media => media.selected) + 1]
            if (!selecedMedia) return
            props.postData.media.forEach(media => {
                media.selected = false;
            })
            selecedMedia.selected = true;
        } else if (swipeDirection == 'right') {
            let selecedMedia = props.postData.media[props.postData.media.findIndex(media => media.selected) - 1]
            if (!selecedMedia) return
            props.postData.media.forEach(media => {
                media.selected = false;
            })
            selecedMedia.selected = true;
        } else {
            mediaScrollerTranslateX.value = mediaScrollerTranslateXValue;
            mediaScrollerTranslateXValue = 0;
        }
    },
})

const kissedAnimaton = ref(false);
const kissingAnimElm = ref(null);
watch(() => props.postData.userInteraction.isKissed, (newValue) => {
    kissedAnimaton.value = newValue;
    if (kissingAnimElm.value) {
        kissingAnimElm.value.addEventListener('animationend', (event) => {
            kissedAnimaton.value = false;
        }, { once: true })
    }
})

const postOptionsOpened = ref(false);
function togglePostOptions(value = null) {
    if (value != null) postOptionsOpened.value = value;
    else postOptionsOpened.value = !postOptionsOpened.value;
}


const commentsList = ref([]);

commentsList.value.push({
    id: 1,
    post_id: "5326bdjgf389hgdf9hgf",
    reply_id: null,
    replies_count: 0,
    replies: [],
    likes: 0,
    liked: false,
    user: {
        id: 1,
        name: "guest_test3yhe98u439g",
        avatar: "https://i.pravatar.cc/150"
    },
    text: 'test comment',
    created: new Date().getTime()
})
commentsList.value.push({
    id: 53332,
    post_id: "5326bdjgf389hgdf9hgf",
    reply_id: null,
    replies_count: 5,
    replies: [],
    likes: 5,
    liked: true,
    user: {
        id: 3,
        name: "guest_6433222",
        avatar: "https://i.pravatar.cc/150",
        verified: true
    },
    text: 'Yes you are so georgius and your room looks amazing! 💋',
    created: new Date().getTime()
})
commentsList.value.push({
    id: 522,
    post_id: "5326bdjgf389hgdf9hgf",
    reply_id: null,
    replies_count: 0,
    replies: [],
    likes: 52,
    liked: true,
    user: {
        id: 2,
        name: "guest_6322",
        avatar: "https://i.pravatar.cc/150",
        verified: false
    },
    text: 'Yes you are so dump! 💋',
    created: new Date().getTime()
})

commentsList.value[1].replies.push({
    id: 53352332,
    post_id: "5326bdjgf389hgdf9hgf",
    reply_id: 53332,
    likes: 5,
    liked: false,
    user: {
        id: 1,
        name: "guest_325322",
        avatar: "https://i.pravatar.cc/150",
        verified: true
    },
    text: 'I\'m replying to a comment',
    created: new Date().getTime()
})

commentsList.value[1].replies.push({
    id: 533532332,
    post_id: "5326bdjgf389hgdf9hgf",
    reply_id: 53332,
    likes: 2,
    liked: true,
    user: {
        id: 1,
        name: "guest_325322",
        avatar: "https://i.pravatar.cc/150",
        verified: true
    },
    text: 'I\'m replying to a comment one more time ;)',
    created: new Date().getTime()
})

</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/components/userPostView.scss';
</style>