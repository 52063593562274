import { Socket } from 'socket.io-client'

type SocketIoAPI = {
    socket: Socket | null,
    _allCallbacks: Function[],
    onNewConnection: (callback: (instance: Socket) => void) => void,
    _callAllCallbacks: (socketVar: Socket) => void
}

let socketIoAPI: SocketIoAPI = {
    socket: null,
    _allCallbacks: [],
    onNewConnection(callback) {
        if (this.socket) return callback(this.socket)
        if (typeof callback !== 'function') return
        this._allCallbacks.push(callback)
    },
    _callAllCallbacks(socketVar) {
        this._allCallbacks.forEach(callback => {
            if (typeof callback === 'function') callback(socketVar)
        })
    }
}


export default socketIoAPI