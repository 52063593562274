<template>
    <svg class="icon-share" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="share-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 Z M18.3199469,8.87921234 L18.2288715,8.88309191 C17.6973637,8.93413089 17.3728134,9.09391776 17.3659141,9.92242935 L17.3659141,11.5217523 L17.1990751,11.5630019 C12.0530547,12.0977829 7.79770708,14.8741683 7.37921222,22.2290895 C7.32640687,23.1571278 7.85716425,23.3419464 8.33121596,22.5458291 L8.44069862,22.3681813 C9.66785636,20.4436346 11.2327068,17.1790132 17.2788693,16.9447543 L17.3546212,16.9637362 L17.3540196,18.5418308 C17.3563657,18.8097605 17.377763,19.1290304 17.5100856,19.277177 C17.943445,19.76236 18.5301337,19.3536644 18.966671,19.0465546 L24.3143279,14.8741683 C24.6763464,14.4551254 24.6905027,13.9982439 24.3143279,13.5949704 L18.966671,9.17345272 C18.7927311,8.98698266 18.5613405,8.88170674 18.3199469,8.87921234 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'shareIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
        svg {
            height: 24px;
            width: 24px; 
        }

    #share-icon {
        path {
            fill: var(--dpurple);
        }
    }
</style>