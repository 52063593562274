<template>
    <form @submit.prevent="submitData">
        <div class="emoji_wrapper noselect" v-if="!isMobileUser">
            <emojiPopup v-if="emojiOpened" :autoClose="true" @closePopup="emojiClosePopup" @emojiClick="emojiClicked" />
            <emojiIconSVG @click="emojiOpened = !emojiOpened"/>
        </div>
        <div class="tips_icon_wrapper tips_ok noselect" v-if="tipsEnabled" @click="openTipsModal()" >
            <div class="additional_info_box">
                <span>100€</span>
            </div>
            <euroCoinIconSVG/>
        </div>
        <div class="comment_texarea">
            <textarea row="5" :placeholder="placeholder" 
            :value="modelValue"
            @input="messageFieldChanged($event as CustomInputEvent)"
            @keydown.tab.prevent="messageTabPressed"
            @keydown.enter="messageEnterPressed($event)"
            @focus="focusHandler($event, true)"
            @focusout="focusHandler($event, false)"
            ref="messageTextarea"></textarea>
        </div>
        <button class="noselect" type="submit" :title="$t('messages_block.send')" :disabled="messageFieldIsEmpty || disabled">
            <slot name="icon"></slot>
            <span>
                <slot></slot>
            </span>
        </button>
    </form>
</template>

<script setup lang="ts">
import { ref, computed, nextTick } from 'vue';
import emojiPopup from '@/components/emojiPopup.vue';
import emojiIconSVG from '@/components/svg/emoji-icon.vue';
import euroCoinIconSVG from '@/components/svg/euro-coin-icon.vue';

type ComponentEmits = {
    (e: 'submitData'): void,
    (e: 'update:modelValue', value: string | number): void,
    (e: 'tipsToggle', value?: boolean): void
}

const emit = defineEmits<ComponentEmits>()

type ComponentProps = {
    disabled?: boolean,
    tipsEnabled?: boolean,
    placeholder?: string,
    modelValue?: string,
    tips?: number
}

const props = withDefaults(defineProps<ComponentProps>(), {
    disabled: false,
    tipsEnabled: false,
    placeholder: '',
    modelValue: '',
    tips: 0
})

function submitData() {
    if (props.disabled) return
    if (messageFieldIsEmpty.value) return
    emit('submitData')
    setTimeout(() => {
        messageTextarea.value?.dispatchEvent(new Event('input'))
    }, 200);
}


const messageTextarea = ref<HTMLInputElement | null>(null);
const emojiOpened = ref(false)
const tipsOpened = ref(false)
const texareaFocused = ref(false)

function openTipsModal() {
    tipsOpened.value = true;
    emit('tipsToggle', tipsOpened.value)
}
function closeTipsModal() {
    tipsOpened.value = false;
    emit('tipsToggle', tipsOpened.value)
}

function emojiClosePopup() {
    if (!messageTextarea.value) return;
    emojiOpened.value = false;
    messageTextarea.value.focus();
}
function emojiClicked(selectedEmoji: string) {
    emit('update:modelValue', props.modelValue + selectedEmoji)
    nextTick(() => {
        if (!messageTextarea.value) return;
        messageTextarea.value.focus();
        messageTextarea.value.setSelectionRange(messageTextarea.value.value.length, messageTextarea.value.value.length);
        messageTextarea.value.dispatchEvent(new CustomEvent('input', {
            detail: {
                emojiEvent: true
            }
        }))
    })
}

const messageFieldIsEmpty = computed(() => {
    return props.modelValue.trim().length == 0;
})

type CustomInputEvent = InputEvent & {
    detail: {
        emojiEvent: boolean
    }
}

function messageFieldChanged(event: CustomInputEvent) {
    const target = event.target as HTMLTextAreaElement;
    if (props.disabled) return target.value = props.modelValue;
    emit('update:modelValue', target.value)
    if (!messageFieldIsEmpty.value && emojiOpened.value) {
        if (!event.detail || !event.detail.emojiEvent) emojiOpened.value = false;
    }

    let $target = event.target as HTMLTextAreaElement;
    $target.style.height = '0';
    let scrollHeight = `${$target.scrollHeight}px`;
    if (scrollHeight != $target.style.height) {
        $target.style.height = scrollHeight;
    }
}
function messageTabPressed() {
    if (emojiOpened.value) {
        emojiOpened.value = false;
        nextTick(() => {
            messageTextarea.value?.focus();
        })
    } else {
        emojiOpened.value = true
    }
}
function messageEnterPressed(event: KeyboardEvent) {
    if (event.shiftKey) {
        event.preventDefault();
        submitData();
    }
}

const isMobileUser = computed(() => {
    return device.mobile() || device.tablet();
})

function focusHandler(event: FocusEvent, isFocused: boolean) {
    texareaFocused.value = isFocused;
}

defineExpose({
    messageTextarea,
    texareaFocused
})
</script>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .emoji_wrapper {
        position: relative;
        cursor: pointer;
        height: 24px;
        width: 24px;

        .emoji-icon {
            height: 24px;
            width: 24px;

            :deep(path) {
                fill: var(--grey);
            }
        }
    }

    .tips_icon_wrapper {
        position: relative;
        .additional_info_box {
            position: absolute;
            top: 0;
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--100);
            border-radius: .4rem;
            padding: .25rem .75rem;
    
            span {
                font-size: .85rem;
            }
        }
    }

    .comment_texarea {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        flex-shrink: 1;
        margin-right: 1rem;

        textarea {
            margin-left: 1rem;
            background: none;
            border: none;
            color: var(--dpurple);
            display: flex;
            flex-grow: 1;
            font-size: inherit;
            height: 23px;
            line-height: 1.5;
            max-height: 180px;
            outline: none;
            padding: 0;
            resize: none;
            width: 0;

            @media(max-width: 507px) {
                margin-left: 0;
            }

            &::placeholder {
                color: var(--grey);
                transition: opacity 550ms ease;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: inherit;
                line-height: inherit;
            }

            &:focus {
                &::placeholder {
                    opacity: 0;
                }
            }
        }
    }

    .tips_icon_wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 33px;
        width: 33px;
        cursor: pointer;

        @media(max-width: 768px) {
            height: 31px;
            width: 31px;
        }

        @media(hover) {
            margin-left: 1rem;
        }
    }

    button {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        display: flex;
        flex-direction: row;
        flex-basis: 128px;
        flex-grow: 1;
        flex-shrink: 1;
        align-items: center;
        justify-content: center;
        padding: 0.45rem 0.7rem;
        margin-left: auto;
        border-radius: 0.3rem;
        border: 1px solid transparent;
        background-color: var(--lpurple);
        background-size: 125%;
        cursor: pointer;
        min-width: 0;
        opacity: 1;
        transition: opacity 200ms linear;
        
        &:disabled {
            opacity: .5;
            pointer-events: none;
        }

        &.pressed {
            background-color: var(--white);
            border: 1px solid var(--lpurple);

            :deep(.message_icon) {
                path {
                    fill: var(--lpurple);
                }
            }

            span {
                color: var(--lpurple);
            }
        }
        
        &:active {
            background-color: var(--white);
            border: 1px solid var(--lpurple);

            :deep(.message_icon) {
                path {
                    fill: var(--lpurple);
                }
            }

            span {
                color: var(--lpurple);
            }
        }

        :deep(.message_icon) {
            height: 23px;
            width: 16px;

            path {
                fill: var(--white);
            }
        }

        @media(max-width: 640px) {
            flex-basis: 112px;
        }

        span {
            font-size: 0.9rem;
            font-weight: 800;
            letter-spacing: 0.3px;
            text-transform: capitalize;
            color: var(--white);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
</style>