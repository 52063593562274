<template>
    <div class="selector_wrapper">
        <div class="true_language">
            <template v-for="(thisLocale, thisLocaleKey) in locales" :key="thisLocaleKey">
                <span v-if="thisLocaleKey == $i18n.locale">{{ thisLocale.nativeName }}</span>
            </template>
            <i class="uil uil-angle-down"></i>
        </div>
        <select @change="switchLocaleEvent">
            <option v-for="(thisLocale, thisLocaleKey) in locales" :key="thisLocaleKey"
            :value="thisLocaleKey"
            :selected="thisLocaleKey == $i18n.locale">{{ thisLocale.nativeName }}</option>
        </select>
    </div>
</template>

<script>
import Trans from '@/plugins/Translation'

export default {
    data() {
        return {
            locales: Trans.supportedLocales,
        }
    },
    methods: {
        switchLocaleEvent($event) {
            if (!$event.target || !$event.target.value) return;

            this.switchLocale($event.target.value);
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                Trans.changeLocale(locale).then(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                }).catch((err) => {
                    console.warn(err)
                })
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    @import 'src/styles/scss/localesSwitcher.scss';
</style>