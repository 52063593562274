<template>
    <svg class="kiss_icon" width="39px" height="27px" viewBox="0 0 39 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Feed-loged-in" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M37.0146804,12.5711703 C37.7291403,12.3270054 37.5260378,12.5438337 37.279782,12.7480333 L37.1925002,12.8188081 C37.1206452,12.8760648 37.0539562,12.927409 37.0146804,12.9607903 C35.0982628,14.5895972 34.3072327,16.3896267 33.5630826,18.1578738 L33.3693107,18.6183012 C32.3016141,21.1452184 31.1373623,23.5703687 26.691027,25.2941476 C21.7789676,26.6947703 18.3402522,26.5422478 13.4735981,25.2941476 C7.8593154,22.9166913 7.01948775,20.3995379 6.05961445,17.9155624 L5.9141213,17.5432365 C5.32365282,16.0551012 4.6018669,14.5820216 2.69155137,13.1613389 C2.64169905,13.1242642 2.57934015,13.0838685 2.5170474,13.0436259 L2.42522947,12.9838111 C2.2036666,12.8367328 2.07143578,12.7162367 2.69155137,12.8055352 C3.33500404,12.8981943 3.65946491,12.9607903 4.21003163,13.0400328 C7.31438678,13.4868394 11.1824718,12.9157294 14.9300805,13.8313344 C17.2563415,14.3996798 17.6775269,15.0070521 19.8742964,15.0070521 C22.0710658,15.0070521 22.3992248,14.5307881 24.7911046,13.8313344 C27.4970706,13.0400328 30.0051271,13.8313344 35.5393944,12.9607903 C35.967323,12.8934769 36.5818459,12.7190904 37.0146804,12.5711703 Z M16.5654993,0.561765851 C20.4983601,2.24066955 20.0151938,2.24066955 23.7917155,0.561765851 C25.0643554,0.11406102 26.3502905,0.0481539467 27.6564059,0.561765851 C34.3043346,3.55894124 33.5748568,9.26520501 38.5206334,11.6850459 C38.6062599,11.7269407 38.8120183,11.828748 38.5391943,11.9177809 C34.195018,13.3354517 30.610837,13.1589101 23.7917155,12.7955521 C21.1879478,13.5194017 18.5033081,13.5194017 15.7915567,12.7955521 C9.85908804,13.0273868 5.66629155,13.1589101 1.02883654,12.3549066 C0.657182388,12.2904723 0.96260896,12.1420639 1.06262713,12.0886624 C5.63627087,9.64671178 5.48537374,4.07430788 12.2658953,0.561765851 C13.701423,-0.109172617 15.2740936,0.107153752 16.5654993,0.561765851 Z" id="Combined-Shape-Copy-4" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'kissIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .kiss_icon {
        height: 24px;
        width: 29px;

        @media(max-width: 430px) {
            height: 23px;
            width: 28px;
        }
        
        path {
            fill: #838387;
        }
    }

    .post_kisses_count {
        .kisses-icon {
            .kiss_icon {
                height: 32px;
                width: 34px;
                #Feed-loged-in {
                    path {
                        fill: var(--dpurple);
                    }
                }
            }
        }
    }

    .kisses-icon {
        &.active {
            .kiss_icon {
                #Feed-loged-in {
                    path {
                        fill: var(--dpurple);
                    }
                }
            }
        }

        // &.kissed{
        //     .kiss_icon {
        //         #Feed-loged-in {
        //             path {
        //                 fill: var(--700);
        //             }
        //         }
        //     }
        // }
    }
</style>