import { defineStore as definePiniaStore } from 'pinia'
import io from 'socket.io-client'
import axios from 'axios'
import vueAPI from "@/modules/vueAPI"
import socketIoAPI from '@/modules/socketAPI'
import { useLoginData } from './loginData'

export const useSocketIOData = definePiniaStore('socketIOData', {
    state: () => ({
        hasBeenReconnected: false,
        floodLimit: false
    }),
    getters: {
        connectionFloodLimit: (state) => state.floodLimit,
    }
})

vueAPI.onMounted(function() {
    configureNewConnection()
})

async function configureNewConnection() {
    const loginData = useLoginData()
    const socketIOData = useSocketIOData()

    if (socketIoAPI.socket) {
        socketIoAPI.socket.offAny()
        socketIoAPI.socket.disconnect()
    }

    const socketServer = await getSocketServer()
    if (!socketServer || socketServer.response !== 1) {
        if (socketServer.response !== 1) {
            setTimeout(() => {
                console.log('Reconnecting...')
                configureNewConnection()
            }, 5000)
            console.error('API error!', socketServer.error);
            return console.warn('Reconnect in 5 seconds...');
        }
        return console.error('Error getting socket server!')
    }
    socketIoAPI.socket = io(`wss://${socketServer.host}:${socketServer.port}`, {
        reconnection: false,
    });
    socketIoAPI._callAllCallbacks(socketIoAPI.socket)
    
    socketIoAPI.socket.on('connect_error', (err) => {
        // console.log(`Error: ${err}`)
        vueAPI.onMounted(function() {
            if (loginData.adminLoggedIn) {
                socketIOData.hasBeenReconnected = true
            }
        })
        console.error(`Error connection to server!`)
        console.log('Reconnect in 5 seconds...')
        setTimeout(() => {
            console.log('Reconnecting...')
            configureNewConnection()
        }, 5000)
    })
    
    socketIoAPI.socket.on('disconnect', async () => {
        if (loginData.adminLoggedIn) {
            socketIOData.hasBeenReconnected = true
        }

        if (socketIOData.connectionFloodLimit) {
            console.warn('Flood limit reached! Reconnect in 30 seconds...')
            setTimeout(() => {
                console.log('Reconnecting...')
                configureNewConnection()
            }, 30000)
        } else {
            console.warn('Disconnected from server!');
            console.log('Reconnecting...');
            configureNewConnection()
        }
    })
    
    socketIoAPI.socket.on('ping', (callbackFuction) => {
        let timestampNow = Date.now()
        if (typeof callbackFuction === 'function') {
            callbackFuction(timestampNow)
        }
    })

    socketIoAPI.socket.on('floodLimit', async () => {
        socketIOData.floodLimit = true
        console.warn('Flood limit reached!')
    })
}

async function getSocketServer() {
    const { data } = await axios.get('/api/getConnectionServer.php')
    if (!data) return null
    return data
}