<template>
    <svg class="edit-icon" width="32px" height="31px" viewBox="0 0 32 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M15.6176471,0.00131248302 C16.4460742,0.00131248302 17.1176471,0.672885358 17.1176471,1.50131248 C17.1176471,2.2810086 16.522759,2.92176116 15.7621071,2.9944459 L15.6176471,3.00131248 L5.04429007,3.00131248 C4.26459395,3.00131248 3.62384139,3.59620057 3.55115665,4.35685247 L3.54429007,4.50131248 L3.54429007,26.0087922 C3.54429007,26.7884883 4.13917815,27.4292409 4.89983006,27.5019256 L5.04429007,27.5087922 L26.5235834,27.5087922 C27.3032796,27.5087922 27.9440321,26.9139041 28.0167169,26.1532522 L28.0235834,26.0087922 L28.0235834,15.2550524 C28.0235834,14.4266252 28.6951563,13.7550524 29.5235834,13.7550524 C30.3032796,13.7550524 30.9440321,14.3499404 31.0167169,15.1105923 L31.0235834,15.2550524 L31.0235834,26.0087922 C31.0235834,28.4230656 29.1223469,30.3932813 26.7354191,30.5038942 L26.5235834,30.5087922 L5.04429007,30.5087922 C2.63001673,30.5087922 0.65980098,28.6075557 0.549188058,26.2206279 L0.544290068,26.0087922 L0.544290068,4.50131248 C0.544290068,2.08703915 2.44552658,0.116823394 4.83245436,0.00621047252 L5.04429007,0.00131248302 L15.6176471,0.00131248302 Z M23.3677655,0.786397423 C24.1475249,0.00406178475 25.4138531,0.0019733339 26.1961887,0.781732738 L26.1961887,0.781732738 L30.1555101,4.72801602 C30.1583683,4.73086479 30.1612178,4.7337222 30.1640587,4.73658822 C30.9416601,5.5210689 30.9360832,6.78738657 30.1516026,7.56498792 L30.1516026,7.56498792 L18.0463881,19.5640483 C17.5636091,20.0425937 16.9326209,20.3429935 16.256791,20.4160367 L16.256791,20.4160367 L11.0365198,20.9802399 C10.9581846,20.9887064 10.8791232,20.987885 10.8009807,20.9777929 C10.2532451,20.9070532 9.86656347,20.4056799 9.93730319,19.8579442 L9.93730319,19.8579442 L10.6282392,14.5080463 C10.7126054,13.8548011 11.0096136,13.2474772 11.4734284,12.779799 L11.4734284,12.779799 L23.3642427,0.789940662 C23.3654155,0.788758116 23.3665897,0.787577036 23.3677655,0.786397423 Z" id="Combined-Shape" fill="#838387" fill-rule="nonzero"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'editIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    .back_photo_edit {
        svg {
            height: 16px;
            width: 16px;

            path {
                fill: #fff;
            }
        }
    }
</style>