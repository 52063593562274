import { createApp, markRaw } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'

// @ts-ignore
import VShowSlide from 'vue-show-slide'
// @ts-ignore
import customMobileClick from 'vue-custom-mobile-click'
// @ts-ignore
import customHorizontalSliding from 'vue-custom-horizontal-sliding'

import numberAnimation from '@/plugins/numberAnimation.vue'
import numberFormating from '@/plugins/numberFormating.vue'

import getTranslation from '@/mixins/getTranslation'
import formatedTextHandler from '@/plugins/formatedTextHandler.vue'
import formatText from '@/mixins/formatText'
// @ts-ignore
import mountedCreated from 'vue-mounted-check'

import i18n from './i18n'
import svgComponents from '@/components/svg'
import { Router } from 'vue-router'

declare module 'pinia' { 
    export interface PiniaCustomProperties {
        router: Router
    }
}
const pinia = createPinia()
pinia.use(({ store }) => {
    store.router = markRaw(router)
})

let thisApp = createApp(App)
    .use(pinia)
    .use(i18n)
    .use(router)
    .use(VShowSlide)
    .use(customMobileClick)
    .use(customHorizontalSliding)

thisApp.component('numberAnimation', numberAnimation)
thisApp.component('numberFormating', numberFormating)
thisApp.component('formatedTextHandler', formatedTextHandler)

svgComponents.forEach((svgComponent) => {
    thisApp.component(svgComponent.name, svgComponent)
})

thisApp.mixin(getTranslation)
thisApp.mixin(formatText)
thisApp.mixin(mountedCreated)

thisApp.mount('#app')

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        getTranslation: typeof getTranslation.methods.getTranslation
    }
}

declare global {
    var turnstile: any;
    var device: any;
}