<template>
    {{ formatedNumber }}
</template>

<script>
export default {
    name: 'numberFormating',
    props: {
        number: {
            type: Number,
            required: true
        },
        numberType: {
            type: String,
            default: 'money_short'
        }
    },
    computed: {
        formatedNumber() {
            if (this.numberType == 'money') {
                return Math.round(this.number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } else if (this.numberType == 'money_space') {
                return Math.round(this.number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            } else if (this.numberType == 'money_dot') {
                return Math.round(this.number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else if (this.numberType == 'money_short') {
                const formatter = Intl.NumberFormat('en', {
                    notation: 'compact',
                    compactDisplay: 'short',
                    maximumFractionDigits: 2,
                })

                let n = formatter.format(this.number)
                return n
            } else {
                return this.number
            }
        }
    }
}
</script>