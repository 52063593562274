<template>
    <div class="optionsBackground" @click="closeMenu()">
        <div class="optionsMenuInner" @click.stop>
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

const emit = defineEmits(['close']);

defineExpose({ closeMenu });

const themeColorOriginal = document.querySelector('meta[name="theme-color"]')?.getAttribute('content');
const bodyOverflowOriginal = document.body.style.overflow;
onMounted(() => {
    document.body.style.overflow = 'hidden';
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', '#000000a6');
});
onUnmounted(() => {
    document.body.style.overflow = bodyOverflowOriginal;
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', themeColorOriginal ? themeColorOriginal : '');
});

function closeMenu() {
    emit('close')
}
</script>

<style lang="scss" scoped>
.optionsBackground {
    font-size: 14px;
    z-index: 10000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--elevated-text-color);

    .optionsMenuInner {
        width: 100%;
        max-width: 400px;
        background-color: var(--elevated-background);
        border-radius: 1rem;

        @media(max-width: 507px) {
            width: 92%;
        }

        :slotted(.optionsMenuBody) {
            width: 100%;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 550px) {
                font-size: 14px;
            }

            .optionsMenuBodyItem {
                min-height: 42px;
                padding: 4px 8px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                text-align: center;

                &:not(:first-child) {
                    border-top: 1px solid var(--elevated-separator);
                }

                &:active,
                &.pressed {
                    background-color: rgba(0, 0, 0, .1);
                }
            }
        }
    }
}

.optionsMenuInner {
    animation: bounceIn 0.15s;
}


@keyframes bounceIn {
  0% {
    transform: scale(1.15);
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
</style>