<template>
    <svg class="close-icon" width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="close-icon" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M2.16888617,1.00515313 L6.77045463,5.61466376 L11.3720231,1.00515313 C11.6900522,0.686539391 12.2053721,0.683557958 12.5270666,0.998470509 C12.8447429,1.3094496 12.850172,1.81907549 12.539193,2.1367518 C12.5373658,2.13861827 12.5355296,2.14047584 12.5336844,2.14232444 L7.91845463,6.76566376 L12.5336844,11.3890031 C12.8477412,11.7036371 12.8472732,12.2132917 12.5326392,12.5273485 L12.5270666,12.532857 C12.2053721,12.8477696 11.6900522,12.8447881 11.3720231,12.5261744 L6.77045463,7.91566376 L2.16888617,12.5261744 C1.85085701,12.8447881 1.33553718,12.8477696 1.01384269,12.532857 C0.696166378,12.2218779 0.690737203,11.712252 1.00171629,11.3945757 C1.00354341,11.3927092 1.00537961,11.3908517 1.00722481,11.3890031 L5.62145463,6.76566376 L1.00722481,2.14232444 C0.693168045,1.8276904 0.693636017,1.3180358 1.00827006,1.00397903 L1.01384269,0.998470509 C1.33553718,0.683557958 1.85085701,0.686539391 2.16888617,1.00515313 Z" id="Combined-Shape" fill="#FFFFFF"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'closeIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
    svg {
        &.close-icon {
            position: absolute;
            inset: 0;
            margin: auto;
            width: 13px;
            height: 12px;

            @media(width <= 430px) {
                width: 10px;
                height: 10px;
            }
        }
    }
</style>