<template>
    <svg class="no-photo" width="36px" height="35px" viewBox="0 0 36 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M3.1507576,0.529437252 L34.9705627,32.3492424 C35.5563492,32.9350288 35.5563492,33.8847763 34.9705627,34.4705627 C34.3847763,35.0563492 33.4350288,35.0563492 32.8492424,34.4705627 L31.3649115,32.9866476 C31.2447293,32.9975164 31.1230131,33.0030693 31,33.0030693 L4,33.0030693 C1.790861,33.0030693 2.705415e-16,31.2122083 0,29.0030693 L0,10.5707118 C8.6112427e-15,8.36157276 1.790861,6.57071176 4,6.57071176 L4.949,6.57009742 L1.02943725,2.6507576 C0.443650814,2.06497116 0.443650814,1.11522369 1.02943725,0.529437252 C1.61522369,-0.0563491861 2.56497116,-0.0563491861 3.1507576,0.529437252 Z M17.5882894,1.00306931 C18.561662,1.00872666 19.4339503,1.01069205 20.2239655,1.01109831 L20.9872529,1.01106386 L22.0384871,1.01010006 C22.2604726,1.00986301 22.4750585,1.00965021 22.6829414,1.00954064 C24.4874788,1.00858959 27.2271162,3.09531718 27.2216873,6.57071176 L31,6.57071176 C33.209139,6.57071176 35,8.36157276 35,10.5707118 L35,28.3790974 L25.9917515,19.3696254 C25.9972308,19.2481262 26,19.1259211 26,19.0030693 C26,14.5847913 22.418278,11.0030693 18,11.0030693 C17.8771482,11.0030693 17.7549431,11.0058385 17.6334439,11.0113178 L9.22881467,2.60752823 C10.1462955,1.56784575 11.3423834,1.00081317 12.3274044,1.00502179 C12.8706085,1.0073427 13.4399168,1.00921472 14.0443313,1.01011298 L15.4673031,1.0102694 C16.1294403,1.00941177 16.8334351,1.0071867 17.5882894,1.00306931 Z M10,19.0030693 C10,23.4213473 13.581722,27.0030693 18,27.0030693 C20.051808,27.0030693 21.9232053,26.2306391 23.3392634,24.9607072 L20.5012193,22.1217077 C19.816233,22.6712509 18.9465082,23 18,23 C15.790861,23 14,21.209139 14,19 C14,18.0538949 14.3284691,17.1845079 14.8775903,16.499656 L12.0423622,13.6638059 C10.7724302,15.079864 10,16.9512613 10,19.0030693 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
    export default {
        name: 'nophotoIconSVG',
        props: {
            title: {
                type: String,
                default: ''
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>