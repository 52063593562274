<template>
    <svg class="registr-icon" width="27px" height="32px" viewBox="0 0 27 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ $t('sign-up') }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M20,0 C23.3137085,-6.08718376e-16 26,2.6862915 26,6 L26.0015951,13.0819194 C25.4157202,12.4666739 24.6071875,12.0653941 23.7052162,12.0072992 L23.4778516,12 L22.3021957,12 C20.948656,12 19.692476,12.6839458 18.9563503,13.8078589 L18.8385505,13.9992097 L12,25.8376879 L12,31 C12,31.5128358 12.3860402,31.9355072 12.8833789,31.9932723 L13,32 L6,32 C2.6862915,32 -2.62395403e-14,29.3137085 -2.66453526e-14,26 L-2.66453526e-14,6 C-2.70511648e-14,2.6862915 2.6862915,6.08718376e-16 6,0 L20,0 Z M24.2081707,15.5516316 L25.9682564,16.5678176 C26.9225614,17.1187858 27.2514166,18.337864 26.7036698,19.2940216 L21.0189178,29.2174368 C20.692084,29.7879644 20.1868668,30.2353179 19.5809731,30.4906902 L16,32 L15.4607925,27.6283541 C15.3793556,26.9681008 15.5196884,26.2995068 15.8597219,25.727717 L21.4891654,16.2614197 C22.0486018,15.320689 23.2603007,15.0043786 24.2081707,15.5516316 Z M9.5,19 L6.5,19 C5.67157288,19 5,19.6715729 5,20.5 C5,21.3284271 5.67157288,22 6.5,22 L6.5,22 L9.5,22 C10.3284271,22 11,21.3284271 11,20.5 C11,19.6715729 10.3284271,19 9.5,19 L9.5,19 Z M13.5,13 L6.5,13 C5.67157288,13 5,13.6715729 5,14.5 C5,15.3284271 5.67157288,16 6.5,16 L6.5,16 L13.5,16 C14.3284271,16 15,15.3284271 15,14.5 C15,13.6715729 14.3284271,13 13.5,13 L13.5,13 Z M15.5,7 L6.5,7 C5.67157288,7 5,7.67157288 5,8.5 C5,9.32842712 5.67157288,10 6.5,10 L6.5,10 L15.5,10 C16.3284271,10 17,9.32842712 17,8.5 C17,7.67157288 16.3284271,7 15.5,7 L15.5,7 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'registrIconsSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
     svg {
        height: 22px;
        width: 22px;

        path {
            fill: var(--dpurple);
        }
    }
</style>