<template>
    <floodLimit v-if="socketIOData.connectionFloodLimit" />
    <router-view v-else-if="!loginData.getCheckingForLogin" />
</template>

<script setup lang="ts">
import { onMounted, onBeforeMount, watch } from 'vue';
import i18n from './i18n';
import { useRoute, useRouter } from 'vue-router';
import { useLoginData } from '@/store/loginData'
import { useSocketIOData } from './store/socketIOData';
import floodLimit from "./views/floodLimit.vue";
import $ from "jquery";
window.$ = $
import Cookies from 'js-cookie'
window.Cookies = Cookies
// @ts-ignore
import device from 'current-device-v2'
window.device = device

import vueAPI from "./modules/vueAPI"

import Trans from '@/plugins/Translation'

const route = useRoute()
const router = useRouter()

declare global {
    interface Window {
        unregSW: () => void;
        $: typeof $;
        Cookies: typeof Cookies;
        device: any;
    }
}

window.unregSW = function() {
    vueAPI.onServiceWorkerInstalled((serviceWorker) => {
        serviceWorker.unregister().then(() => {
            console.log('Service worker unregistered')
            location.reload()
        })
    })
}

onMounted(() => {
    vueAPI._mountedCallback()
})

onBeforeMount(() => {
    vueAPI._beforeMountedCallback()

    if (localStorage.getItem('lang')) {
        Trans.changeLocale(localStorage.getItem('lang') as string)
    }
})

watch(() => i18n.global.locale, (newValue) => {
    setLocalStorageLanguage(newValue.value as string)
})

function setLocalStorageLanguage(lang: string | false = false) {
    if (lang) {
        localStorage.setItem('lang', lang)
    } else {
        localStorage.setItem('lang', String(i18n.global.locale.value))
    }
}

const loginData = useLoginData()
const socketIOData = useSocketIOData()

watch(() => loginData.getLoginState, (newValue) => {
    if (newValue == true) return

    console.log(`Login state changed to ${newValue}`)
    console.log(route.meta.requiresAuth)

    if (route.meta.requiresAuth) {
        loginData.unloginPosition = window.location.pathname
        router.replace('/login')
    }
})
</script>

<style lang="scss">
@import 'src/styles/scss/main.scss';
</style>
