<template>
    <svg class="logout-icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>{{ title }}</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M24,0 C28.418278,-8.11624501e-16 32,3.581722 32,8 L32,24 C32,28.418278 28.418278,32 24,32 L8,32 C3.581722,32 5.41083001e-16,28.418278 0,24 L0,8 C-5.41083001e-16,3.581722 3.581722,8.11624501e-16 8,0 L24,0 Z M11.2634237,9.82740944 C10.8523455,9.41633124 10.1858561,9.41633124 9.77477786,9.82740944 C6.07507405,13.5271133 6.07507405,19.5255183 9.77477786,23.2252221 C13.4744817,26.924926 19.4728867,26.924926 23.1725906,23.2252221 C26.8722944,19.5255183 26.8722944,13.5271133 23.1725906,9.82740944 C22.7615124,9.41633124 22.0950229,9.41633124 21.6839447,9.82740944 C21.2728665,10.2384876 21.2728665,10.9049771 21.6839447,11.3160553 C24.5614921,14.1936027 24.5614921,18.8590289 21.6839447,21.7365763 C18.8063973,24.6141237 14.1409711,24.6141237 11.2634237,21.7365763 C8.3858763,18.8590289 8.3858763,14.1936027 11.2634237,11.3160553 C11.6745019,10.9049771 11.6745019,10.2384876 11.2634237,9.82740944 Z M16.5263158,7 L16.4210526,7 C15.8687679,7 15.4210526,7.44771525 15.4210526,8 L15.4210526,8 L15.4210526,16.5263158 C15.4210526,17.0786005 15.8687679,17.5263158 16.4210526,17.5263158 L16.4210526,17.5263158 L16.5263158,17.5263158 C17.0786005,17.5263158 17.5263158,17.0786005 17.5263158,16.5263158 L17.5263158,16.5263158 L17.5263158,8 C17.5263158,7.44771525 17.0786005,7 16.5263158,7 L16.5263158,7 Z" id="Combined-Shape" fill="#838387"></path>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'logoutIconSVG',
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>

</style>